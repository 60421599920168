.mb-0 {
    @extend .mb-0;
    // margin-bottom: 0 !important;
}

.p-0 {
    @extend .p-0;
    // padding: 0px !important;
}

.alink {
    @extend .s-sbold, .dark-green-text, .cpointer;
    // @extend .s-reg, .dark-green-text, .cpointer;
    &:hover {
        text-decoration: underline;
    }
}

b, strong {
    font-weight: 500;
}

// Flex 
.gap-1 {
    gap: 1px;
}
.gap-2 {
    gap: 2px;
}
.gap-3 {
    gap: 3px;
}

.gap-4 {
    gap: 4px;
}

.gap-5 {
    gap: 5px;
}

.gap-6 {
    gap: 6px;
}

.gap-7 {
    gap: 7px;
}

.gap-8 {
    gap: 8px;
}

.gap-9 {
    gap: 9px;
}

.gap-10 {
    gap: 10px;
}

.gap-15 {
    gap: 15px;
}

.gap-20 {
    gap: 20px;
}

.gap-30 {
    gap: 30px;
}

.gap-40 {
    gap: 40px;
}

.gap-50 {
    gap: 50px;
}

.white-space {
    white-space: nowrap;
}

.flex-1 {
    flex: 0.1;
}

.flex-2 {
    flex: 0.2;
}

.flex-3 {
    flex: 0.3;
}

.flex-4 {
    flex: 0.4;
}

.flex-5 {
    flex: 0.5;
}

.flex-6 {
    flex: 0.6;
}

.flex-7 {
    flex: 0.7;
}

.flex-8 {
    flex: 0.8;
}

.flex-9 {
    flex: 0.9;
}

.flex-10 {
    flex: 0.10;
}

// End Flex 


@include for-desktop-min {

    // @media (min-width: 1300px)
    .overflowx-xl-inherit {
        overflow-x: inherit !important;
    }
}

a:hover {
    text-decoration: unset;
}

.cpointer {
    cursor: pointer !important;
}

.cptext {
    cursor: text !important;
}

.cmenu {
    cursor: context-menu !important;
}

.cnotallowed,
[disabled] {
    cursor: not-allowed !important;
}


// Ellipsisc
.ellipsisc,
.ellips-auto {
    // overflow: hidden;
    // text-overflow: ellipsis;
    // // display: -webkit-box;
    // -webkit-box-orient: vertical;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

.ellipsisc-line-1,
.ellps-l1 {
    -webkit-line-clamp: 1;
}

// .ellips, .ellips-auto {
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
// }
// End Ellipsisc

/* scroll to top bottom on page on click icon */
.top-bottom-icon {
    font-size: 11px;
    position: fixed;
    z-index: 1000;
    background: var(--greenc);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 9px;
    right: 0px;
    transition: all .8s;

    width: 22px;
    height: 22px;

    @include for-desktop-big-x {
        font-size: 13px;
        width: 25px;
        height: 25px;
    }
}

.btbottom-icon {
    /* transform: rotate(179deg); */
    /* bottom: 20px; */
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
}

.icon-tbwhite:before {
    color: var(--white) !important;
}

/* End scroll to top bottom on page on click icon */

.cap {
    text-transform: capitalize;
}
.upper {
    text-transform: uppercase;
}

.green-link {
    @extend .cpointer;
    color: var(--dark-greenc) !important;
}

.remove-new-scroller {
    @extend .p-0;
    overflow-y: unset !important;
    overflow-x: unset !important;
}





// .cursornot, .form-control:disabled, input[disabled] {
//     cursor: not-allowed!important;
// }
.cursornot {
    cursor: not-allowed !important;
}

.line-through {
    text-decoration: line-through;
}


// Loader 
.main-loader-sec {
    .loader-sec {
        // @extend .position-fixed, .d-flex, .align-items-center, .justify-content-center;
        @extend .position-fixed;
        // height: 100vh;        
        z-index: 1000000;
        width: 100%;
        text-align: center;
        top: 30%;

        img.loaderimg {
            height: 100px;

            @include for-desktop {
                height: 150px;
            }

            // height: 9rem;
        }

        p {
            color: #fff;
            margin-top: -5px;
        }
    }

    .modal-backdrop.show {
        opacity: 0.71;
    }
}

// End Loader 

// Cross icon 
.ss-cross {
    @extend .position-absolute, .cpointer;
    top: -1rem;
    right: -0.7rem;
    font-size: 1.7rem;
}

.ss-cross-sm {
    @extend .position-absolute, .cpointer;
    top: -0.5rem;
    right: -0.4rem;
    font-size: 1.1rem;
}

.ss-cross-md {
    @extend .position-absolute, .cpointer;
    top: -0.8rem;
    right: -0.5rem;
    font-size: 1.3rem;
    z-index: 1;
}

.popup-cross {
    @extend .position-absolute, .cpointer;
    top: -0.7rem;
    right: -0.7rem;
    // top: -0.5rem;
    // right: -0.5rem;

    .popup-cross-icon {
        // @extend .size-xxii;
        @extend .size-xxiii;
    }
}

// End Cross icon 


// No Voucher
.no-voucher {
    @extend .mt-2;
    background: var(--grayc) !important;
    border-radius: 15px;
    padding: 1.5rem 1rem;

    p {
        @extend .size-xx, .s-sbold, .mb-0, .text-center;
        color: var(--gray-text) !important;
    }
}

// End No Voucher

.react-datepicker__day,
.react-datepicker__day-name,
.react-datepicker__time-name {
    font-size: 0.75rem;
    width: 1.3rem !important;
    line-height: 1rem !important;
    margin: 0.10rem !important;
}

.react-datepicker__month-option {

    // position: relative;
    .react-datepicker__month-option--selected {
        // position: absolute;
        // left: 1px;        
        position: relative;
        left: -3px;
    }
}

.separator-border {
    border-bottom: 3px solid var(--grayc);
}



.disable-opacity {
    cursor: not-allowed !important;
    opacity: 0.5 !important;
}

.disable-before-opacity {
    cursor: not-allowed !important;
    // opacity: 0.65 !important;
}

.sup-cust-check .styled-checkbox:checked+label.disable-before-opacity:before {
    cursor: not-allowed !important;
    opacity: 0.5 !important;
}

// Section Loader with img icon 
.sec-loder-overlay {
    background: var(--black);
    opacity: 0.6;
    position: absolute;
    top: -3px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 10;
    border-radius: 10px;
}

.sec-link-loder {
    position: absolute;
    top: 35%;
    left: 40%;
    color: var(--white) !important;
    width: 150px;
    z-index: 100;
}

//  {this.props.evidence_list_loading && <><div className='sec-loder-overlay'></div>
// <img className="sec-link-loder" src={loaderw} /></>}

// End Section Loader with img icon 

/* Tool Tip Tooltip */
.a-tooltip {
    @extend .position-relative;

    .a-tooltiptext {
        @extend .position-absolute, .text-center;
        font-size: 13px;
        top: -65%;
        left: -55%;
        z-index: 1;
        width: 280px;
        color: var(--black);
        background-color: var(--white) !important;
        box-shadow: 0 0 10px 5px var(--gray-shadow);
        border-radius: 6px;
        padding: 5px;
        visibility: hidden;
    }

    &:hover .a-tooltiptext {
        visibility: visible;
    }
}


/* Tool Tip Tooltip */
// .tooltipc, .tooltipc > .tooltip-inner  {
.tooltip-inner {
    font-size: 13px;
    color: var(--black);
    font-family: s-reg !important;
    text-align: left;
    background-color: var(--white) !important;
    background: var(--white) !important;
    padding: 4px 8px;
    box-shadow: 0 0 10px 5px var(--gray-shadow);
    border-radius: 8px !important;
    // width: 100%;
}

// .tooltip-arrow { box-shadow: var(--box-shadow-tooltip); }

.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
    margin-left: 0px;
    content: "";
    border-width: 5px 5px 0;
    border-top-color: var(--white);
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
    border-bottom-color: var(--white) !important;
}

.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
    border-top-color: var(--white) !important;
}

.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
    border-left-color: var(--white) !important;
}

.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-right .arrow::before {
    border-right-color: var(--white) !important;
}

.tooltip-inner {
    max-width: 600px !important;
    @include for-ipad-pro {
        max-width: 700px !important;
    }
    @include for-desktop-15 {
        max-width: 850px !important;
    }
    // @include for-desktop-15 {
    //     max-width: 800px !important;
    // }
    // max-width: 100% !important;
}
.tooltip.show {
    opacity: 1;
    // max-width: calc(80% - 50px) !important;
}

#tooltip-disabled {
    z-index: 10000000;
}
.custom-folder-row {
  color: var(--grayc-light) !important;
}

/* .tooltip-inner {color: #77d7b1;text-align: center;background-color: #fff;font-size: 16px;padding: 8px 10px;box-shadow: 2px 2px 4px 0px rgba(161, 161, 161, 1); border-radius: 8px !important;}
      .tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.bs-tooltip-top .arrow::before {margin-left: 0px;content: "";border-width: 5px 5px 0;border-top-color: #fff;}
      .tbl-user-ent .tooltip.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.bs-tooltip-bottom .arrow::before {margin-left: 0px;content: "";border-width: 0px 5px 5px;border-bottom-color: #fff;}
      .tbl-user-ent .tooltip-inner ul{padding-left: 10px;list-style: decimal;margin: 0;}
      .tbl-user-ent .tooltip-inner ul li{font-size: 16px;font-family: s-reg !important;line-height: 30px;}
      
      .tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.bs-tooltip-top .arrow::before {margin-left: 0px;content: "";border-width: 5px 5px 0;border-top-color: #fff;}
      .blu-color .tooltip-inner{color: #2C275F !important;}
      .tbl-user-ent .tooltip-inner {color: #656263;text-align: left;background-color: #fff;padding: 8px 20px;box-shadow: 0px 0px 10px 2px rgb(161 161 161); border-radius: 8px !important;width: 100%;}
      .red-color .tooltip-inner {color: #FF522E;}
      .yellow-color .tooltip-inner{color: #FBA751 !important;}
      .sky-blu-color .tooltip-inner{color: #5E9FFD !important;}
      .blu-color .tooltip-inner{color: #2C275F !important;}
      .gray-text .tooltip-inner {color: #656263;}  */
/* End Tool Tip */

// box-gray-sec 


// Blink button 

.blink-green-btn {
    // @extend .position-relative, .size-xxv, .border-0, .cpointer, .d-flex, .align-items-center, .justify-content-center;
    // width: 40px;

    @extend .position-relative, .cpointer,;
    // background: var(--greenc);
    border-radius: 100%;
    background-position: center;

    &:after {
        content: "";
        background: var(--dark-greenc);
        background-position: center;
        @extend .position-absolute, .d-block;
        top: 0;
        padding-top: 35px;
        padding-left: 35px;
        opacity: 0;
        transition: all 0.8s !important;
        border-radius: 100%;
    }
    &:focus {
        outline: unset;
        border: 0;
    }
    &:active:after {
        @extend .p-0, .m-0;
        opacity: 1 !important;
        transition: 0s !important;
    }
}
// End Blink button 

/* Stages Bar  */
.process_step_bar {
    counter-reset: step;
    max-width: 1000px;
    margin: auto;

    // margin: -10px auto 10px;
    // position: relative;

    li {
        // @extend .position-relative, .size-xvi, .s-reg, .gray-text, .d-inline-block, .text-center; 
        @extend .position-relative, .s-reg, .gray-text, .d-inline-block, .text-center; 
        // , .cpointer
        list-style: none;        
        // width: 30.33%; 
         
        min-width: 135px;
        @include for-desktop-min {
            min-width: 200px;
        }

        font-size: 0.7rem;
        @include for-desktop-15 {
            font-size: 1rem;
        }
        
        // &:not(:first-child) {
        //     padding-left: 35px;
        // }

        &:before {
            content: counter(step);
            counter-increment: step;
            // content: "✓";
            @extend  .position-relative, .d-flex, .justify-content-center;            
            align-items: center;            
            margin: 0 auto 5px auto;            
            width: 30px;
            height: 30px;
            line-height : 30px;
            border: 1px solid;
            border-color: var(--grayc-dark);
            background-color: var(--white);           
            border-radius: 100%;             
            z-index: 1000;
        }
        &:after {
            content: "";
            width: 100%;
            height: 1px;
            border: 1px solid var(--grayc-dark);
            position: absolute;
            top: 15px;
            left: -50%;
            z-index : 1;
        }
        &:first-child:after {
            content: none;
        }
        &.active_process {
            &:before {
                // content: "✓";
                content: "\2714";
                border-color: var(--greenc);
                background: var(--greenc);
                color: var(--white);
            }          
        }
        &.dot_process {
            &:before {                
                content: "\2022";
                border-color: var(--grayc-dark);
                background: var(--white);
                color: var(--greenc);                
                font-size: 3rem;
                line-height: 3px;
                align-items: baseline !important;
                padding-top: 9px;
                // content: "\2022";
                // font-size: 1.5rem;
                // line-height: 3px;
            }          
        }
        // &.active {
        //     color: var(--bluec);
        //     &:before {
        //         border-color: var(--bluec);
        //         background: var(--bluec);
        //         color: var(--white);
        //     }
        // }
        // &.active + li:after {
        //     background-color: unset;
        //     border-color: var(--bluec);
        // }

        // &.dot_process {
        //     &:before {
        //         content: "";
        //         border-color: var(--grayc-dark);
        //         background: var(--white);
        //         color: var(--greenc);                
        //     } 
        //     font-size: 2rem;         
        // }
        // content: "\2688";
        // content: "\2024";        
    }  
    
    &.progres-li-sm {        
        padding-left: 0;
        li {            
            font-size: 0.7rem !important;
            min-width: 118px !important;
            @include for-desktop-min {
                font-size: 0.8rem !important;
                min-width: 125px !important;
            }
        }
    }
}
/* End Stages Bar  */


// .notes-box, .shadow-light-box {
//     @extend .d-block, .gray-text;
//     padding: 5px 8px;
//     border-radius: 5px;
//     box-shadow: var(--light-shadow);
// }
.shadow-light-box {
    @extend .d-block, .gray-text;
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: var(--light-shadow);
}

.hr-gray-line {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-top: 1px solid var(--border-gray);
}

.hr-blue-line {
    border-top: 1px solid var(--bluec);
}


// .gap-count-note {
//     @extend .d-flex, .align-items-center;
//     border: 2px solid var(--greenc);
//     border-radius: 50px;
//     padding: 0.375rem 0.75rem;

//     height: calc(1.5em + 0.70rem + 1px);
//     // padding: 2px 2px !important;

//     @include for-desktop-big {
//         height: calc(1.5em + 0.75rem + 11px);
//         // padding: 5px 2px !important;
//         // min-width: 215px;
//     }
// }

// Gap Count Notes
.gap-count-note {
    @extend .s-reg, .size-xvii, .d-flex, .align-items-center;
    border: 1px solid var(--redc);   
    background: #ffeaea; 
    border-radius: 50px;   
    // padding: 0.25rem 0.8rem 0.25rem 0.5rem;        
    padding: 0.3rem;        

    height: calc(1.5em + 0.70rem + 3px);
    @include for-desktop-big {
        height: calc(1.5em + 0.75rem + 7px);
    }

    .gap-count-badge {
        @extend .size-xviii, .s-sbold, .d-flex, .align-items-center, .justify-content-center;
        color: var(--white);
        background: var(--redc) !important;
        border-radius: 100px;
        padding: 2px 2px 3px;
        // min-width: 32px;
        // height: 32px;

        height: calc(1rem + 10px);
        min-width: calc(1rem + 10px);
        @include for-desktop-big {
            height: calc(1rem + 15px);
            min-width: calc(1rem + 15px);
        }
    }
    .gap-count-text {
        @extend .s-sbold, .blue-text, .d-flex,.align-items-center, .ml-2, .mr-2;
        // padding: 0.25rem 1rem 0.25rem 0.5rem;        
    }
    .gap-count-date {
        // @extend .ml-2;
    }
}

.menu-gap-count {
    @extend .s-sbold, .d-flex, .align-items-center, .justify-content-center, .ml-2;
    min-width: 22px;
    height: 22px;
    color: var(--white);
    background: var(--redc);
    border-radius: 100px;
    padding: 2px;
    margin-top: 3px;
}
.hm-menu-gap-count {
    @extend .s-sbold, .size-xvii, .d-flex, .align-items-center, .justify-content-center, .ml-2;
    min-width: 25px;
    height: 25px;
    color: var(--white);
    background: var(--redc);
    border-radius: 100px;
    padding: 2px 2px 3px;
}
// Gap Count Notes
.reset-bar-icon {
    font-size: 1.3rem;
}
@media only screen and (min-width: 1600px) {
	.reset-bar-icon {
		font-size: 1.4rem !important;
	}
}
