.company-name-new {
    @extend .s-reg;
    line-height: normal;
    font-size: 1.5rem !important;
    @include for-desktop-big {  
        font-size: 2rem !important;
    }
    .license-new {
        // @extend .size-xvi;
        font-size: 0.9rem !important;
        min-width: 120px;
    }
    .cname-new {
        max-width: 71%;
    }
}
// .company-name {
//     @extend .size-xxxv, .s-reg;
//     .license {
//         @extend .size-xiv;
//         min-width: 120px;
//     }
//     .cname {
//         max-width: 71%;
//     }
// }
.blue-green-hlink {
    color: var(--bluec);    
    &:hover {
        color: var(--greenc);
    }
}
.green-blue-hlink {
    color: var(--greenc);
    &:hover {
        color: var(--bluec);
    }
}
.h-loader {
    position: absolute;
    top: 5%;
    left: 48%;
}

.main-logo-new {
    width: 165px;
    @include for-desktop-big { 
        width: auto;
    }
}

.header-dropdown-new {
    a:hover {
        text-decoration: none;
    }
    .h-dropdown-sec-new.show {
        border-color: transparent;
        box-shadow: 0 0 10px 5px var(--gray-shadow);
        border-radius: 12px;
        padding: 0.5rem;
        width: auto;
        // min-width: 200px;        
        min-width: 350px;        
        // max-width: 350px;        
    } 
    .h-dropdown-sec-new .padding-menu {
        padding: 0.4rem 0.8rem;
    }
    .noti-drp-width {
        min-width: 400px !important; 
    }
    ul {
        list-style-type: none;
    }
    .dropdown-toggle::after {
        display: none;
    }
    .user-drp.dropdown-toggle::after {
        display: inline-block;
        border: solid var(--bluec);
        border-width: 0 3px 3px 0;
        padding: 3px;
        vertical-align: middle;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }
    #dropdown-basic.btn {
        @extend .size-xxviii;
        padding: 0 0 0 0.9rem;
        border: 0 !important;
        // outline: 0 auto -webkit-focus-ring-color !important;
        outline: unset !important;
    }
    #dropdown-basic.btn:focus {
        box-shadow: none;
        outline: 0 auto -webkit-focus-ring-color!important;
    }  
    .dwnldh-icon {
        font-size: 1.3rem;
        @include for-desktop-big {
            font-size: 1.5rem;
        }
    } 
    // .btn:focus {
    //     border: 0 !important;
    //     outline: 0 auto -webkit-focus-ring-color!important;
    // }    
    .dropdown:focus-visible {
        outline: 0 auto -webkit-focus-ring-color!important;
    } 
    .h-icons {
        padding-top: 5px;
    }
    .profileh-img {
        width: 40px;
        height: 40px;
        border-radius: 100px;
        box-shadow: var(--border-shadow);
    }
    .profileh-name {
        @extend .size-xx;
        width: 37px;
        height: 37px;
        color: var(--white);
        background: var(--greenc);
        border-radius: 100px;
        text-transform: capitalize;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
    .bell-notifi-count {
        position: absolute;
        // @extend .size-xi;

        font-size: 10px;
        @include for-desktop-big {
            font-size: 11px;
        }

        color: var(--white);
        background: var(--greenc);
        padding: 2px 4px;
        min-width: 18px !important;
        border-radius: 100px;
        right: -5px;
        top: 0px;
    }    
}

.hamburger-menu {
    // .menu-gap {
    //     gap: 5rem;
    // }
    .menu-gap {
        .menu-title {
            margin-right: 5rem;
        }
    }
    // .menu-gap .menugap:not(:last-child) {
    //     margin-right: 5rem;
    // }    
    .menu-list {
        line-height: 1.7rem;
        @include for-desktop-big {
            line-height: 1.9rem;
        }
    }
    .menu-title {
        @extend .s-sbold, .size-xix;
        margin-bottom: 0.3rem;
        white-space: nowrap;
    }
    .menu-link {
        // @extend .s-reg, .size-xvii;
        @extend .s-reg;
        font-size: 1rem;

        // @include for-desktop-big {
        //     font-size: 1rem;
        // }

        display: flex;
        align-items: center;
        color: var(--gray-text);
        padding: 0;
        // padding: 0 3.5rem 0 0;
        &:hover {
            color: var(--greenc) !important;
            background-color: transparent !important;
        }
        &:active {
            background-color: transparent;
        }
    }
    .h-menu-icon {
        margin-right: 10px;
        @extend .size-xix;

        &:before {
            color: var(--greenc) !important;
        }

        span:before {
            color: var(--greenc) !important;
        }
    }
    .applogo {
        max-height: 24px;
    }
    .ac-partner-img {
        width: 40px;
        height: 40px;
        border-radius: 100px;
        box-shadow: var(--border-shadow);
    }
    .ac-partner-h-name {
        @extend .size-xx;
        width: 37px;
        height: 37px;
        color: var(--white);
        background: var(--greenc);
        border-radius: 100px;
        text-transform: capitalize;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
}

.main-h-profile {
    .user-h-detail {
        padding: 0.75rem;
        background: var(--white);
        border-radius: 12px;

        .profileh-drp-img {
            width: 60px;
            height: 60px;
            border-radius: 100px;
            box-shadow: var(--border-shadow);
        }
        .profileh-drp-name {
            width: 60px;
            height: 60px;
            @extend .s-sbold, .size-xxxii;
            color: var(--white);
            background: var(--greenc);
            border-radius: 100px;
        }
    }
    form.search-sec input[type=text] {
        padding: 0.3rem 1rem;
        @extend .size-xix;
        border: 1px solid var(--bluec);
        // float: left;
        width: 85%;
        background: var(--white);
        border-right: none;
        border-top-left-radius: 26px;
        border-bottom-left-radius: 26px;
        outline: none;
        color: var(--bluec);
    }
    form.search-sec button {
        // float: left;
        width: 14%;
        padding: 0.4rem 1rem 0.2rem;
        background: var(--white);
        color: var(--white);
        @extend .size-xix;
        border: 1px solid var(--bluec);
        border-left: none;
        border-top-right-radius: 26px;
        border-bottom-right-radius: 26px;
        outline: none;
        align-items: center;
        justify-content: center;
    }
    .h-profile-entities-list {
        max-height: 330px;
        @include for-desktop-big {
            max-height: 377px;
        }
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 2px;
        // background: #f4f5f8;
        // margin-top: 6px;

        .entities-header {
            @extend .size-xxii;
            color: var(--white);
            background-color: var(--greenc);
            padding: 0.35rem 0.75rem;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
        }
        .drp-entity-letter {
            width: 37px;
            height: 37px;
            color: var(--white);
            background: var(--greenc);
            border-radius: 100px;
            @extend .size-xxi;
            padding: 6px;
        }
        .drp-entity-letter-img {
            width: 37px;
            height: 37px;
            border-radius: 100px;
            box-shadow: var(--border-shadow);
        }
        .drp-entity-name {
            min-width: 155px;
            max-width: 155px;
            @extend .size-xix;
        }
        .drp-entity-count {
            min-width: 25px;
            height: 25px;
            color: var(--white);
            background: var(--greenc);
            border-radius: 100px;
            @extend .size-xvi;
            padding: 4px 6px 6px;
        }
        .h-ul-entities-list {
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
        }
        .h-ul-entities-list li {
            // padding: 0.5rem 1rem 0.5rem 1.5rem;
            padding: 0.6rem 1rem 0.6rem 1.8rem;
            border-bottom: 0.5px solid var(--gray-border);
            position: relative;
            gap: 1.5rem;

            &.active {
                background: var(--menu-active);

                &:before {
                    content: "";
                    width: 7px;
                    height: 100%;
                    background: var(--active-shaded);
                    position: absolute;
                    left: 0;
                    z-index: 10000;
                }
            }
            &.de-active {
                cursor: not-allowed !important;

                & .blue-text {
                    color: var(--menu-deactive) !important;
                    cursor: not-allowed !important;
                }
            }
        }
    }
}

.notification { 
    .noti-alert-error {
        padding: 0.2rem 0.5rem 0.5rem;
        line-height: 1.2;
    }
    .noti-max-list {
        max-height: 340px;
        @include for-desktop-big {
            max-height: 500px;
        }
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 5px !important;
    }     
    // .noti-max-list:not(:first-child) .noti-drp-list,
    .noti-max-list .noti-drp-list:not(:last-child)  {
        margin-bottom: 2rem;
    }  
    .noti-drp-list {        
        .noti-drp-li {
            border: 1px solid var(--gray-dark-border);
            padding: 0.5rem;
            border-radius: 15px;
            
            &.active {
                background: var(--grayc);
            }
        }
        .noti-user {
            position: relative;
            .noti-user-img {
                width: 50px;
                height: 50px;
                border-radius: 100px;
                box-shadow: var(--border-shadow);
            }
            .noti-user-letter {
                width: 50px;
                height: 50px;
                color: var(--white);
                background: var(--bluec);
                border-radius: 100px;
                @extend .size-xxiv;
                padding: 4px 6px 6px;
            } 
            .noti-file-icon {            
                @extend .size-xxv;
                position: absolute;
                bottom: 0;
                right: -12px;
            }
        }
        .noti-p {
            color: var(--bluec);
            @extend .size-xvi, .mb-1;
            // line-height: 22px;
            line-height: 1.3;
            min-height: 31px;
        }
        .noti-date {
            @extend .d-flex, .align-items-center, .justify-content-between;
            p {
                @extend .size-xiv;
            }
        }
    }
}