// .icon-dt-td {
//     font-size: 30px;
// }
.trans-icon:before {
    color: transparent!important;
}
.gray-icon:before,
.gray-icon span:before {
    color: var(--grayc-icons) !important;
}
.dark-gray-help-icon:before,
.dark-gray-help-icon span:before {
    color: var(--gray-dark-text) !important;
}
.gray-folder-icon:before,
.gray-folder-icon span:before {
    color: var(--disable) !important;
}
// .icond[disabled]:before { 
//     color: var(--text-disable) !important;
// }
.grayc-light-icons:before,
.grayc-light-icons span:before {
    color: var(--grayc-light-icons) !important;
}
.gray-dark-icon:before {
    color: var(--gray-text) !important;
}
.blue-icon:before {
    color: var(--bluec) !important;
}
.white-icon:before {
    color: var(--white) !important;
}
.green-icon:before {
    color: var(--greenc) !important;   
}
.skyblue-icon:before {
    color: var(--sky-blue-icon) !important;   
}
.bank-blue-icon:before {
    color: var(--bank-blue-icon) !important;   
}
.green-white-icon {
    span.path1:before {
        color: var(--greenc) !important;
    }
}
.green-white-all-icon {
    background-color: var(--greenc) !important;
    &:before {
        color: var(--white) !important;
    }
}
.red-icon {
    &:before {
        color: var(--redc) !important;
    }
    span::before {
        color: var(--redc) !important;
    }
}
.white-red-icon {
    span::before {
        color: var(--redc) !important;
    }
    span.path1:before {
        color: var(--white) !important;
    }
}
.gray-red-icon {
    span::before {
        color: var(--redc) !important;
    }
    span.path1:before {
        color: var(--grayc-light-icons) !important;
    }
}
.gray-white-icon {
    span::before {
        color: var(--white) !important;
    }
    span.path1:before {
        color: var(--grayc-light-icons) !important;
    }
}
.gray-dark-white-icon {
    span::before {
        color: var(--white) !important;
    }
    span.path1:before {
        color: var(--grayc-icons) !important;
    }
}
.red-white-icon {
    span::before {
        color: var(--white) !important;
    }
    span.path1:before {
        color: var(--redc) !important;
    }
}
.help-icon {
    @extend .ml-1, .size-xxv, .cpointer;
    &:before {
        color: var(--greenc);
    }
}
.yellow-icon {
    &:before {
        color: var(--yellow) !important;
    }
}
.tblarow {
    @extend .ml-1;
}
// .help-icon span:before{
//     color: var(--greenc);
// }
.datepicker-icon {
    @extend .cmenu;
    background: url(./../../img/calendar.svg) no-repeat right;
    background-size: 1rem;
    background-origin: content-box;
    // width: 240px;
    // height: 24px;
}
.assignedby-icon {
    background: url(./../../img/down-blue-arrow.svg) no-repeat right;
    background-size: 0.6rem;
    background-origin: content-box;
    background-position: 96%;
    @mixin for-desktop-big {
        background-position: 98%;
    }
}
// short arrow 
.sort-both-icon{
    @extend .ml-1;
    font-size: 15px;
}
.sort-topbottom-icon {
    @extend .ml-1;
    font-size: 6px;
}
// end short arrow 

// Star icon 
.star-td {
    @extend .size-xxiv, .d-inline-block;
    margin-top: 0.3rem;
    // display: inline-block;
    &:before {
        color: var(--start-gray) !important;
    }

    &.active {
        &:before {
            color: var(--start-yellow) !important;
        }
    }
}
// End Star icon 


.icon-bold {
    font-weight: 600;
}

.form-icon-log {
    @extend .size-xxv, .cpointer;
}
.icon-Cross-with-grey-circle, .icon-cancel {
    @extend .cpointer;
}

.refresh-icon-new-round {
    background: var(--greenc);
    background-color: var(--greenc) !important;
    border-radius: 100% !important;
    padding: 0px 4px !important;
    line-height: 10px;
    font-size: 0.9rem;
    // width: 30px;
    // height: 30px;
    width: 1.6rem;
    height: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    @include for-desktop-big {
        // padding: 2px 4px !important;
        font-size: 1rem;
        width: 1.8rem;
        height: 1.8rem;
    }
}

.ico-refresh-sec {
    @extend .size-xviii, .cpointer, .d-flex, .align-items-center, .justify-content-center;    
    background: var(--greenc);
    border-radius: 7px !important;   
    height: 2rem;
    width: 2rem;

    @include for-desktop-big {
        height: 2.3rem;
        width: 2.3rem;
    }
}

.icon-refresh-iconn {
    margin-top: -1px;
    .path1:before {
        color: #77D7B1 !important;
        opacity: 1 !important;
    }
}

.reset_btn_new  {
    .reset-download-icon:before {
        color: var(--greenc);
    }
    &:hover {
        .reset-download-icon:before {
            color: var(--white);
        }
    }
}




.gray-st-icon.icon-Group-3182 .path1:before, .gray-st-icon.icon-Group-3180 .path1:before,
.gray-st-icon.icon-Group-3283-1 .path1:before, .gray-st-icon.icon-Group-3181 .path1:before,.gray-st-icon.icon-Group-3284 .path1:before{color: #D9D9D9;}
.gray-st-icon.icon-Group-4175.icon-Group-4175 .path1:before,.gray-st-icon.icon-personal-icon .path1:before{color: #D9D9D9;}

.gray-st-icon.icon-attachment-icon .path1:before,
.gray-st-icon.icon-TDS-icon .path1:before, .gray-st-icon.icon-TDS-icon .path4:before,
.gray-st-icon.icon-GST-icon .path1:before, .gray-st-icon.icon-GST-icon .path2:before, .gray-st-icon.icon-GST-icon .path3:before, .gray-st-icon.icon-GST-icon .path7:before
{
  color: #D9D9D9 !important;
}

.gray-st-icon span:before {
  color: #fff !important;
}
.gray-st-icon.icon-messages-icon .path1:before, .gray-st-icon.icon-messages-icon .path2:before,
.gray-st-icon.icon-tasks-icon .path1:before, .gray-st-icon.icon-tasks-icon .path2:before, .inactiveuser .path1:before,
.gray-st-icon span.path1:before
{
  color: #D9D9D9 !important;
}