// Smart Scan Box 
.smart-scan-box-padding {
    // padding: 0.2rem 0;

    // padding: 0.2rem 0.8rem;

    // @include for-desktop-big {
    //     padding: 0.8rem 2rem;
    // }

    // @include for-desktop-big-xx {
    //     padding: 0.8rem 3rem;
    // }




    padding: 0.5rem 0rem 0rem;

    // @include for-desktop-big {
    //     padding: 0.5rem 0.5rem 0rem;
    // }
    // @include for-desktop-big-125 {
    //     padding: 0.5rem 0.5rem 0rem;
    // }
    // @include for-desktop-big-xx {
    //     padding: 0.8rem 2.5rem 0.2rem;
    // }
}

.smart-scan-box {
    @extend .d-flex, .flex-column, .align-items-center, .justify-content-center, .position-relative, .cpointer;

    border: 1px solid var(--bluec);
    border-radius: 6px;
    margin: 20px auto 10px;
    min-height: 170px;
    // min-height: 220px;
    // min-height: 10rem; 
    img {
        border-radius: 6px;
    }

    @include for-desktop {
        border: 2px solid var(--bluec);
        min-height: 220px;
    }

    // @include for-desktop-big-xx {
    // }    

    .ssp-icon {
        @extend .size-xxxvi, .mb-3;
    }

    .ssp-p {
        @extend .size-xvi, .s-reg, .mb-0, .gray-text;
    }
}

.ss-drag-border {
    border: 1px dashed var(--bluec);

    @include for-desktop {
        border: 2px dashed var(--bluec);
    }
}

.smart-scan-popup {
    @extend .position-fixed;
    left: 4rem;
    bottom: 0;
    width: 50%;
    box-shadow: var(--dropdown-shadow);
    background-color: var(--white);
    z-index: 10000;
    // background: #fff;

    .ssp-relative {
        @extend .position-relative;
        height: 85vh;
    }

    .smartview-drag-sec {
        @extend .position-absolute, .text-center;
        width: 100%;
        bottom: 0px;
        background: var(--table-bg);
        padding: 20px 10px;
    }

    .rotation-sec {
        @extend .d-inline-block;
        background: var(--white);
        padding: 3px 0 0;
        margin: 0 10px 0 0;
        border-radius: 8px;

        button span {
            @extend .size-xvii;
        }
    }

    .inner-smartview-sec {
        // @extend .d-flex, .justify-content-center, .align-items-center, .p-2;
        @extend .d-flex, .justify-content-center, .align-items-start, .p-2;
        height: 90%;
        overflow-x: auto;
    }

    &.fullscreen-img {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10000;
        // padding: 1rem;
        background-color: unset;

        .ssp-relative {
            height: 100vh;
        }

        .inner-smartview-sec {
            height: 100%;
            background-color: rgba(6, 6, 6, .6);
        }

        .smartview-drag-sec {
            width: 35%;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 0);
        }

        .ss-cross {
            top: 0;
            right: 0;
            background: var(--white);
            padding: 0.5rem 1rem 1rem 1.5rem;
            border-bottom-left-radius: 93px;
        }
    }
}

.checks3 {
    // @extend .d-flex, .justify-content-between;
    // flex-wrap: wrap;
    // gap: 3px;

    // .sup-cust-check {
    //     width: 49% !important;
    // }
    display: flex;
    gap: 10px;

    @include for-desktop-min {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 3px;
        .sup-cust-check {
            width: 49% !important;
            // float: left;
        }
    }

    @include for-desktop-big-x {
        gap: 5px;
        flex-wrap: unset;
        .sup-cust-check {
            width: auto !important;
            float: none;
        }
    }
}

// @media only screen and (min-width: 1650px) {
//     .checks3 {
//         // @extend .d-flex;
//         display:  flex;
//     }
// }

// End Smart Scan Box 