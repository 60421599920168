// Accordion
.myAccordion {
    @extend .mt-2;
    overflow-y: auto;
    overflow-x: hidden;
    height: 93%;
    padding-right: 3px;

    .accordion .card {
        // @extend .m-0;        
        @extend .mb-2;
        border: 1px solid var(--border-gray);
        border-radius: 15px;


        .card-header {
            @extend .cpointer, .d-flex, .align-items-center, .justify-content-between;
            border-radius: 15px;
            border-bottom: 1px solid var(--border-gray);
            // background: var(--grayc-ft);
            background: var(--grayc);

            padding: 0.5rem 0.7rem !important;
            // @include for-desktop {
            //     padding: 0.65rem 1rem !important;
            // }

            .label-accordion {
                @extend .size-xx, .blue-text, .s-sbold;
            }

            .iconbar-accordion {
                @extend .d-flex, .align-items-center, .justify-content-end;

                font-size: 1.3rem;
                gap: 10px;

                @include for-desktop {
                    font-size: 1.7rem;
                    gap: 15px;
                }

                .down-arrow {
                    @extend .size-x;
                }
            }
        }

        .card-body {
            // @extend .p-2;
            padding: 0.7rem !important;
        }
    }
}

// End Accordion



// Multiple Entities Popup - Accordion
.multipleEntityAccordion {
    .accordion .card {
        // @extend .mb-2;
        border: 1px solid var(--border-gray);
        border-radius: 15px;

        &:not(:last-child) {
            margin-bottom: 0.6rem;
        }

        .card-header {
            @extend .cpointer, .d-flex, .align-items-center, .justify-content-between;
            border-radius: 15px;
            border-bottom: 1px solid var(--border-gray);
            color: var(--white);
            background: var(--greenc);
            padding: 0.5rem 0.7rem !important;

            .label-accordion {
                @extend .size-xxi, .white-text, .s-sbold;
            }

            .license-qty {
                @extend .size-xvi, .white-text, .s-sbold;
            }

            .license-qty-count {
                @extend .size-xxi, .s-sbold, .text-center;
                color: var(--bluec);
                background: var(--white);
                padding: 0 14px;
                min-width: 60px;
                border-radius: 6px;
            }

            .iconbar-accordion {
                @extend .d-flex, .align-items-center, .justify-content-end;
                font-size: 1.3rem;
                gap: 10px;

                @include for-desktop {
                    font-size: 1.7rem;
                    gap: 15px;
                }

                .down-arrow {
                    @extend .size-x;
                }
            }
        }

        .card-body {
            // @extend .p-2;
            padding: 0.7rem !important;

            .license-name {
                @extend .size-xix, .blue-text, .s-sbold;
            }

            .licenses-list {
                @extend .d-flex, .align-items-center, .justify-content-between, .gap-15;
                // border: 1px solid #656263;
                border: 1px solid var(--border-gray);
                padding: 8px 14px;
                border-radius: 15px;

                &:not(:last-child) {
                    margin-bottom: 0.6rem;
                }        
            }

            .licenseExpire {
                @extend .size-xv, .s-sbold;

                .license-date {
                    @extend .blue-text;
                }
            }
        }
    }
}

// End Multiple Entities Popup - Accordion