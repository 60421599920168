// Font Color 
h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--bluec);
}

p {
    color: var(--gray-text);
}

.blue-text {
    color: var(--bluec) !important;
}
.dark-green-text {
    color: var(--dark-greenc) !important;
}
.green-text, .green-color {
    color: var(--greenc) !important;
}
.red-text {
    color: var(--redc) !important;
}
.red-color {
    color: var(--redc-light) !important;
}
.gray-text {
    color: var(--gray-text) !important;
}
.white-text {
    color: var(--white) !important;
}
.text-light-blue {
    color: var(--sky-blue) !important;
}
.yellow-text {
    color: var(--yellow) !important;
}
.orange-text {
    color: var(--orange) !important;
}

// Background Color 
.gray-bg {
    background: var(--grayc) !important;
}
.gray-dark-bg, .disable, .disable-btn {
    background-color: var(--disable) !important;
}
.page-link.disable-btn {
    opacity: 0.6;
    background-color: transparent !important;
}
.green-bg {
    background: var(--greenc) !important;
}
.white-bg {
    background: var(--white) !important;
}
.greenc-light-bg {
    background-color: var(--greenc-light) !important;
}
.orange-bg {
    background-color: var(--orange) !important;
}
.sky-blue-bg {
    background-color: var(--sky-blue) !important;
}
.blue-bg {
    background-color: var(--bluec) !important;
}
// .yellow-bg {
//     background-color: var(--bluec) !important;
// }

.overlay-black {
    background: var(--overlay-black) !important;
}

// Background + Text Color
.green-white-bgt {
    color: var(--white) !important;
    background: var(--greenc) !important;
}