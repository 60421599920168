// My Profile 
.change-password {
    @extend .position-relative;
    border: 1px solid var(--bluec);
    padding: 13px 5px;
    // border-radius: 12px;
}
// End My Profile 


// Profile Management
.profile-upload-width {
    // width: 200px;
    width: 170px;
    text-align: center;
}

.profile-upload-logo {
    @extend .cpointer, .text-center, .d-flex, .align-items-center, .justify-content-center, .flex-column, .gap-20, .p-2;
    border: 1px dashed var(--gray-dark-text);
    width: 170px;
    height: 165px;
    border-radius: 15px;

    img {
        border-radius: 15px;
    }

    .profile-p {
        @extend .size-xvii;
    }

    .upload-icon {
        @extend .size-xxxv;
    }
}

.pm-p-icon {
    @extend .size-xv;
}

.pm_thumbnail_img {
    max-height: 165px;
}

.tabs-with-btns {
    @extend .d-flex, .align-items-center, .gap-10;
    position: absolute;
    right: 15px;
    top: 1px;

    @include for-desktop {
        top: 3px;
    }
}

.card-forms {
    // @extend .p-3, .mb-3;
    @extend .mb-3;    
    border: 1px solid var(--form-border);
    border-radius: 20px;
    min-height: 95%;

    padding: 0.7rem 0.8rem;
    @include for-desktop-15 {
        padding: 1rem;
    }

    .card-form-titlebar {
        @extend .d-flex, .align-items-center, .justify-content-between, .gap-10, .pb-2;
        border-bottom: 1px solid var(--form-border);
        margin-bottom: 0.7rem;

        .card-form-title {
            @extend .s-reg, .mb-0;
            font-size: 1.1rem !important;

            @include for-desktop-min {
                font-size: 1.2rem !important;
            }            
        }
    }
}

.copyfile {
    @extend .position-relative, .size-xxv, .border-0, .cpointer, .d-flex, .align-items-center, .justify-content-center;
    background: var(--greenc);
    width: 40px;

    // font-size: 1.7rem;    
    &:after {
        content: "";
        background: var(--dark-greenc);
        @extend .position-absolute, .d-block;
        padding-top: 40px;
        padding-left: 40px;
        opacity: 0;
        transition: all 0.8s
    }

    &:focus {
        outline: unset;
        border: 0;
    }

    &:active:after {
        @extend .p-0, .m-0;
        opacity: 1;
        transition: 0s;
    }
}

.pm-steps-title {}

// pmgt-user-menu
.pmgt-user-menu {
    @extend .h-100;
    // @extend .flex-column;
    background: var(--grayc) !important;
    border-radius: 15px;
    padding: 1rem 0.8rem;

    .pmgt-menu-heading {
        @extend .mb-0;
        // padding: 0.8rem;
        padding-bottom: 1rem;
    }

    .pmgt-menu-list {
        overflow-y: auto;
        max-height: 230px;
        padding-right: 5px;
        // min-height: 270px;
        // max-height: 50%;
    }

    .nav-item:not(:last-child) {
        @extend .cnotallowed;
        margin-bottom: 0.8rem !important;
    }

    .list-group-item {
        @extend .size-xix, .s-sbold, .blue-text, .border-0, .cpointer;
        padding: 0.6rem 1rem;
        border-radius: 15px !important;
        background: var(--white);

        label {
            @extend .cpointer, .mb-0;
        }

        &:not(:last-child) {
            margin-bottom: 0.8rem !important;
        }

        &.active {
            @extend .white-text;
            background: var(--widget-active);
        }

        &.disabled {
            @extend .white-text, .cnotallowed;
            background: var(--widget-disabled);
        }
    }

    .checkbox_a {
        @extend .d-flex, .align-items-center;
        // @extend .d-flex, .align-items-center, .gap-5;
        // .styled-checkbox {
        //     &::before {
        //         width: 1.4rem !important;
        //         height: 1.4rem !important;
        //         font-size: 85%;
        //     }
        // }
    }

    .pmgt-same-permission {
        @extend .d-flex, .align-items-center, .justify-content-between, .gap-3;

        .upae-text {
            @extend .s-sbold, .blue-text;
            font-size: 0.85rem !important;

            @include for-desktop-big {
                font-size: 1.05rem !important;
            }
        }
    }
}

.pmuser-step-list {
    @extend .d-flex, .align-items-center, .justify-content-center, .h-100;

    .pmuser-li-list {
        @extend .size-xxi, .s-reg, .blue-text;
        margin-left: -17px;

        li {
            @extend .mb-2;
        }
    }
}

.pmgt-user-detail {
    @extend .d-flex, .flex-column, .h-100, .mb-xl-0, .mb-lg-3;
    border: 1px solid var(--bluec);
    border-radius: 12px;
    padding: 12px !important;

    @include for-desktop-big {
        padding: 15px !important;
    }

    .tab-content.tabcontent {
        @extend .mt-0;
    }

    .pmgt-user-cards-checkbox {
        max-height: 500px;
        overflow-y: auto;
        padding-right: 5px;

        // .card:not(:last-child) {
        //     // @extend .mb-3;
        //     margin-bottom: 12px !important;
        //     @include for-desktop-big {
        //         margin-bottom: 15px !important;
        //     }
        // }

        .card:not(:last-child) {
            margin-bottom: 15px !important;
        }

        .card {
            &:not(:last-child) {
                margin-bottom: 15px !important;

                @include for-desktop-big {
                    margin-bottom: 15px !important;
                }
            }
        }

        .card-header {
            // padding: 0.5rem 1.25rem !important;
            padding: 0.5rem 1rem !important;

            .card-header-label {
                @extend .s-sbold, .size-xviii, .blue-text, .mb-0;
            }
        }

        .card-body {
            // padding: 0.8rem 1.25rem 0.1rem;
            padding: 0.8rem 1rem 0.1rem;

            .card-body-label {
                @extend .s-reg, .size-xvi, .blue-text, .mb-0;
            }

            .form-group-card-body {
                @extend .mb-3;
            }
        }
    }
}

.licensesTable {

    th {
        @extend .pt-0, .pb-0, .border-0;
        font-weight: normal;
    }

    td {
        border: 0px;
        border-bottom: 1px solid var(--border-gray) !important;
        vertical-align: middle !important;
    }

    th,
    td,
    .qty-lt {
        @extend .size-xx, .s-sbold;
        color: var(--bluec) !important;
    }

    .licence_name {
        @extend .d-flex, .align-items-center, .gap-10, .mb-2;
    }


    .recomtext {
        @extend .size-xiv, .s-reg;
        color: var(--white);
        background: var(--bank-blue-icon);
        padding: 3px 6px;
        border-radius: 5px;
        // vertical-align: super;
    }

    .total_rate {
        display: inline-block;
        border-radius: 8px;
        padding: 0.3rem 0.75rem;
        min-width: 140px;
        color: var(--white) !important;
        background: var(--greenc);
    }

    .tr-total {
        td {
            padding: 0.1rem;

            @include for-desktop-big-x {
                padding: 0.3rem;
            }

            &:not(:first-child) {
                color: var(--white) !important;
                background: var(--greenc);
            }
        }
    }
}

.licence-list {
    @extend .list-group, .mb-0;

    li {
        @extend .size-xvi, .s-reg, .gray-text;
        @extend .d-flex, .align-items-baseline, .gap-5;
        list-style-type: none;

        &:before {
            // content: "\2714\0020";
            content: "\2714";
            @extend .d-flex, .align-items-center, .justify-content-center;
            color: var(--white);
            background: var(--greenc);
            border-radius: 100%;

            min-width: 1.1rem;
            height: 1.1rem;
            font-size: 90%;

            @include for-desktop-big-x {
                min-width: 1.4rem;
                height: 1.4rem;
                font-size: 80%;
            }
        }

        &:not(:last-child) {
            @extend .mb-1;
        }
    }
}

.licence-list-row {
    @extend .list-group, .d-flex, .flex-row, .mb-0;   

    li {
        @extend .size-xxi, .blue-text, .mr-3;
        list-style-type: none;

        &:before {
            // content: "\2714\0020";
            content: "\2714";
            @extend .d-inline-flex, .align-items-center, .justify-content-center, .mr-1;
            color: var(--white);
            background: var(--greenc);
            border-radius: 100%;

            min-width: 1.3rem;
            height: 1.3rem;
            font-size: 80%;

            @include for-desktop-big-x {
                min-width: 1.5rem;
                height: 1.5rem;
                // font-size: 80%;
            }
        }
        &:not(:last-child) {
            @extend .mb-1;
        }
    }    
}


.term-and-condition {
    @extend .py-3, .pl-3, .pr-2, .mt-2;
    background: var(--grayc);
    border-radius: 20px;

    p {
        @extend .text-justify, .mb-2;
    }
    h3 {
        @extend .mt-4, .mb-2;
    }

    .tnc-content {
        @extend .pr-2;
        max-height: 210px;
        overflow-y: scroll;        
    }    
}


// .pmgt-user-detail {
//     border: 1px solid var(--bluec);
//     border-radius: 15px;
//     padding: 0 1rem;
//     .heading-shi {
//         @extend .mb-0;
//         padding: 0.8rem 0;
//     }
// }
// End pmgt-user-menu

// End Profile Management





.pm-form-title {
    font-size: 1.3rem !important;

    @include for-desktop {
        font-size: 1.8rem !important;
    }

    @include for-desktop-big-125 {
        font-size: 1.5rem !important;
    }
}

.pm-hr {
    // margin-bottom: 1.7rem;
    margin-bottom: 1.5rem;
}



// Paid By Attached License Card  
.paid-by-cards {
    @extend .position-relative;
    border: 1px solid var(--shi-border);
    border-radius: 10px;
    padding: 0.5rem;

    *p {
        @extend .s-sbold, .mb-0;
    }

    .pm-pb-lable {
        @extend .size-xv, .gray-text;
    }

    .pm-pb-name {
        @extend .size-xxiii, .blue-text, .ellipsisc;
    }

    .pm-pb-edate {
        @extend .text-right, .d-flex, .flex-column, .align-items-end, .justify-content-end, .h-100;

        .pm-pb-date {
            @extend .size-xv, .blue-text;
        }
    }
}

// End Paid By Attached License Card  