.communicationbar {
    @extend .mt-3;

    .styled-checkbox {
        &::before {
            width: 1.25rem !important;
            height: 1.25rem !important;
            margin-top: 1px;
        }
        @include for-desktop-big {
            &::before {
                width: 1.5rem !important;
                height: 1.5rem !important;
            }
        }
    }

    // .styled-checkbox + label:before {
    //     content: "";
    //     margin-right: 0;
    //     display: inline-block;
    //     vertical-align: text-top;
        
    //     width: 1.1rem;
    //     height: 1.1rem;
    //     @include for-desktop-big {
    //         width: 23px;
    //         height: 23px;
    //     }
        
    //     background: var(--white);
    //     border: 1px solid var(--shi-border);
    //     border-radius: 4px;
    // }
    // .styled-checkbox:checked + label:before {
    //     box-shadow: none;
    //     background: var(--greenc);
    //     border-color: var(--greenc);
    // }
    // .styled-checkbox:checked + label:after {
    //     content: "";
    //     position: absolute;
    //     // left: 6px;
    //     // top: 13px;
    //     left: 0.18rem;
    //     top: 0.75rem;
    //     @include for-desktop-big {
    //         left: 0.35rem;
    //         top: 0.85rem;
    //     }
    //     @include for-desktop-big-125 {
    //         top: 0.6rem;
    //     }
    //     background: var(--white);
    //     width: 2px;
    //     height: 2px;
    //     // box-shadow: -2px 0 0 white,2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white, 4px -10px 0 white, 4px -12px 0 white;
    //     box-shadow: var(--check-box-shadow);
    //     transform: rotate(45deg);
    // }

    

    .character-limit {
        @extend .size-xiv, .s-reg, .gray-text;
    }

    .lable-add-note {
        @extend .size-xv, .s-reg, .gray-text;
    }

    .cmb-loader {
        position: absolute;
        top: 23%;
        left: 48%;
    }

    .task-placeholder {
        &::placeholder {
            font-size: 1rem;
        }
    }
    .form-group {
        // @extend .mb-1;
        margin-bottom: 1rem !important;
        @include for-tablet-big {
            margin-bottom: 5px !important;
        }
    }

    .comment-cmb-box {
        @extend .mb-2, .position-relative;
        padding: 0.8rem;
        border: 1px solid var(--shi-border);
        border-radius: 15px;

        .cmb-heading {
            @extend .pb-2;
            margin-bottom: 10px;
            border-bottom: 1px solid var(--shi-border);
        }

        .msg-sec-cmb {
            min-height: 200px;
            max-height: 300px;
            overflow-y: auto;
            padding-right: 5px;

            .msg-boxed {
                max-width: 90%;
                @include for-desktop-min {
                    max-width: 70%;
                }
            }

            .messages-cmb {
                @extend .d-lg-flex, .align-items-start;
                border-radius: 15px;
                margin-bottom: 10px;
                // padding: 0.8rem;
                padding: 0.5rem 0.7rem;
                border: 1px solid #656263;

                &.business-notes {
                    background: var(--grayc);
                    border: 0;
                }

                &.msg-send-cmb {
                    @extend .ml-auto;
                }

                // &.msg-receive-cmb {
                //     @extend .ml-auto;
                // }

                .pnd-cmb {
                    @extend .d-flex, .mr-2;

                    .profile-cmb-img {
                        @extend .mr-2;
                        height: 40px;
                        width: 40px;
                        border-radius: 100px;
                        // margin-right: 10px;
                    }

                    .profile-cmb-letter {
                        @extend .size-xxiii, .mr-2, .s-sbold, .cap, .d-inline-flex, .align-items-center, .justify-content-center;
                        // font-size: 1.3rem;
                        height: 40px;
                        width: 40px;
                        color: var(--white);
                        background: var(--bluec);
                        border-radius: 100px;
                        // text-transform: capitalize;
                        // display: inline-flex;                        
                    }

                    .nd-cmb {
                        max-width: 175px;
                    }

                    .profile-cmb-name {
                        @extend .size-xviii, .mb-1;
                        // width: 170px;
                    }

                    .date-cmd {
                        @extend .size-xv, .gray-text;
                    }
                }

                .msgtext-cmd {
                    @extend .size-xvi, .gray-text, .mb-0;
                    margin-top: -3px;
                }

                &.notes-box {
                    border: 1px solid var(--gray-notes-border);
                    background: var(--grayc);

                    .notes-msg-box {
                        @extend .size-xviii, .d-flex, .justify-content-between, .w-100;

                        .msgtext-cmd {
                            @extend .size-xviii;
                            // mr-auto
                        }

                        .notesc-icons {
                            @extend .d-flex, .flex-column, .justify-content-xl-between, .ml-1;
                            opacity: 0;
                        }

                        &:hover .notesc-icons {
                            opacity: 1;
                        }
                    }
                }

                &.taskc-box {
                    @extend .d-sm-flex, .align-items-start;
                    border: 1px solid var(--gray-notes-border);
                    
                    &:hover {
                        background: var(--grayc);
                    }                   

                    .notes-msg-box {
                        @extend .size-xviii, .d-flex, .justify-content-between, .w-100;

                        .taskc-name {
                            @extend .size-xx, .s-sbold, .blue-text, .mb-1;
                        }

                        .day-reply-sec{ 
                            @extend .d-lg-flex, .align-items-center;
                            p {
                                @extend .size-xv, .s-sbold, .gray-text, .mr-2, .mr-lg-4, .mb-sm-0;
                                .day-reply-text {
                                    @extend .size-xviii, .blue-text;
                                }
                            }
                        }

                        .msgtext-cmd {
                            @extend .size-xviii;
                            // mr-auto
                        }

                        .notesc-icons {
                            @extend .d-flex, .flex-column, .justify-content-xl-between, .ml-1;
                            opacity: 0;
                        }

                        &:hover .notesc-icons {
                            opacity: 1;
                        }
                    }
                }

            }
        }
    }

    .bottom-cmb {
        // position: relative;
        // width: 100%;
        // @extend .p-2, .mb-2;
        @extend .p-2, .mt-3;
        background: var(--grayc);
        border: 1px solid var(--gray-dark-border);
        border-radius: 15px;

        .bottom-inner-cmb {
            @extend .d-lg-flex, .mr-auto, .w-100, .gray-text;
            // @extend .d-lg-flex, .mr-auto, .mb-2, .w-100, .gray-text;
            // @extend .d-flex, .align-items-start, .mr-auto, .w-100, .gray-text;
            background: var(--white);
            // border: 1px solid var(--white);
            border-radius: 15px;

            .user-comment-cmb {
                @extend .d-flex, .align-items-center, .mr-auto, .w-100, .p-2;
                background: var(--white);
                // border: 1px solid var(--gray-dark-border);
                border-radius: 15px 0 0 15px;
                border-right: 0;

                .click-msg {
                    @extend .size-xviii, .mb-0, .ml-2;
                }

                .form-control.mention-a-user {
                    @extend .size-xxii, .border-0, .w-100;
                    overflow: unset !important;
                    height: auto !important;

                    textarea {
                        // @extend .size-xxii;
                        @extend .border-0, .s-reg, .blue-text;

                        &:focus-visible {
                            outline: 0 !important;
                        }

                        // &::placeholder{
                        //     @extend .size-xx;
                        // }
                    }

                    .form-control__suggestions {
                        margin-top: 50px !important;
                        padding-bottom: 50px !important;
                        // background: hsla(0,0%,100%,0)!important;
                        background: transparent !important;

                        .form-control__suggestions__list {
                            max-height: 300px;
                            overflow: auto;
                            border: 2px solid var(--greenc);
                            background: var(--white);
                            border-radius: 10px;

                            li {
                                @extend .s-sbold;
                                padding: 2px 10px !important;
                                color: var(--greenc);

                                &:hover {
                                    background: var(--greenc) !important;
                                    color: var(--white) !important;
                                }
                            }
                        }
                    }
                }


                &.disable {
                    @extend .blue-text, .cnotallowed;
                    background: var(--disable);
                }
            }

            .task-notes-comment-cmb {
                border-radius: 0;
                padding: 0 !important;

                .form-group {
                    margin-bottom: 0px !important
                }
            }

            .p-0 {
                @extend .p-0;
            }

            .icon-cmb {
                // .gap-5,                
                // @extend .d-flex, .align-items-baseline, .justify-content-end, .p-2, .size-xxx;

                // @extend .d-flex, .align-items-center, .justify-content-end, .p-2, .size-xxx;
                @extend .d-flex, .align-items-center, .justify-content-end, .p-2;

                font-size: 1.3rem;

                @include for-desktop-big {
                    font-size: 1.7rem;
                }

                height: 65px;

                a {
                    @extend .position-relative, .ml-3;
                    // padding: 0.7rem;
                    text-decoration: none;

                    &:first-child {
                        @extend .ml-0;
                    }

                    &.active {
                        background: var(--bluec);
                        // width: 54px;
                        // height: 54px;
                        border-radius: 36px;
                        padding: 0.7rem;

                        span:before {
                            color: var(--white);
                        }
                    }

                    &.active-msg span:before {
                        color: #fba751 !important;
                    }

                    .active-dot {
                        @extend .position-absolute;
                        background: var(--redc);
                        padding: 7px;
                        border-radius: 10px;
                        top: -9px;
                        left: 15px;
                    }

                    // &.active-dot:before {
                    //     content: "";
                    //     @extend .position-absolute;
                    //     background: var(--redc);
                    //     padding: 6px;
                    //     border-radius: 10px;
                    //     top: 2px;
                    //     right: 7px;
                    // }
                }
            }
        }

        .btn {
            // @extend .mt-2;
            margin-top: 0.5rem;
        }
        .right_side_btn_new {
            @extend .mt-0;
        }
    }
}