/* Actions Menu */
.action-menu .dropdown-menu {
  width: 295px !important;
  box-shadow: rgb(0 0 0 / 67%) 0px 1px 21px;
  line-height: 30px;
  padding: 5px 0px !important;
}
.action-menu .dropdown-menu  hr {
  border-top: 1px solid #cfcfcf;
}
.action-menu .dropdown-menu button {
  font-family: s-reg;
  font-size: 16px;
  color: #656263;
  border: 0;
  background: transparent;
  display: flex;
  line-height: 1;
  /* margin: 5px 10px;
  margin: 12px 10px 12px; */
  padding: 10px 16px;
  text-align: left;
  width: 100%;
}
.action-icon {
  /* margin-right: 10px; */
}
.action-icon:before, .action-icon span:before{
  color: #77D7B1 !important;
}
.action-menu .dropdown-menu button:hover:enabled {
  color: #77D7B1;
}
.action-menu .dropdown-menu button:hover:disabled {
  cursor: not-allowed;
}
.action-menu .dropdown-menu button:focus {
  border: 0;
  outline: 0;
}
.action-menu .dropdown-toggle::after{
  display: none;
}
.action-menu .icon-menu-lines {
  font-size: 2rem;
}

.action-menu .tgl-btn {
  border: 1px solid #77d7b1;
  background: #77d7b1;
  padding: 0.45rem 0.45rem !important;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center !important;
  margin-bottom: 0;
}
.action-menu .tgl-btn .iconwhite:before{
  color: #fff !important;
}
.action-menu button:focus {
  border-color: #fff !important;
  outline: 0 !important;
}
@media only screen and (max-width: 1450px)
{
  .action-menu .icon-menu-lines {
    font-size: 1.5rem;
  }
  .action-menu .tgl-btn {
    padding: 0 0.3rem !important;
  }
}
/* End Actions Menu */

/* DocBox Three Dots Menu */
/* .action-doc-menu div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  width: 200px !important;
  box-shadow: rgb(0 0 0 / 67%) 0px 1px 21px;
  line-height: 30px;
  border: 0px !important;
}
.action-doc-menu ul {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.action-doc-menu .css-aqqxug-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper:before {
  display: none !important;
}
.action-doc-menu hr {
  border-top: 1px solid #cfcfcf;
}
.action-doc-menu button {
  font-family: s-reg;
  font-size: 16px;
  color: #656263;
  border: 0;
  background: transparent;
  display: flex;
  line-height: 1;
  margin: 5px 10px;
  margin: 10px;
  text-align: left;
}
.action-doc-menu .action-icon {
  margin-right: 10px;
  width: 17px;
}
.action-doc-menu .action-icon:before, .action-doc-menu .action-icon span:before{
  color: #77D7B1 !important;
}
.action-doc-menu button:hover {
  color: #77D7B1;
}
.action-doc-menu button:focus {
  border: 0;
  outline: 0;
}
.action-doc-menu button:focus {
  border-color: #fff !important;
  outline: 0 !important;
} */


/* -------  */


/* .action-doc-menu ul.MuiList-root.MuiList-padding.MuiMenu-list li {
  font-family: s-reg !important;
  font-size: 16px !important;
  color: #656263;
  border: 0;
  background: transparent;
  display: flex;
  line-height: 1;
  margin: 10;
  text-align: left;
} */
/* .action-doc-menu .dropdown-toggle::after{
  display: none;
} */
/* .action-doc-menu .icon-menu-lines {
  font-size: 2rem;
} */
/* .action-doc-menu .tgl-btn {
  font-size: 2.8rem;
} */
/* .action-doc-menu .tgl-btn {
  border: 1px solid #77d7b1;
  background: #77d7b1;
  / 
  padding: 0.45rem 0.45rem !important;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center !important;
  margin-bottom: 0;
}
.action-doc-menu .tgl-btn .iconwhite:before{
  color: #fff !important;
} */

/* @media only screen and (max-width: 1450px)
{ */
  /* .action-doc-menu .icon-menu-lines {
    font-size: 1.5rem;
  } */
  /* .action-doc-menu .tgl-btn {
    padding: 0 0.3rem !important;
  } */
/* } */
/* End DocBox Three Dots Menu */