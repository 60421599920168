.ledger-entery-new {
    position: relative;
    border-radius: 11px;
    padding: 0.4rem 0.8rem;
    margin: 8px 2px 6px 0;
    border: 1px solid var(--border-gray);
}

.voucher-type-line {
    @extend .d-flex, .align-items-center, .mb-lg-0, .mb-md-2;

    .vt-text {
        @extend .size-xvi;
    }
}

.main-invoice-item-new {
    // @extend .mt-2;
    margin-top: 0.5rem;
    overflow-x: inherit;

    .ledger-table {
        // @extend .mb-2;
        margin-bottom: 0.5rem;

        thead {
            tr {
                background: var(--grayc) !important;
            }

            th {
                @extend .s-reg, .size-xvi, .blue-text, .border-0;
                padding: 4px 3px !important;
                font-weight: 400;
                // &:first-child {
                //     border-top-left-radius: 8px;
                //     border-bottom-left-radius: 8px;
                // }
                // &:last-child {
                //     border-top-right-radius: 8px;
                //     border-bottom-right-radius: 8px;
                // }
            }
        }

        td {
            @extend .border-0;
            padding: 1px 3px !important;
            vertical-align: top !important;

            &:first-child {
                @extend .pl-0;
            }

            &:last-child {
                @extend .pr-0;
            }
        }

        td.va-middle {
            vertical-align: middle !important;
        }

        .ledger-add-row {
            @extend .p-0, .border-0, .bg-transparent, .cpointer, .d-flex, .align-items-center, .gap-5, .size-xviii, .green-text, .s-sbold;

            .icon-plus-circled {
                @extend .size-xxxii;
            }

            outline: 0;

            &:focus {
                .icon-plus-circled {
                    // -webkit-box-shadow: var(--btn-border-shadow);
                    // -ms-box-shadow: var(--btn-border-shadow);
                    box-shadow: 0 0 2px var(--gray-shadow);
                    // box-shadow: 0 0 6px #696768;
                    border-radius: 100px;
                    outline: 0;
                }
            }
        }

        // .ledger-add-row {
        //     @extend .size-xxxvi, .cpointer;
        //     &:focus-visible {
        //         box-shadow: 0 0 6px #696768;
        //         border-radius: 100px;
        //         outline: 0;
        //     }
        //     &:focus {
        //         box-shadow: var(--btn-border-shadow);
        //         // border-radius: 100px;
        //         // outline: 0;
        //     }
        // }

        .ledger-total {
            // @extend .size-xx, .s-sbold, .text-right, .blue-text, .va-middle;
            @extend .s-sbold, .blue-text, .va-middle;
            text-align: right;
        }

        .croslt,
        td.croslt {
            padding: 0.5rem 0 0 2px !important;
            // vertical-align: middle !important;
        }
    }

    .croslt {
        padding: 0.85rem 0 0 0px !important;
    }

    .croslt-icon {
        @extend .size-xx, .cpointer;
    }
}

// .croslt, td.croslt {
//     padding: 0.85rem 0 0 2px !important;
//     vertical-align: middle !important;
// }

.add-tag-ledger-new {
    .tags {
        @extend .s-reg, .cpointer, .d-inline-block, .cptext;
        font-size: 0.8rem;
        border-radius: 5px;
        padding: 2px 11px 4px;
        margin: 0 4px 4px 0;
        background: var(--grayc-icons);
        color: var(--white);

        &.green-tags {
            background: var(--greenc);
            color: var(--white);
        }

        &.sky-blue-tags {
            background: var(--sky-blue);
            color: var(--white);
        }
    }

    .btn-small {
        @extend .s-reg, .size-xiii;
    }
}

.ledger-type-date {
    @extend .d-flex, .align-items-center, .justify-content-end, .gap-20, .mt-2;
}

.date-width-130 {
    width: 130px;
}

// Ledger icons 
.ledger-ls-icon {
    @extend .size-xviii;
    margin-left: 0.5rem;
}

.ledger-icon-bar {
    @extend .blue-text, .d-flex, .align-items-center, .flex-wrap, .gap-10, .mt-1, .mb-0;
}

.ledger-left-bar {
    @extend .d-flex, .align-items-center, .gap-10, .mr-auto;
}

.ledger-right-bar {}

.ledger-bar-import-text {
    @extend .blue-text, .text-right, .mb-xl-2;
}

.ledger-bar-sync-text {
    @extend .blue-text;
}

.ledger-tls-icon {
    @extend .size-xxviii;
    // margin-left: 0.5rem;
}

.ledger-trs-icon {
    @extend .size-xxvii;
    // margin-right: 0.5rem;
}

.refresh-licon {
    @extend .size-xxxii, .cpointer;
    // margin-right: 0.5rem; 
}

.refresh-recom-icon {
    @extend .cpointer;

    .path2,
    .path3,
    .path4,
    .path5 {
        &:before {
            color: var(--greenc) !important;
        }
    }
}

// Ledger icons - End 