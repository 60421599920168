// Font Family 
@font-face {
    font-family: s-sbold;
    src: url(../../../Assets/font/seguisb_0.ttf);
}
@font-face {
    font-family: s-reg;
    src: url(../../../Assets/font/segoeui_0.ttf);
}
@font-face {
    font-family: s-light;
    src: url(../../../Assets/font/segoeuil_0.ttf);
}
.s-sbold, b {
    font-family: s-sbold !important;
}
.s-reg {
    font-family: s-reg !important;
}
.s-light {
    font-family: s-light !important;
}
// * {
//     font-family: s-reg;
// }
body,
p,
li,
small {
    font-family: s-reg;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: s-sbold !important;
}

.iconsc * {
    font-family: 'icomoon' !important;
}
[class^="icon-"],
[class*=" icon-"] {
    font-family: 'icomoon' !important;
}
// End Font Family 

// Font Size 
$base-font-size : 7px;
$base-font-size-big : 10px;
// $base-font-size : var(--c-font-size);
$font-sizes: (
    x : 0rem,
    xi : 0.0625rem,
    xii : 0.125rem,
    xiii : 0.1875rem,
    xiv : 0.25rem,
    xv : 0.3125rem,
    xvi : 0.375rem,
    xvii : 0.4375rem,
    xviii : 0.5rem,
    xix : 0.5625rem,
    xx : 0.625rem,
    xxi : 0.6875rem,
    xxii : 0.75rem,
    xxiii : 0.8125rem,
    xxiv : 0.875rem,
    xxv : 0.9375rem,
    xxvi : 1rem,
    xxvii : 1.0625rem,
    xxviii : 1.125rem,
    xxix : 1.1875rem,
    xxx : 1.25rem,
    xxxi : 1.3125rem,
    xxxii : 1.375rem,
    xxxiii : 1.4375rem,
    xxxiv : 1.5rem,
    xxxv : 1.5625rem,
    xxxvi : 1.625rem,
    xl : 1.875rem,
    l : 2.152rem,
    xlviii : 2.375rem,
    xliv : 2.5rem,
    lvi : 2.875rem,
    liv : 2.75rem,
    lx : 3.125rem,
    lxiv : 3.375rem,
    lxx : 3.75rem,
    lxxx : 4.375rem,
    cxx: 7.5rem,
    cxxviii: 8rem,
);

@each $className,
$fontsize in $font-sizes {
    .size-#{$className} {
        // --font-size: calc(var(--base-font-size) + #{$fontsize}) !important;
        --font-size: calc(#{$base-font-size} + #{$fontsize}) !important;
        font-size: var(--font-size) !important;
    }
}

@include for-desktop-big {
    @each $className,
    $fontsize in $font-sizes {
        .size-#{$className} {
            // --font-size: calc(var(--base-font-size) + #{$fontsize}) !important;
            --font-size: calc(#{$base-font-size-big} + #{$fontsize}) !important;
            font-size: var(--font-size) !important;
        }
    }
}

// h1 {
//     @extend .size-xxxv;
// }
// h2 {
//     @extend .size-xxxii;
// }
// h3 {
//     @extend .size-xxvii;
// }
// h4 {
//     @extend .size-xxiii;
// }
// h5 {
//     @extend .size-xx;
// }
// h6 {
//     @extend .size-xvii;
// }
// p {
//     @extend .size-xvi;
// }
// small {
//     @extend .size-xiii;
// }


h1 {
    // font-size: 1.5rem !important;
    // @include for-desktop-big {  
    //     font-size: 2rem !important;
    // }
    font-size: 2.5rem !important;
}
h2 {
    font-size: 1.5rem !important;
    @include for-desktop-big {  
        font-size: 2rem !important;
    }
}
h3 {
    // font-size: 1.5rem !important;
    font-size: 1.3rem !important;
    @include for-desktop-big {  
        font-size: 1.5rem !important;
    }
}
h4 {
    // font-size: 1.3rem !important;
    font-size: 1.2rem !important;
    @include for-desktop-big {  
        font-size: 1.3rem !important;
    }
}
h5 {
    font-size: 1.05rem !important;
}
h6 {
    font-size: 0.9rem !important;
}
p {
    font-size: 14px !important;
    @include for-desktop-big {  
        font-size: 16px !important;
    }
}
.p-big {
    font-size: 15px !important;
    @include for-desktop-big {  
        font-size: 18px !important;
    }
}
small {
    font-size: 0.8rem !important;
}
 

// End Font Size 

// Error Message 
.error-mgs {
    @extend .size-xiv;
    color: var(--redc);
}
// End Error Message 