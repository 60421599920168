/* Widget Icon Popup Buttons  */
.count-box-action {
  position: absolute;
  top: 0rem;
  right: 0px;
  padding: 7px;
}
.count-box-action .ifsize {
  font-size: 1.5rem;
  background: #fff;
  border-radius: 100%;
  padding: 1px 1px 1px 2px;
}
.count-box-action .widget-icon {
  background: #77d7b163;
  padding: 0.4rem;
  border-radius: 100%;
  font-size: 0.75rem;
}
.count-box-action .widget-menu {
  position: absolute;
  z-index: 100;
  background: #fff;
  box-shadow: rgb(0 0 0 / 67%) 0px 1px 21px;
  border-radius: 12px;
  padding: 5px;  
  min-width: 170px; 
}
.count-box-action .widget-menu button {
  display: block;
  font-family: s-sbold;
  font-size: 14px;
  color: #2C275F;
  border: 0;
  background: transparent;
  padding: 3px 3px 3px 5px;  
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;  
  border-radius: 12px;
  margin-bottom: 3px;
}
.count-box-action .widget-menu button:last-child {
  margin-bottom: 0px;
}
.count-box-action .widget-menu button:focus {
  outline: 0px auto -webkit-focus-ring-color !important;
}
.count-box-action .widget-menu button:hover {
  color: #fff;  
  background: linear-gradient(90deg, rgba(0,174,239,1) 5%, rgba(0,174,239,1) 31%, rgba(34,53,201,1) 85%);
}
.count-box-action .widget-menu button.disable-bg-widgets {
  color: #2C275F;
}
.count-box-action .widget-menu button span.widgets-count { 
  color: #2C275F;
  font-size: 14px;
  padding: 0px 8px;  
  background: #e9e9e9;
  border-radius: 21px;
  min-width: 30px;
}
.count-box-action .widget-menu {
  display: none;
  border: 1px solid rgba(0,0,0,.2);
}
.count-box-action:hover .widget-menu {
  display: block !important;
  z-index: 1000;
}

@keyframes blink {
  0% {
         background-color: #509178;
  }
  50% {
         background-color: #77d7b1;
  }
  100% {
         background-color: #509178;
  }
}
@-webkit-keyframes blink {
  0% {
         background-color: #509178;
  }
  50% {
         background-color: #77d7b1;
  }
  100% {
         background-color: #509178;
  }
}
.widget-icon {
  padding: 15px 15px 15px 15px;
  text-align:center;
  margin-bottom: 4px;
  font-size: 24px;
  border-radius: 5px;
  -moz-transition:all 0.5s ease-in-out;
  -webkit-transition:all 0.5s ease-in-out;
  -o-transition:all 0.5s ease-in-out;
  -ms-transition:all 0.5s ease-in-out;
  transition:all 0.5s ease-in-out;
  -moz-animation:blink normal 1.5s infinite ease-in-out;
  -webkit-animation:blink normal 1.5s infinite ease-in-out;
  -ms-animation:blink normal 1.5s infinite ease-in-out;
  animation:blink normal 1.5s infinite ease-in-out;
}

@media only screen and (min-width: 1300px) {  
  .count-box-action .widget-menu {
    left: 7px;
  }
  .count-box-widget:last-child .widget-menu {
    right: 0px;
    transform: translate(-80%, 0px);
  }
}
@media only screen and (min-width: 1600px) {  
  .count-box-action .widget-menu button {
    font-size: 15px;
  }
}
@media only screen and (min-width: 1800px) {  
  .count-box-action {
    top: 5px;
  }
  .count-box-action .iconwhite {
    padding: 0.5rem;
    font-size: 0.9rem;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1290px) {  
  .count-box-action .widget-menu {
    left: inherit;
    right: 0px;
  }
}
/* End Widget Popup Buttons */	