// Mixin
@mixin for-phone-small {
    @media (max-width: 325px) { @content; }
}
@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
}
@mixin for-tablet {
    @media (min-width: 600px) { @content; }
}
@mixin for-ipad {
    @media (min-width: 767px) { @content; }
}
@mixin for-ipad-pro {
    @media (min-width: 1024px) { @content; }
}
@mixin for-tablet-big {
    @media (min-width: 1025px) { @content; }
}
@mixin for-desktop-min {
    @media (min-width: 1300px) { @content; }
}
@mixin for-desktop {
    @media (min-width: 1450px) { @content; }
}
@mixin for-desktop-15 {
    @media (min-width: 1550px) { @content; }
}
@mixin for-desktop-big {
    @media (min-width: 1600px) { @content; }
}
@mixin for-desktop-big-x {
    @media (min-width: 1700px) { @content; }
}
@mixin for-desktop-big-xx {
    @media (min-width: 1800px) { @content; }
}
@mixin for-desktop-big-125 {
    @media (min-device-width: 1500px) and (max-device-width: 1550px) { @content; }
}

// @media screen and (min-device-width: 1500px) and (max-device-width: 1550px) 



// @include for-desktop-big {
   
// }


// @mixin for-size($size) {
//     @if $size == phone-only {
//       @media (max-width: 599px) { @content; }
//     } @else if $size == tablet-portrait-up {
//       @media (min-width: 600px) { @content; }
//     } @else if $size == tablet-landscape-up {
//       @media (min-width: 900px) { @content; }
//     } @else if $size == desktop-up {
//       @media (min-width: 1200px) { @content; }
//     } @else if $size == big-desktop-up {
//       @media (min-width: 1800px) { @content; }
//     }
// }



// @mixin for-desktop {
//     @media (min-width: 1200px) { @content; }
// }
// @mixin for-big-desktop {
//     @media (min-width: 1800px) { @content; }
// }


// @mixin font-size-family($font-size, $font-family) {
//     font-size: $font-size;
//     font-family: $font-family;
// }
// @include for-phone-only {
//     .communicationbar {
//         display: none;
//     }
// }
// End Mixin

// @include media-breakpoint-up(xs) { ... }
// @include media-breakpoint-up(sm) { ... }
// @include media-breakpoint-up(md) { ... }
// @include media-breakpoint-up(lg) { ... }
// @include media-breakpoint-up(xl) { ... }

// // Example usage:
// @include media-breakpoint-up(sm) {
//   .some-class {
//     display: block;
//   }
// }