:root {
    // --c-font-size   :   7px;
    --black: #000;
    --white: #ffffff;
    --bluec: #2c275f;
    --greenc: #77D7B1;
    --greenc-light: #C4EEDD;
    --dark-greenc: #20a36f;
    --redc: #ff0000;
    --redc-light: #fb4545;
    // --redc-dark: #ff0000;
    --grayc: #F4F5F8;
    --grayc-bg: #F4F5F8;
    --grayc-icons: #a6a5a5;
    --grayc-light-icons: #d9d9d9;
    --grayc-light: #999999;
    --grayc-dark: #656263;
    --gray-pri: #E9ECEF;
    --gry-ic-bg: #C1C1C1;
    --gray-badge: #e9e9e9;
    --orange: #FBA751;
    --yellow: #ffc107;
    --orange-light: #fdecdb;
    --sky-blue: #5691E8;
    --sky-blue-icon: #00aeef;
    --bank-blue-icon: #0c75e6;
    --btn-focus: #a5a5a5;
    --gray-text: #656263;
    --gray-dark-text: #333333;
    --menu-active: #f0f0f0;
    --menu-deactive: #afafaf;
    // --disable        :   #d9d9d9;
    --disable: #d9d9d9;
    // --disable-excel: #ebebeb;
    --disable-excel: #f3f3f3;
    --text-disable: #b4b5b5;
    --assignby: #adf5d8;
    --closebtn: #656263;
    --gray-inactive: #e6e6e6;
    --gray-btn: #dedede;

    --placeholderc: #656263;
    --form-border: #ced4da;
    --gray-border: #f3f3f3;
    --gray-bg: #f3f3f3;
    --gray-mid-bg: #ebebeb;
    --gray-dark-border: #e0e0e0;
    --gray-notes-border: #c1c1c1;
    --shi-border: #656263;

    --table-bg: #f4f5f8;
    --checkbox-gray: #999999;
    --start-gray: #d9d9d9;
    --switch-gray: #d9d9d9;
    --switch-track: #cccccc;
    --start-yellow: #FFCC00;

    --overlay-black: #000000ad;
    --border-gray: #d9d9d9;

    --yellowp1: #FFD900;
    --yellowp2: #ffba00;
    --yellowp3: #ff9200;

    --green-blink1: #509178;
    --green-blink2: #77D7B1;

    --gray-shadow: #a5a5a5;

    --gray-light-shadow: 0 0 6px rgba(0,0,0,.1607843137254902);

    --dropdown-shadow: 0 0 10px 5px #a5a5a5;
    --light-shadow: 0 1px 20px #0000005c;
    --box-shadow-tooltip: 0 0 3px rgb(0 0 0 / 80%);
    --border-shadow: 0 0 3px rgb(0 0 0 / 20%);
    --dark-shadow: 0 0 12px 0 rgba(0, 0, 0, .75);
    --btn-border-shadow: 0 0 6px #696768;
    --side-filter-shadow: 0 7px 10px -7px rgba(0, 0, 0, .75);
    --active-shaded: transparent linear-gradient(180deg, #00acee, #2135c9) 0 0 no-repeat padding-box;
    --widget-active: linear-gradient(90deg, #00aeef 5%, #00aeef 31%, #2235c9 85%);
    --widget-disabled: linear-gradient(90deg, rgb(193 193 193) 5%, rgb(171 171 171) 31%, rgb(142 142 142) 85%);
    --check-box-shadow: -2px 0 0 white, 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white, 4px -10px 0 white, 4px -12px 0 white;
}

// Scroll Bar 
::-webkit-scrollbar {
    width: 8px;
    height: 10px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
}
::-webkit-scrollbar-thumb {
    background: #2C275F;
    border-radius: 5px;
}
// ::-webkit-scrollbar-thumb:hover {
//     background: #555;
// }

:root, :root * {
    scrollbar-color: #2C275F #f1f1f1 !important;
    scrollbar-width: thin !important;
    // scrollbar-width: auto !important;
    scrollbar-face-color: rgb(210, 210, 210);
    /* Firefox 63 compatibility */
    scrollbar-track-color: rgb(46, 54, 69);
    /* Firefox 63 compatibility */
}



.inve-zoom-img-div::-webkit-scrollbar-track,
.inve-zoom-img-div::-webkit-scrollbar-thumb {
    margin: 0 0 15px;
}

.inventory_form_sec::-webkit-scrollbar-track {
    margin: 1.4rem 0 0
}


// :root, .optionContainer, .recipt-sidebar-sec, .main-link-search-evidence-sec .main-link-drag-sec, .main-search-evidence-box-sec .se-box-height, .react-bs-table .react-bs-container-body, .inner-term-condition-sec, .bill-company-name select, .main-card-checkbox-sec, select.form-control, .inner-main-gift-sec, .inner-main-noti-sec, .profile-entities-list, .chart-scroll-sec, .chart-left-list, .modal-dialog .edit-bank, .user-entity-select-sec .assign-premission, .main-smartview-sec.main-list-history-popup-sec .card-header, .link-history-popup, .main-list-history-popup-sec .inner-smartview-sec, .modal-body, .pay-status-scroll, .inner-comment-scroll, .entity-scrollbar, .users-wise, .seaarch-user-task-sec .inner-seaarch-user-task-sec ul {
//     scrollbar-color: #2C275F #f1f1f1 !important;
//     scrollbar-width: thin !important;
//     scrollbar-face-color: rgb(210,210,210); /* Firefox 63 compatibility */
//     scrollbar-track-color: rgb(46,54,69); /* Firefox 63 compatibility */
// }
// End Scroll Bar 

// @media only screen and (min-width: 1600px) {
//     :root {
//         --c-font-size: 10px;
//     }
// }





.cog {
    -webkit-animation: cog 2s infinite;
    -moz-animation: cog 2s infinite;
    -ms-animation: cog 2s infinite;
    animation: cog 2s infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
    -ms-animation-timing-function: linear;
    animation-timing-function: linear
}

@-webkit-keyframes cog {
    100% {
        -webkit-transform: rotate(360deg)
    }
}

@-moz-keyframes cog {
    100% {
        -webkit-transform: rotate(360deg)
    }
}

@-ms-keyframes cog {
    100% {
        -webkit-transform: rotate(360deg)
    }
}

@keyframes cog {
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

// <colgroup>
// <col width="110px" />
// <col width="250px" />
// <col width="" />
// <col width="100px" className="text-right" />
// <col width="100px" className="text-center" />
// <col width="60px" className="text-center" />
// </colgroup>

// <tr>
// <td scope="col" className="lable-input-trans">Date</td>
// <td scope="col" className="lable-input-trans">Financial Institute</td>
// <td scope="col" className="lable-input-trans">Narration</td>
// <td scope="col" className="lable-input-trans text-right">Amount</td>
// <td scope="col" className="lable-input-trans text-center">Personal</td>
// <td scope="col" className="lable-input-trans text-center">Status</td>
// </tr>


// @media all and (min-width: 1024px){
//     body {
//         min-width: 1366px;
//     }
// }


// @media (min-width: 1024px) {
//     /* For screens wider than 1366px, use the large desktop grid */
//     .container, .container-lg, .container-md, .container-sm, .container-xl {
//       max-width: 1320px; /* Adjust as needed */
//     }
//     .container-fluid, .container-fluid-lg, .container-fluid-md, .container-fluid-sm, .container-fluid-xl {
//         max-width: 1320px; /* Adjust as needed */
//       }
//   }
