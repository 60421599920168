.sidebar-new-pagination {
    // position: absolute;
    // bottom: 20px;
    // right: 0.5rem;

    // background: var(--grayc);

    .page-item {
        @extend .cpointer;
        .page-link {
            @extend .s-reg, .size-xviii, .border-0, .p-2;
            color: var(--gray-text) !important;
            pointer-events: none;
            background-color: transparent;
            
            .pagi-arrow {
                @extend .size-xiii;
            }
        }
        &.active .page-link {
            @extend .s-sbold;
            color: var(--greenc) !important;
            text-decoration: underline;
        }
    }
}

// jsfiddle . net / so06bt1p/

// left panel fixed right panel scrollbar
// https://stackoverflow.com/questions/9749708/what-css-do-i-need-for-a-fixed-right-panel
// http://jsfiddle.net/DPZha/8/