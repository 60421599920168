body {
    background: var(--grayc);
    // min-height: 100vh;
}
.container-padding-new {
    // @extend .p-0;
}
.content-wrapper-new {
     @extend .mb-2;
    margin-left: 73px;
    background: var(--white);
    border-radius: 25px;
    // padding: 1rem 0;
    padding: 0.5rem 1px 1rem;
    margin-right: 0.7rem;

    // padding: 1.2rem;
    // @extend .p-4;
    // padding: 1rem;
    // min-height: 100vh;    
    // margin-top: 64px;
}
.content-wrapper-inner-new {
    // padding: 1.2rem;
    margin-left: 66px;

    // @include for-tablet-big {
    @include for-desktop-big {
        margin-left: 66px;
        // margin-left: 78px;   
    }
    .main-content-sec-new {
        // @extend .pt-0, .pb-3, .pl-3, .pr-3;
        // // height: 100vh;
        // background: var(--white);
        @extend .pl-0;
        .main-content-inner-new {
            @extend .pt-0, .pb-3, .pl-3, .pr-3;
            // height: 100vh;
            background: var(--white);
        }
        .form-sec-inner-left {
            @extend .pr-xl-0;
        }
        .form-sec-inner-right {}
    }

    // .sidebar-listing {
    //     width: 25%;
    //     max-width: 400px;
    // }
    // .main-content-sec {
    //     width: calc(100% - 400px);
    //     min-width: 75%;
    //     // margin-left: 400px;
    // }

    .sub-header-inner-new {
        // @extend .pt-3, .pb-1;
        @extend .pb-1;
        position: -webkit-sticky;
        /* Safari */
        position: sticky;
        // top: 80px;
        background: var(--white);
        border-bottom: 1px solid var(--shi-border);
        z-index: 1000;
        top: 58px;
        padding-top: 0.8rem;
        @include for-desktop-big {
            // top: 66px;
            top: 73px;
            padding-top: 1rem;
        }
        .sub-header-lr-new {
            @extend .d-flex, .gap-10;
            .shleft-new {
                @extend .mr-auto, .d-flex, .align-items-center, .gap-10, .flex-6;
                // @extend .mr-auto;
                // flex: 0.3;
                // .heading-shi {
                //     @extend .mb-1;
                // }
                .help-icon {
                    @extend .ml-0;
                }
            }
            // .shright-new {
            //     @extend .text-right;
            //     // flex: 0.9;
            // }
            .shright-new {
                @extend .flex-6, .justify-content-end;
                .heading-shi {
                    @extend .d-flex, .align-items-center, .gap-10;
                }
                @extend .text-right, .d-flex, .align-items-center, .gap-10;
                .sh-new-error-text p {
                    @extend .size-xiv, .mb-0;
                    line-height: normal;
                    // font-size: 14px !important;
                }
            }
        }
    }
}

.content-wrapper-inner-new .sub-header-inner-new,
.sidebar-listing .sidebar-new-header {
    min-height: 51px;
    @include for-desktop-big {
        min-height: 60px;
    }
}

// Report Page 
.report-menu-card {
    background: var(--grayc) !important;
    border-radius: 15px;
    .heading-shi {
        @extend .mb-0;
        padding: 0.8rem 1.2rem;
    }
    .report-card-menu-body {
        padding: 0.9rem 1rem;
        .list-group-item {
            @extend .size-xix, .s-sbold, .blue-text, .border-0, .cpointer;
            padding: 0.6rem 1rem;
            border-radius: 15px !important;
            &:not(:last-child) {
                margin-bottom: 0.8rem;
            }
        }
        a.active {
            @extend .white-text;
            background: var(--widget-active);
        }
    }
}

.report-detail-card {
    border: 1px solid var(--bluec);
    border-radius: 15px;
    padding: 0 1rem;
    .heading-shi {
        @extend .mb-0;
        padding: 0.8rem 0;
    }
    // .report-card-detail-body {
    //     padding: 0.8rem 0;
    // }
}
// End Report Page 


@media only screen and (min-width: 768px) and (max-width: 1024px){
    .heading-shi {
        font-size: 1.2rem !important;
    }
}


// Section Margin -- Top Bottom Margin
.sec-my {
    @extend .my-3;
}
.sec-mt {
    @extend .mt-3;
}
.sec-mb {
    @extend .mb-3;
}
// .sec-my {
//     // @extend .my-2;
//     margin: 0.65rem 0 !important; 
//     @include for-desktop {
//         // @extend .my-3;
//         margin: 0.8rem 0 !important;
//     }
// }
// End - Section Margin -- Top Bottom Margin
