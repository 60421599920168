.evi-row-col {
    // @extend .row, .no-gutters, .gap-15;
    @extend .row, .no-gutters, .gap-15;

    // .evi-left-col {
    //     @extend .col, .pl-0, .pr-0;
    //     width: 325px;
    //     max-width: 325px;
    // }
    // .evi-right-col {
    //     @extend .col, .pl-0, .pr-0;
    //     width: calc(100% - 325px);
    //     max-width: calc(100% - 325px);
    // }

    .evi-left-col {
        @extend .col-xl, .col-lg-12, .pl-0, .pr-0, .d-flex;
        // width: 240px;
        // max-width: 240px;
    }

    .evi-right-col {
        @extend .col-xl, .col-lg-12, .pl-0, .pr-0;
        // width: calc(100% - 240px);
        // max-width: calc(100% - 240px);
    }

    @include for-desktop-min {
        .evi-left-col {
            width: 240px;
            max-width: 240px;
        }

        .evi-right-col {
            // width: calc(100% - 240px);
            // max-width: calc(100% - 240px);
            width: calc(100% - 255px);
            max-width: calc(100% - 255px);
        }
    }

    @include for-desktop-big {
        .evi-left-col {
            width: 25%;
            max-width: 25%;
        }

        .evi-right-col {
            width: calc(100% - 25%);
            max-width: calc(100% - 25%);
        }
    }

    // @include for-desktop-big {
    //     .evi-left-col {
    //         width: 330px;
    //         max-width: 330px;
    //     }
    //     .evi-right-col {
    //         width: calc(100% - 330px);
    //         max-width: calc(100% - 330px);
    //     }
    // }    
}

.evi-card-sec-new {
    width: 100%;
    // border: 1px dashed var(--border-gray);
    // border-top: 0px; 
    // border-radius: 15px;
    // height: 100%;
    // height: 400px;

    .link-ev-h {
        // @extend .size-xvii, .text-center;
        @extend .text-center, .mb-0;
        border: 1px solid var(--grayc);
        padding: 0.6rem 0.7rem;
        background: var(--grayc);
        border-radius: 15px 15px 0 0;
    }

    .evi-cards-list {
        // padding: 0.5rem 0.5rem 0.7rem;
        padding: 0.3rem 0.2rem 0.7rem 0.2rem;
        // @extend .p-2; 
        border: 1px dashed var(--border-gray);
        border-top: 0px;
        border-radius: 0 0 15px 15px;

        // max-height: 350px;
        // height: 100%;
        overflow-y: auto;
        height: calc(100% - 48px);
        max-height: 290px;

        @include for-tablet-big {
            max-height: 75vh;
        }

        .ec-list-row {
            .ec-list-col {
                @extend .pl-lg-1, .pr-lg-1, .pl-xl-0, .pr-xl-0;
            }
        }

        // .ec-list-row {
        //     @extend .d-flex, .flex-wrap;
        //     gap: 15px;

        //     .ec-list-col {
        //         @extend .mb-0;
        //     }
        //     .evi-list-card {
        //         width: calc((100% / 3) - 10px);
        //     }
        // }

        @include for-desktop-min {
            // height: 100%;
            // min-height: 500px;
            // max-height: 65vh;            
        }
    }

    .evi-cards {
        @extend .p-2, .position-relative;
        border: 1px solid var(--border-gray);
        border-radius: 15px;
        // padding: 8px 7px 2px;
        background: var(--white);
        overflow: hidden;
        cursor: all-scroll;
        // min-height: 148px;
        // min-height: 140px;
    }

    .evi-add-new-card {
        @extend .text-center;

        .evi-cards {
            @extend .d-flex, .flex-column, .align-items-center, .justify-content-center, .gap-5;
            background: var(--grayc-ft);

            min-height: 8.5rem;
            max-height: 8.5rem;
            height: 92%;

            @include for-desktop-big {
                min-height: 9.5rem;
                max-height: 9.5rem;
                height: 92%;
            }

            .add-plus {
                @extend .size-xl;
            }

            p {
                @extend .size-xviii, .s-sbold, .mb-0;
            }
        }
    }

    .evi-card-line {
        @extend .d-flex, .align-items-center, .justify-content-between, .mb-1;
    }

    .evi-card-line-1 {
        @extend .evi-card-line, .gap-10;
    }

    .evi-card-line-2 {
        @extend .evi-card-line, .gap-10;
    }

    .evi-card-line-3 {
        // @extend .d-flex;
        @extend .evi-card-line, .gap-10;
    }

    .evi-name {
        @extend .size-xvii, .s-sbold, .gray-text;
        max-width: 65%;
    }

    .evi-gte-icon {
        @extend .size-xxvi, .d-flex, .align-items-center, .gap-6;

        .evi-edit-icon {
            @extend .size-xxiii, .cpointer;
        }
    }

    .evi-card-vendor {
        @extend .size-xvii, .s-reg, .gray-text;
        max-width: 65%;
    }

    .evi-card-date {
        @extend .size-xiii, .s-sbold, .gray-text;
        min-width: 70px;
        text-align: right;
    }

    .evi-card-amount {
        @extend .size-xvii, .s-sbold, .blue-text, .text-right, .w-100;
        padding: 0.1rem 0.3rem;
        border: 1px solid #f1f1f1;
        border-radius: 6px;
        // width: 65%;
    }

    .evi-card-link-icon {
        @extend .size-xvii, .text-center, .cpointer;
        // padding: 0.3rem 0.1rem 0.1rem;
        padding: 0.14rem 0.3rem 0;
        background: var(--grayc-lightc);
        border: 1px solid var(--grayc-lightc);
        border-radius: 6px;
        // min-width: 62px;
        min-width: 2.8rem;

        .elicon {
            @extend .size-xvii;
        }
    }

    .progres-bar-sec {
        @extend .mt-2;

        .progres-bar-yellow {
            @extend .mt-2;
            height: 7px;
            background: var(--grayc);
            // display: flex;
            // margin-top: 8px;
            // width: 100%;
            // background-color: #efefef;  
            
            .progress-bar {
                background: var(--yellowp1);
                /* Old browsers */
                background: -moz-linear-gradient(left, var(--yellowp1) 2%, var(--yellowp2) 53%, var(--yellowp2) 80%, var(--yellowp3) 100%);
                /* FF3.6-15 */
                background: -webkit-linear-gradient(left, var(--yellowp1) 2%, var(--yellowp2) 53%, var(--yellowp2) 80%, var(--yellowp3) 100%);
                /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to right, var(--yellowp1) 2%, var(--yellowp2) 53%, var(--yellowp2) 80%, var(--yellowp3) 100%);
                /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='var(--yellowp1)', endColorstr='var(--yellowp3)', GradientType=1);
                /* IE6-9 */
                border-radius: .25rem;
            }
        }


        .pbt {
            @extend .size-xiii, .s-sbold, .yellow-text, .text-center, .mb-0;
        }
    }

    .evi-card-vds-btns {
        // @extend .size-xiii, .d-flex, .align-items-center, .justify-content-center, .cpointer; 
        @extend .size-xv, .s-sbold, .d-flex, .align-items-center, .justify-content-center, .cpointer;
        border: 1px solid var(--border-gray);
        border-radius: 0 0 15px 15px;
        margin-top: -3.4rem;
        transition: all .2s ease-in-out;

        .ecvd-btns {
            @extend .d-flex, .align-items-center, .justify-content-center, .gap-4, .text-center;
            width: 50%;
            padding: 1.3rem 0.5rem 0.5rem;
        }

        .evi-btns-icon {
            @extend .size-xviii;
        }

        .evi-view-btns {
            @extend .gray-text;
            background: var(--grayc);
            border-radius: 0 0 0 15px;
        }

        .evi-discard-btns {
            @extend .white-text;
            background: var(--grayc-dark);
            border-radius: 0 0 15px 0;
        }

        .evi-select-btns {
            @extend .white-text;
            background: var(--greenc);
            border-radius: 0 0 15px 0;
        }
    }

    .evi-card-vds-btns {
        // display: none !important;
        // transition: all .2s ease-in-out; 
    }

    .evi-cards-box {
        margin-bottom: 1rem;

        &:hover .evi-card-vds-btns {
            // display: flex !important;       
            margin-top: -0.9rem;
        }
    }
}

.evi-card-list {
    @extend .d-flex, .flex-wrap;
    gap: 14px;

    .evi-cards-box {
        @extend .mb-0;
    }

    .evi-list-card {
        width: calc((100% / 3) - 10px);
    }
}

// .amber {
//     background: #fba751;
// }
// .active {
//     background: #5691e8;
// }

.no-evi-popup {
    @extend .position-absolute;
    top: 0;
    opacity: .1;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    background-color: var(--black);
    border-radius: 15px;
    cursor: not-allowed !important;
}

// Evidence Filter 
.evi-ss-tab-filter {
    @extend .d-flex, .justify-content-between, .align-items-center, .gap-20, .mb-2;
    min-height: 2.2rem;

    ul {
        @extend .p-0, .list-group, .list-group-horizontal, .cpointer;

        li {
            @extend .size-xviii, .s-sbold, .blue-text, .p-0, .border-0, .list-group-item;

            &:not(:last-child):after {
                content: "|";
                margin: 0 0.7rem;
                @extend .s-sbold, .blue-text;
            }

            &.active-evi-ss {
                @extend .green-text;
            }
        }
    }
}

.evi-filter-btn {
    // @extend .d-flex, .align-items-center, .pl-3, .pr-3, .size-xviii, .s-reg;
    @extend .d-flex, .align-items-center, .size-xv, .s-reg;
    gap: 5px;
    color: var(--gray-text);
    border-color: var(--bluec);
    border-radius: 26px;
    padding: 0.3rem 0.8rem;

    // height: calc(1.5em + 0.75rem + 1px);
    // @include for-desktop-big {
    //     height: calc(1.5em + 0.75rem + 7px);
    // }

    &:focus {
        border-color: var(--bluec);
        box-shadow: unset !important;
    }

    &:hover,
    &:active {
        color: var(--gray-text) !important;
        background-color: var(--white) !important;
    }

    &.active-filter {
        border: 1px solid var(--greenc) !important;
    }

    // .filter-icon { }
}

.evi-search-filter-popup {
    @extend .mb-2;
    padding: 0.6rem;
    border: 1px solid var(--form-border);
    border-radius: 15px;

    .form-group {
        margin-bottom: 0.6rem;
    }

    .form-control,
    .form-control::placeholder {
        @extend .size-xvi;
    }
}

// End Evidence Filter 