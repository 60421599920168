.sidebar-listing {
    // @extend .pl-1;
    // height: 92vh;
    // @extend .position-relative;
    // height: 95vh;
    // height: 92vh;
    // display: flex;
    // flex-direction: column;

    // .sidebar-fixed {
    //     position: fixed;
    //     flex: 0 0 22.5%;
    //     width: 100%;
    //     max-width: 22.5%;
    //     display: flex;
    //     flex-direction: column;
    //     height: 93%;
    // }
    .sidebar-fixed {
        position: fixed;
        display: flex;
        flex-direction: column;
        padding: 0 3rem 0px 0;
        // height: 93%;
        // height: calc(100% - 66px);   
        height: calc(100% - 56px);
        width: 100%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
        @include for-tablet-big {
            flex: 0 0 25%;
            max-width: 25%;
        }
        @include for-desktop-big {
            height: calc(100% - 70px);
            padding: 0 3rem 6px 0;
        }
    }
    // .sidebar-fixed {
    //     // flex: 0 0 22.5%;
    //     width: 100%;
    //     // max-width: 22.5%;
    //     position: fixed;  
    //     display: flex;
    //     flex-direction: column;   
    //     padding: 0 3rem 0 0;
    //     // height: 93%;
    //     height: calc(100% - 66px);        

    //     flex: 0 0 33.33333%;
    //     max-width: 33.33333%;
    //     @include for-tablet-big {
    //         flex: 0 0 25%;
    //         max-width: 25%;
    //     }
    // }

    .sidebar-new-header {
        // @extend .pt-3, .pb-1, .d-flex, .align-items-center, .gap-10;
        @extend .pb-1, .d-flex, .align-items-center, .gap-10;
        
        position: -webkit-sticky;
        /* Safari */
        position: sticky;
        // top: 80px;
        top: 57px; 
        
        padding-top: 0.55rem;
        @include for-desktop-min {
           padding-top: 0.8rem;
        }
        @include for-desktop-big-x {
            top: 66px;
            padding-top: 1rem;
        }

        background: var(--grayc);
        border-bottom: 1px solid var(--shi-border);
        z-index: 1000;

        .sidebar-icons {
            @extend .d-flex, .align-items-center, .gap-5, .ml-auto, .cpointer;
            // @extend d-flex, .align-items-center, .gap-10, .ml-auto, .cpointer, .mb-1; 
            margin-bottom: 4px;

            @include for-desktop-big {
                margin-bottom: 6px;
            }

            .s-filter-icon {
                // @extend .size-xxii, .p-2, .mt-2, .cpointer;
                @extend .size-xxii,  .cpointer;
                padding: 6px;
                background: var(--white);
                border-radius: 10px;
                // display: flex;
                // align-items: center;
                // justify-content: center;
            }
            .s-plus-icon .add-plus { 
                @extend .d-inline-block, .size-xxxii, .mt-1, .cpointer;
            }
            .s-add-icon {
                @extend .size-xvi, .p-2, .cpointer;
                background: var(--greenc);
                border-radius: 100px;

                &:before {
                    color: var(--white);
                }
            }
        }
        .filler_snapcr {
            margin-top: 3px;
        }
        // @extend .d-flex, .gap-10; 
        // .sleft {
        //     @extend .mr-auto;
        // }
        // .sright {
        //     @extend .d-flex, .gap-10;
        // }             
    }

    .sidebar-new-sec {
        @extend .mt-2, .pt-0, .pb-0, .pl-0, .pr-2, .mb-auto;
        height: 100%;
        // height: 82vh;
        overflow-y: auto;
        overflow-x: hidden;
        // height: 88vh;
        // height: 100%;
        // @extend .p-3;
        // @extend .pt-0, .pb-3, .pl-3, .pr-3;       
        // height: 100vh;
        // background: var(--white);
        //  @extend .d-flex, .gap-10;

        padding-right: 0.5rem;

        &.remove-new-scroller {
            padding-right: 0rem !important;
        }

        .form-group {
            margin-bottom: 0.6rem !important;

            @include for-desktop-big {
                margin-bottom: 0.8rem !important;
            }
        }
        .side-trans-h {
            margin: 0.3rem 0 0.7rem;
        }
        // Tags
        .sidebar-tag-list {
            @extend .mt-1, .mb-1, .s-sbold;

            .sidebar-tagname {
                @extend .size-xvi;
                color: var(--bluec) !important;
                background: var(--white) !important;
                border-radius: 10px;
                padding: 6px 15px;
                margin: 0 8px 8px 0;
                display: inline-block;
            }
        }
        // End Tags

           
    }
}

// Cards 
.sidebar-cards {
    // @extend .p-3;
    // @extend .position-relative, .mb-2;
    @extend .position-relative;
    background: var(--white) !important;
    border-radius: 15px;
    margin-bottom: 0.7rem;
    padding: 0.7rem 0.7rem 0.7rem 1.4rem;

    .cards-sec-center {
        // @extend .d-flex;
        @extend .d-flex, .align-items-center;
    }
    .new-evidance {
        @extend .text-center, .gray-text, .mb-0, .p-2;
        // padding: 1rem 1rem;
    }
    .no-item {
        @extend .text-center, .blue-text, .mb-0, .p-2;
    }
    // .no-item-list {
    //     @extend .p-lg-2;
    //     color: var(--bluec);
    //     background: var(--white);
    //     border-radius: 20px; 
    //     padding: 2.2rem;
    // }
    p {
        @extend .mb-0, .gray-text;
    }

    .titlesc,
    .namesc {
        @extend .mr-auto, .s-sbold;
    }
    .titlesc {
        @extend .size-xvii;
    }
    .titlesc-2 {
        @extend .pb-1;
        min-height: 1.2rem;
    }
    .namesc,
    .datesc {
        // @extend .size-xiv;
        font-size: 0.75rem !important;

        @include for-desktop-big {
            font-size: 0.9rem !important;
        }
    }
    .amountsc {
        // @extend d-flex align-items-center justify-content-end s-sbold blu-color text-right amnts
        @extend .size-xvii, .s-sbold, .blue-text, .text-right, .d-flex, .align-items-center, .justify-content-end;

        .ellipsisc {
            width: 130px;
            display: inline-block;
        }
    }
    // .datesc {
    //     @extend .text-right;
    // }
    .iconsc {
        @extend .size-xxx, .ml-1, .mb-1;
    }
    &.active:after {
        content: "";
        width: 13px;
        height: 100%;
        position: absolute;
        z-index: 8;
        top: 0;
        left: 0;
        border-top-left-radius: 18px;
        border-bottom-left-radius: 18px;
        background: var(--active-shaded);
    }
    .ss-cross {
        top: -0.7rem;
        right: -0.2rem;
        font-size: 1.2rem;
    }
}
// End Cards 

// Sidebar Filter Form 
// position: sticky;
// top: 0;
// z-index: 999;
// padding: 18px 16px;

.side-filter {
    @extend .mt-1, .p-3;
    background: var(--white);
    border-bottom: 5px solid var(--white);
    margin: 0 0 24px;
    border-radius: 20px;
    max-height: 73vh;
    overflow-y: auto;
    box-shadow: var(--side-filter-shadow);
    -webkit-box-shadow: var(--side-filter-shadow);
    -moz-box-shadow: var(--side-filter-shadow);
    // box-shadow: 0 7px 10px -3px rgba(0,0,0,.75);
    // -webkit-box-shadow: 0 7px 10px -7px rgba(0,0,0,.75);
    // -moz-box-shadow: 0 7px 10px -3px rgba(0,0,0,.75);

    form.search-side-sec {
        @extend .d-flex, .ml-lg-auto;

        input[type=text] {
            @extend .mb-0;
        }
        button {
            // @extend .size-xviii, .p-1, .d-flex, .align-items-center, .justify-content-center;
            @extend .p-1, .d-flex, .align-items-center, .justify-content-center, .border-0;
            background: var(--white) !important;
            border-bottom: 1px solid var(--form-border) !important;
            border-radius: 0 !important;
            outline: none;

            &.crosebtn {
                @extend .size-xiv;
            }
            &:focus {
                border-bottom: 1px solid var(--greenc) !important;
            }
        }
    }

    // .reset-apply-btn button {
    .center_apply_btn_new button {
        @extend .w-100;
    }
    .form-control{
        font-size: 0.75rem !important;
        &::placeholder {
            font-size: 0.75rem !important;
        }
        @include for-desktop-big {
            font-size: 1rem !important;
            &::placeholder {
                font-size: 1rem !important;
            }
        }
    }
}
// End Sidebar Filter Form 

// Tags
.main-applied-sidefilter {
    @extend .s-sbold;
    .sidebar-tagname {
        @extend .size-xvi;
        color: var(--bluec) !important;
        background: var(--white) !important;
        border-radius: 10px;
        padding: 6px 15px;
        margin: 0 8px 8px 0;
        display: inline-block;
    }
}

// End Tags

// .sidebar-ico {
//     @extend .size-xx, .cpointer;
//     background: var(--greenc);
//     border-radius: 100px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     // width: 27px;
//     // height: 27px;
// }
// .add-new-evi-sms, .add-new-evidence {
//     @extend .size-xxxiii, .cpointer;
//     // @extend .size-xviii, .cpointer, .text-center;
//     // // cursor: pointer;
//     // // text-align: center;
//     // display: inline-block;
//     // background: var(--greenc);
//     // border-radius: 25px;

//     // .icon-plus:before {
//     //     color: var(--white);
//     // }
// }