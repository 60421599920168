@font-face {
  font-family: 'icomoon';
  src:  url('../font-icon/icomoon.eot?d1awh3');
  src:  url('../font-icon/icomoon.eot?d1awh3#iefix') format('embedded-opentype'),
    url('../font-icon/icomoon.ttf?d1awh3') format('truetype'),
    url('../font-icon/icomoon.woff?d1awh3') format('woff'),
    url('../font-icon/icomoon.svg?d1awh3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-document-file-xml:before {
  content: "\eadb";
}
.icon-refresh-iconn .path1:before {
  content: "\ead7";
  color: rgb(0, 0, 0);
  opacity: 0.35;
}
.icon-refresh-iconn .path2:before {
  content: "\ead8";
  margin-left: -1em;
  color: rgb(119, 215, 177);
}
.icon-refresh-iconn .path3:before {
  content: "\ead9";
  margin-left: -1em;
  color: rgb(119, 215, 177);
}
.icon-refresh-iconn .path4:before {
  content: "\eada";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-appstore:before {
  content: "\ead2";
}
.icon-googleplay .path1:before {
  content: "\ead3";
  color: rgb(34, 153, 248);
}
.icon-googleplay .path2:before {
  content: "\ead4";
  margin-left: -1em;
  color: rgb(255, 193, 7);
}
.icon-googleplay .path3:before {
  content: "\ead5";
  margin-left: -1em;
  color: rgb(90, 207, 95);
}
.icon-googleplay .path4:before {
  content: "\ead6";
  margin-left: -1em;
  color: rgb(248, 68, 55);
}
.icon-image-file:before {
  content: "\ead1";
}
.icon-excel:before {
  content: "\eac8";
  color: #007732;
}
.icon-file:before {
  content: "\eac9";
}
.icon-folder_opened:before {
  content: "\eaca";
}
.icon-image:before {
  content: "\eacb";
  color: #77d7b1;
}
.icon-pdf_file .path1:before {
  content: "\eacc";
  color: rgb(253, 66, 51);
}
.icon-pdf_file .path2:before {
  content: "\eacd";
  margin-left: -1em;
  color: rgb(255, 64, 47);
}
.icon-ppt_file:before {
  content: "\eace";
  color: #ff5a29;
}
.icon-word_file:before {
  content: "\eacf";
  color: #1d409d;
}
.icon-zip:before {
  content: "\ead0";
  color: #00b8df;
}
.icon-three-dots:before {
  content: "\eac7";
  color: #656263;
}
.icon-plus-circled:before {
  content: "\eac6";
  color: #77d7b1;
}
.icon-file-icon:before {
  content: "\eac5";
  color: #2c275f;
}
.icon-pdf-icon:before {
  content: "\eac4";
  color: #2c275f;
}
.icon-menu-circle .path1:before {
  content: "\eabc";
  color: rgb(255, 255, 255);
}
.icon-menu-circle .path2:before {
  content: "\eabd";
  margin-left: -1em;
  color: rgb(119, 215, 177);
}
.icon-menu-circle .path3:before {
  content: "\eabe";
  margin-left: -1em;
  color: rgb(119, 215, 177);
}
.icon-menu-circle .path4:before {
  content: "\eabf";
  margin-left: -1em;
  color: rgb(119, 215, 177);
}
.icon-menu-circle-bg .path1:before {
  content: "\eac0";
  color: rgb(119, 215, 177);
}
.icon-menu-circle-bg .path2:before {
  content: "\eac1";
  margin-left: -1em;
  color: rgb(119, 215, 177);
}
.icon-menu-circle-bg .path3:before {
  content: "\eac2";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-menu-circle-bg .path4:before {
  content: "\eac3";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-menulines:before {
  content: "\eaba";
  color: #77d7b1;
}
.icon-menu-lines:before {
  content: "\eabb";
  color: #77d7b1;
}
.icon-copy-file:before {
  content: "\eab9";
  color: #77d7b1;
}
.icon-pdf:before {
  content: "\eab8";
  color: #2c275f;
}
.icon-email:before {
  content: "\eab6";
  color: #2c275f;
}
.icon-email-svg:before {
  content: "\eab7";
  color: #2c275f;
}
.icon-downloads:before {
  content: "\eab4";
}
.icon-download1:before {
  content: "\eab5";
}
.icon-check:before {
  content: "\eaa3";
  color: #2c275f;
}
.icon-Doc:before {
  content: "\eaa4";
  color: #2c275f;
}
.icon-DoubleArrow:before {
  content: "\eaa5";
  color: #2c275f;
}
.icon-Double-Arrow:before {
  content: "\eaa6";
}
.icon-Download:before {
  content: "\eaa7";
  color: #2c275f;
}
.icon-Edit:before {
  content: "\eaa8";
  color: #2c275f;
}
.icon-Folder:before {
  content: "\eaa9";
  color: #2c275f;
}
.icon-Information:before {
  content: "\eaaa";
  color: #2c275f;
}
.icon-jpg .path1:before {
  content: "\eaab";
  color: rgb(233, 233, 224);
}
.icon-jpg .path2:before {
  content: "\eaac";
  margin-left: -1em;
  color: rgb(217, 215, 202);
}
.icon-jpg .path3:before {
  content: "\eaad";
  margin-left: -1em;
  color: rgb(243, 213, 91);
}
.icon-jpg .path4:before {
  content: "\eaae";
  margin-left: -1em;
  color: rgb(38, 185, 154);
}
.icon-jpg .path5:before {
  content: "\eaaf";
  margin-left: -1em;
  color: rgb(20, 160, 133);
}
.icon-jpg .path6:before {
  content: "\eab0";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-jpg .path7:before {
  content: "\eab1";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-jpg .path8:before {
  content: "\eab2";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Star:before {
  content: "\eab3";
  color: #fc0;
}
.icon-bell:before {
  content: "\eaa0";
  color: #656263;
}
.icon-download:before {
  content: "\eaa1";
  color: #656263;
}
.icon-gift1:before {
  content: "\eaa2";
  color: #656263;
}
.icon-Link-icon:before {
  content: "\ea9f";
  color: #fba751;
}
.icon-Dashboard-green:before {
  content: "\ea64";
  color: #77d7b1;
}
.icon-Evidence-green:before {
  content: "\ea65";
  color: #77d7b1;
}
.icon-Exports-green:before {
  content: "\ea66";
  color: #77d7b1;
}
.icon-FI-green:before {
  content: "\ea67";
  color: #77d7b1;
}
.icon-Link-evidence-green:before {
  content: "\ea68";
}
.icon-Cheques .path1:before {
  content: "\ea69";
  color: rgb(251, 167, 81);
}
.icon-Cheques .path2:before {
  content: "\ea6a";
  margin-left: -1em;
  color: rgb(251, 167, 81);
}
.icon-Cheques .path3:before {
  content: "\ea6b";
  margin-left: -1em;
  color: rgb(251, 167, 81);
}
.icon-Cheques .path4:before {
  content: "\ea6c";
  margin-left: -1em;
  color: rgb(251, 167, 81);
}
.icon-Cheques .path5:before {
  content: "\ea6d";
  margin-left: -1em;
  color: rgb(251, 167, 81);
}
.icon-Cheques .path6:before {
  content: "\ea6e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Dashboard .path1:before {
  content: "\ea6f";
  color: rgb(251, 167, 81);
}
.icon-Dashboard .path2:before {
  content: "\ea70";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Doc-box .path1:before {
  content: "\ea71";
  color: rgb(0, 0, 0);
}
.icon-Doc-box .path2:before {
  content: "\ea72";
  margin-left: -1em;
  color: rgb(251, 167, 81);
}
.icon-Doc-box .path3:before {
  content: "\ea73";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Doc-box .path4:before {
  content: "\ea74";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Evidence .path1:before {
  content: "\ea75";
  color: rgb(0, 0, 0);
}
.icon-Evidence .path2:before {
  content: "\ea76";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Evidence .path3:before {
  content: "\ea77";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Evidence .path4:before {
  content: "\ea78";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Evidence .path5:before {
  content: "\ea79";
  margin-left: -1em;
  color: rgb(251, 167, 81);
}
.icon-Evidence .path6:before {
  content: "\ea7a";
  margin-left: -1em;
  color: rgb(251, 167, 81);
}
.icon-Evidence .path7:before {
  content: "\ea7b";
  margin-left: -1em;
  color: rgb(251, 167, 81);
}
.icon-Evidence .path8:before {
  content: "\ea7c";
  margin-left: -1em;
  color: rgb(251, 167, 81);
}
.icon-Evidence .path9:before {
  content: "\ea7d";
  margin-left: -1em;
  color: rgb(251, 167, 81);
}
.icon-Exports .path1:before {
  content: "\ea7e";
  color: rgb(251, 167, 81);
}
.icon-Exports .path2:before {
  content: "\ea7f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-FI .path1:before {
  content: "\ea80";
  color: rgb(251, 167, 81);
}
.icon-FI .path2:before {
  content: "\ea81";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Help .path1:before {
  content: "\ea82";
  color: rgb(251, 167, 81);
}
.icon-Help .path2:before {
  content: "\ea83";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Help .path3:before {
  content: "\ea84";
  margin-left: -1em;
  color: rgb(251, 167, 81);
}
.icon-Help .path4:before {
  content: "\ea85";
  margin-left: -1em;
  color: rgb(251, 167, 81);
}
.icon-Help .path5:before {
  content: "\ea86";
  margin-left: -1em;
  color: rgb(251, 167, 81);
}
.icon-Help .path6:before {
  content: "\ea87";
  margin-left: -1em;
  color: rgb(251, 167, 81);
}
.icon-Help .path7:before {
  content: "\ea88";
  margin-left: -1em;
  color: rgb(251, 167, 81);
}
.icon-Help .path8:before {
  content: "\ea89";
  margin-left: -1em;
  color: rgb(251, 167, 81);
}
.icon-Help .path9:before {
  content: "\ea8a";
  margin-left: -1em;
  color: rgb(251, 167, 81);
}
.icon-Link-Evidence .path1:before {
  content: "\ea8b";
  color: none;
}
.icon-Link-Evidence .path2:before {
  content: "\ea8c";
  margin-left: -1em;
  color: none;
}
.icon-Link-Evidence .path3:before {
  content: "\ea8d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Link-Evidence .path4:before {
  content: "\ea8e";
  margin-left: -1em;
  color: rgb(251, 167, 81);
}
.icon-Messenger .path1:before {
  content: "\ea8f";
  color: rgb(0, 0, 0);
}
.icon-Messenger .path2:before {
  content: "\ea90";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Messenger .path3:before {
  content: "\ea91";
  margin-left: -1em;
  color: rgb(251, 167, 81);
}
.icon-Messenger .path4:before {
  content: "\ea92";
  margin-left: -1em;
  color: rgb(251, 167, 81);
}
.icon-Profile-management .path1:before {
  content: "\ea93";
  color: rgb(251, 167, 81);
}
.icon-Profile-management .path2:before {
  content: "\ea94";
  margin-left: -1em;
  color: rgb(251, 167, 81);
}
.icon-Profile-management .path3:before {
  content: "\ea95";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Profile-management .path4:before {
  content: "\ea96";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Task-manager .path1:before {
  content: "\ea97";
  color: rgb(0, 0, 0);
}
.icon-Task-manager .path2:before {
  content: "\ea98";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Task-manager .path3:before {
  content: "\ea99";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Task-manager .path4:before {
  content: "\ea9a";
  margin-left: -1em;
  color: rgb(251, 167, 81);
}
.icon-Task-manager .path5:before {
  content: "\ea9b";
  margin-left: -1em;
  color: rgb(251, 167, 81);
}
.icon-Task-manager .path6:before {
  content: "\ea9c";
  margin-left: -1em;
  color: rgb(251, 167, 81);
}
.icon-Task-manager .path7:before {
  content: "\ea9d";
  margin-left: -1em;
  color: rgb(251, 167, 81);
}
.icon-Task-manager .path8:before {
  content: "\ea9e";
  margin-left: -1em;
  color: rgb(251, 167, 81);
}
.icon-clocki:before {
  content: "\ea63";
  color: #ff522e;
}
.icon-approved-icon .path1:before {
  content: "\ea31";
  color: rgb(101, 98, 99);
}
.icon-approved-icon .path2:before {
  content: "\ea32";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-attachment-icon .path1:before {
  content: "\ea33";
  color: rgb(0, 43, 110);
}
.icon-attachment-icon .path2:before {
  content: "\ea34";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-GST-icon .path1:before {
  content: "\ea35";
  color: rgb(0, 43, 110);
}
.icon-GST-icon .path2:before {
  content: "\ea36";
  margin-left: -1em;
  color: rgb(251, 167, 81);
}
.icon-GST-icon .path3:before {
  content: "\ea37";
  margin-left: -1em;
  color: rgb(251, 167, 81);
}
.icon-GST-icon .path4:before {
  content: "\ea38";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-GST-icon .path5:before {
  content: "\ea39";
  margin-left: -1em;
  color: rgb(254, 254, 255);
}
.icon-GST-icon .path6:before {
  content: "\ea3a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-GST-icon .path7:before {
  content: "\ea3b";
  margin-left: -1em;
  color: rgb(119, 215, 177);
}
.icon-GST-icon .path8:before {
  content: "\ea3c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-linked-icon .path1:before {
  content: "\ea3d";
  color: rgb(119, 215, 177);
}
.icon-linked-icon .path2:before {
  content: "\ea3e";
  margin-left: -1em;
  color: rgb(90, 97, 108);
}
.icon-linked-icon .path3:before {
  content: "\ea3f";
  margin-left: -1em;
  color: rgb(90, 97, 108);
}
.icon-linked-icon .path4:before {
  content: "\ea40";
  margin-left: -1em;
  color: rgb(90, 97, 108);
}
.icon-linked-icon .path5:before {
  content: "\ea41";
  margin-left: -1em;
  color: rgb(90, 97, 108);
}
.icon-linked-icon .path6:before {
  content: "\ea42";
  margin-left: -1em;
  color: rgb(90, 97, 108);
}
.icon-linked-icon .path7:before {
  content: "\ea43";
  margin-left: -1em;
  color: rgb(90, 97, 108);
}
.icon-linked-icon .path8:before {
  content: "\ea44";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-linked-icon .path9:before {
  content: "\ea45";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-messages-icon .path1:before {
  content: "\ea46";
  color: rgb(251, 167, 81);
}
.icon-messages-icon .path2:before {
  content: "\ea47";
  margin-left: -1em;
  color: rgb(90, 97, 108);
}
.icon-messages-icon .path3:before {
  content: "\ea48";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-not-approve-icon .path1:before {
  content: "\ea49";
  color: rgb(101, 98, 99);
}
.icon-not-approve-icon .path2:before {
  content: "\ea4a";
  margin-left: -1em;
  color: rgb(255, 68, 0);
}
.icon-not-linked-icon .path1:before {
  content: "\ea4b";
  color: rgb(119, 215, 177);
}
.icon-not-linked-icon .path2:before {
  content: "\ea4c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-not-linked-icon .path3:before {
  content: "\ea4d";
  margin-left: -1em;
  color: rgb(90, 97, 108);
}
.icon-not-ready-icon .path1:before {
  content: "\ea4e";
  color: rgb(251, 167, 81);
}
.icon-not-ready-icon .path2:before {
  content: "\ea4f";
  margin-left: -1em;
  color: rgb(90, 97, 108);
}
.icon-not-ready-icon .path3:before {
  content: "\ea50";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-processed-icon .path1:before {
  content: "\ea51";
  color: rgb(0, 43, 110);
}
.icon-processed-icon .path2:before {
  content: "\ea52";
  margin-left: -1em;
  color: rgb(119, 215, 177);
}
.icon-ready-icon .path1:before {
  content: "\ea53";
  color: rgb(251, 167, 81);
}
.icon-ready-icon .path2:before {
  content: "\ea54";
  margin-left: -1em;
  color: rgb(254, 254, 255);
}
.icon-ready-icon .path3:before {
  content: "\ea55";
  margin-left: -1em;
  color: rgb(90, 97, 108);
}
.icon-ready-icon .path4:before {
  content: "\ea56";
  margin-left: -1em;
  color: rgb(90, 97, 108);
}
.icon-ready-icon .path5:before {
  content: "\ea57";
  margin-left: -1em;
  color: rgb(90, 97, 108);
}
.icon-synched-icon .path1:before {
  content: "\ea58";
  color: rgb(0, 43, 110);
}
.icon-synched-icon .path2:before {
  content: "\ea59";
  margin-left: -1em;
  color: rgb(119, 216, 178);
}
.icon-synched-icon .path3:before {
  content: "\ea5a";
  margin-left: -1em;
  color: rgb(119, 216, 178);
}
.icon-tasks-icon .path1:before {
  content: "\ea5b";
  color: rgb(251, 167, 81);
}
.icon-tasks-icon .path2:before {
  content: "\ea5c";
  margin-left: -1em;
  color: rgb(90, 97, 108);
}
.icon-tasks-icon .path3:before {
  content: "\ea5d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-tasks-icon .path4:before {
  content: "\ea5e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-TDS-icon .path1:before {
  content: "\ea5f";
  color: rgb(0, 43, 110);
}
.icon-TDS-icon .path2:before {
  content: "\ea60";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-TDS-icon .path3:before {
  content: "\ea61";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-TDS-icon .path4:before {
  content: "\ea62";
  margin-left: -1em;
  color: rgb(119, 215, 177);
}
.icon-exclamation-gray-sign .path1:before {
  content: "\ea22";
  color: rgb(217, 217, 217);
}
.icon-exclamation-gray-sign .path2:before {
  content: "\ea23";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-exclamation-gray-sign .path3:before {
  content: "\ea24";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-exclamation-gray-sign .path4:before {
  content: "\ea25";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-exclamation-red-sign .path1:before {
  content: "\ea26";
  color: rgb(255, 82, 46);
}
.icon-exclamation-red-sign .path2:before {
  content: "\ea27";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-exclamation-red-sign .path3:before {
  content: "\ea28";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-exclamation-red-sign .path4:before {
  content: "\ea29";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-green-right:before {
  content: "\ea2a";
  color: #77d7b1;
}
.icon-icon-clock:before {
  content: "\ea2b";
  color: #ff522e;
}
.icon-note-orange .path1:before {
  content: "\ea2c";
  color: rgb(251, 167, 81);
}
.icon-note-orange .path2:before {
  content: "\ea2d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-note-orange .path3:before {
  content: "\ea2e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-note-orange .path4:before {
  content: "\ea2f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-orange-right:before {
  content: "\ea30";
  color: #fba751;
}
.icon-cancel:before {
  content: "\ea21";
  color: #707070;
}
.icon-personal-icon .path1:before {
  content: "\ea1e";
  color: rgb(119, 215, 177);
}
.icon-personal-icon .path2:before {
  content: "\ea1f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-personal-icon .path3:before {
  content: "\ea20";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Slider-arrow-1:before {
  content: "\ea19";
  color: #707070;
}
.icon-user .path1:before {
  content: "\ea1a";
  color: rgb(0, 0, 0);
}
.icon-user .path2:before {
  content: "\ea1b";
  margin-left: -1em;
  color: rgb(119, 215, 177);
}
.icon-user .path3:before {
  content: "\ea1c";
  margin-left: -1em;
  color: rgb(119, 215, 177);
}
.icon-Slider-arrow2:before {
  content: "\ea1d";
  color: #707070;
}
.icon-Green-arrow:before {
  content: "\ea17";
  color: #77d7b1;
}
.icon-Red-arrow:before {
  content: "\ea18";
  color: #ff522e;
}
.icon-right-arrow-1:before {
  content: "\ea15";
  color: #2c275f;
}
.icon-right-arrow:before {
  content: "\ea16";
  color: #2c275f;
}
.icon-incre .path1:before {
  content: "\ea11";
  color: rgb(112, 112, 112);
}
.icon-incre .path2:before {
  content: "\ea12";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-decre .path1:before {
  content: "\ea13";
  color: rgb(112, 112, 112);
}
.icon-decre .path2:before {
  content: "\ea14";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Discard-2:before {
  content: "\ea10";
  color: #fff;
}
.icon-Cross-with-grey-circle .path1:before {
  content: "\ea0b";
  color: rgb(101, 98, 99);
}
.icon-Cross-with-grey-circle .path2:before {
  content: "\ea0c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Cross-with-grey-circle .path3:before {
  content: "\ea0d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-delete:before {
  content: "\ea0e";
  color: #656263;
}
.icon-warning:before {
  content: "\ea0f";
  color: #ff6f51;
}
.icon-Minus:before {
  content: "\e9d5";
}
.icon-Plus:before {
  content: "\e9d7";
}
.icon-cross--grey:before {
  content: "\e9d8";
  color: #656263;
}
.icon-credit-card:before {
  content: "\e9d9";
  color: #f00;
}
.icon-credit-card1:before {
  content: "\e9da";
  color: #2c275f;
}
.icon-credit-card2:before {
  content: "\e9db";
  color: #fff;
}
.icon-Group-2453:before {
  content: "\e9dc";
  color: #2c275f;
}
.icon-Group-3553:before {
  content: "\e9dd";
  color: #f00;
}
.icon-Group-4430 .path1:before {
  content: "\e9de";
  color: rgb(119, 215, 177);
}
.icon-Group-4430 .path2:before {
  content: "\e9df";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4430 .path3:before {
  content: "\e9e0";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4430 .path4:before {
  content: "\e9e1";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4436 .path1:before {
  content: "\e9e2";
  color: rgb(119, 215, 177);
}
.icon-Group-4436 .path2:before {
  content: "\e9e3";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4436 .path3:before {
  content: "\e9e4";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4436 .path4:before {
  content: "\e9e5";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4437 .path1:before {
  content: "\e9e6";
  color: rgb(119, 215, 177);
}
.icon-Group-4437 .path2:before {
  content: "\e9e7";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4437 .path3:before {
  content: "\e9e8";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4437 .path4:before {
  content: "\e9e9";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4437 .path5:before {
  content: "\e9ea";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4437 .path6:before {
  content: "\e9eb";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4437 .path7:before {
  content: "\e9ec";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4437 .path8:before {
  content: "\e9ed";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4437 .path9:before {
  content: "\e9ee";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-plus2:before {
  content: "\e9ef";
  color: #fff;
}
.icon-Group-4449:before {
  content: "\e996";
  color: #2c275f;
}
.icon-Downward-sort--blue:before {
  content: "\e9d6";
}
.icon-chat-1:before {
  content: "\e9d0";
  color: #2c275f;
}
.icon-Group-4506 .path1:before {
  content: "\e9d1";
  color: rgb(119, 215, 177);
}
.icon-Group-4506 .path2:before {
  content: "\e9d2";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-send:before {
  content: "\e9d3";
  color: #fff;
}
.icon-Group-4604:before {
  content: "\e9ac";
  color: #fff;
}
.icon-Group-4601:before {
  content: "\e9ad";
  color: #77d7b1;
}
.icon-Group-4598 .path1:before {
  content: "\e9ae";
  color: rgb(119, 215, 177);
}
.icon-Group-4598 .path2:before {
  content: "\e9af";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4598 .path3:before {
  content: "\e9b0";
  margin-left: -1em;
  color: rgb(119, 215, 177);
}
.icon-Group-4596 .path1:before {
  content: "\e9b1";
  color: rgb(119, 215, 177);
}
.icon-Group-4596 .path2:before {
  content: "\e9b2";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4596 .path3:before {
  content: "\e9b3";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4596 .path4:before {
  content: "\e9b4";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4596 .path5:before {
  content: "\e9b5";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4595 .path1:before {
  content: "\e9b6";
  color: rgb(204, 201, 201);
}
.icon-Group-4595 .path2:before {
  content: "\e9b7";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4595 .path3:before {
  content: "\e9b8";
  margin-left: -1em;
  color: rgb(204, 201, 201);
}
.icon-Group-4594 .path1:before {
  content: "\e9b9";
  color: rgb(204, 201, 201);
}
.icon-Group-4594 .path2:before {
  content: "\e9ba";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4594 .path3:before {
  content: "\e9bb";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4594 .path4:before {
  content: "\e9bc";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4594 .path5:before {
  content: "\e9bd";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4276:before {
  content: "\e9be";
  color: #dadada;
}
.icon-Group--1:before {
  content: "\e9bf";
  color: #fff;
}
.icon-check-1 .path1:before {
  content: "\e9c0";
  color: rgb(255, 255, 255);
}
.icon-check-1 .path2:before {
  content: "\e9c1";
  margin-left: -1em;
  color: rgb(119, 215, 177);
}
.icon-Group-3634 .path1:before {
  content: "\e9c2";
  color: rgb(164, 119, 215);
}
.icon-Group-3634 .path2:before {
  content: "\e9c3";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-3634 .path3:before {
  content: "\e9c4";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-3634 .path4:before {
  content: "\e9c5";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-3634 .path5:before {
  content: "\e9c6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-3740 .path1:before {
  content: "\e9c7";
  color: rgb(119, 215, 177);
}
.icon-Group-3740 .path2:before {
  content: "\e9c8";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-3740 .path3:before {
  content: "\e9c9";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4244:before {
  content: "\e9ca";
  color: #ff522e;
}
.icon-list:before {
  content: "\e9cb";
  color: #77d7b1;
}
.icon-menu1:before {
  content: "\e9cc";
  color: #77d7b1;
}
.icon-move:before {
  content: "\e9cd";
  color: #656263;
}
.icon-select:before {
  content: "\e9ce";
  color: #fff;
}
.icon-view-details:before {
  content: "\e9cf";
  color: #707070;
}
.icon-bill:before {
  content: "\e978";
  color: #c7c7c7;
}
.icon-chat1 .path1:before {
  content: "\e979";
  color: rgb(0, 0, 0);
}
.icon-chat1 .path2:before {
  content: "\e97a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-chat1 .path3:before {
  content: "\e97b";
  margin-left: -1em;
  color: rgb(251, 167, 81);
}
.icon-chat1 .path4:before {
  content: "\e97c";
  margin-left: -1em;
  color: rgb(251, 167, 81);
}
.icon-cheque-2:before {
  content: "\e97d";
  color: #c7c7c7;
}
.icon-documents:before {
  content: "\e97e";
  color: #77d7b1;
}
.icon-Group-4398:before {
  content: "\e97f";
  color: #77d7b1;
}
.icon-Group-4402 .path1:before {
  content: "\e980";
  color: rgb(0, 0, 0);
}
.icon-Group-4402 .path2:before {
  content: "\e981";
  margin-left: -0.7705078125em;
  color: rgb(0, 0, 0);
}
.icon-Group-4402 .path3:before {
  content: "\e982";
  margin-left: -0.7705078125em;
  color: rgb(0, 0, 0);
}
.icon-Group-4402 .path4:before {
  content: "\e983";
  margin-left: -0.7705078125em;
  color: rgb(0, 0, 0);
}
.icon-Group-4402 .path5:before {
  content: "\e984";
  margin-left: -0.7705078125em;
  color: rgb(251, 167, 81);
}
.icon-Group-4402 .path6:before {
  content: "\e985";
  margin-left: -0.7705078125em;
  color: rgb(251, 167, 81);
}
.icon-Group-4402 .path7:before {
  content: "\e986";
  margin-left: -0.7705078125em;
  color: rgb(251, 167, 81);
}
.icon-Group-4402 .path8:before {
  content: "\e987";
  margin-left: -0.7705078125em;
  color: rgb(251, 167, 81);
}
.icon-Group-4402 .path9:before {
  content: "\e988";
  margin-left: -0.7705078125em;
  color: rgb(251, 167, 81);
}
.icon-Group-4403 .path1:before {
  content: "\e989";
  color: rgb(0, 0, 0);
}
.icon-Group-4403 .path2:before {
  content: "\e98a";
  margin-left: -0.9921875em;
  color: rgb(251, 167, 81);
}
.icon-Group-4403 .path3:before {
  content: "\e98b";
  margin-left: -0.9921875em;
  color: rgb(0, 0, 0);
}
.icon-Group-4403 .path4:before {
  content: "\e98c";
  margin-left: -0.9921875em;
  color: rgb(0, 0, 0);
}
.icon-Group-4404 .path1:before {
  content: "\e98d";
  color: rgb(251, 167, 81);
}
.icon-Group-4404 .path2:before {
  content: "\e98e";
  margin-left: -0.9912109375em;
  color: rgb(0, 0, 0);
}
.icon-Group-4405 .path1:before {
  content: "\e98f";
  color: rgb(251, 167, 81);
}
.icon-Group-4405 .path2:before {
  content: "\e990";
  margin-left: -0.96484375em;
  color: rgb(251, 167, 81);
}
.icon-Group-4405 .path3:before {
  content: "\e991";
  margin-left: -0.96484375em;
  color: rgb(0, 0, 0);
}
.icon-Group-4405 .path4:before {
  content: "\e992";
  margin-left: -0.96484375em;
  color: rgb(0, 0, 0);
}
.icon-Group-4408 .path1:before {
  content: "\e993";
  color: rgb(251, 167, 81);
}
.icon-Group-4408 .path2:before {
  content: "\e994";
  margin-left: -1.224609375em;
  color: rgb(0, 0, 0);
}
.icon-Group-5303:before {
  content: "\e995";
  color: #c7c7c7;
}
.icon-Group-5305 .path1:before {
  content: "\e997";
  color: rgb(251, 167, 81);
}
.icon-Group-5305 .path2:before {
  content: "\e998";
  margin-left: -1.1337890625em;
  color: rgb(0, 0, 0);
}
.icon-invoice1:before {
  content: "\e999";
  color: #77d7b1;
}
.icon-lightbulb .path1:before {
  content: "\e99a";
  color: rgb(251, 167, 81);
}
.icon-lightbulb .path2:before {
  content: "\e99b";
  margin-left: -0.833984375em;
  color: rgb(0, 0, 0);
}
.icon-lightbulb .path3:before {
  content: "\e99c";
  margin-left: -0.833984375em;
  color: rgb(251, 167, 81);
}
.icon-lightbulb .path4:before {
  content: "\e99d";
  margin-left: -0.833984375em;
  color: rgb(251, 167, 81);
}
.icon-lightbulb .path5:before {
  content: "\e99e";
  margin-left: -0.833984375em;
  color: rgb(251, 167, 81);
}
.icon-lightbulb .path6:before {
  content: "\e99f";
  margin-left: -0.833984375em;
  color: rgb(251, 167, 81);
}
.icon-lightbulb .path7:before {
  content: "\e9a0";
  margin-left: -0.833984375em;
  color: rgb(251, 167, 81);
}
.icon-lightbulb .path8:before {
  content: "\e9a1";
  margin-left: -0.833984375em;
  color: rgb(251, 167, 81);
}
.icon-lightbulb .path9:before {
  content: "\e9a2";
  margin-left: -0.833984375em;
  color: rgb(251, 167, 81);
}
.icon-Mask-Group-4:before {
  content: "\e9a3";
  color: #77d7b1;
}
.icon-to-do-list .path1:before {
  content: "\e9a4";
  color: rgb(0, 0, 0);
}
.icon-to-do-list .path2:before {
  content: "\e9a5";
  margin-left: -0.78125em;
  color: rgb(0, 0, 0);
}
.icon-to-do-list .path3:before {
  content: "\e9a6";
  margin-left: -0.78125em;
  color: rgb(0, 0, 0);
}
.icon-to-do-list .path4:before {
  content: "\e9a7";
  margin-left: -0.78125em;
  color: rgb(251, 167, 81);
}
.icon-to-do-list .path5:before {
  content: "\e9a8";
  margin-left: -0.78125em;
  color: rgb(251, 167, 81);
}
.icon-to-do-list .path6:before {
  content: "\e9a9";
  margin-left: -0.78125em;
  color: rgb(251, 167, 81);
}
.icon-to-do-list .path7:before {
  content: "\e9aa";
  margin-left: -0.78125em;
  color: rgb(251, 167, 81);
}
.icon-to-do-list .path8:before {
  content: "\e9ab";
  margin-left: -0.78125em;
  color: rgb(251, 167, 81);
}
.icon-crop:before {
  content: "\e95f";
  color: #fff;
}
.icon-drag1:before {
  content: "\e960";
  color: #fff;
}
.icon-filter:before {
  content: "\e961";
  color: #fff;
}
.icon-full-size:before {
  content: "\e962";
  color: #fff;
}
.icon-Group-3587 .path1:before {
  content: "\e963";
  color: rgb(119, 215, 177);
}
.icon-Group-3587 .path2:before {
  content: "\e964";
  margin-left: -0.978515625em;
  color: rgb(255, 255, 255);
}
.icon-Group-3587 .path3:before {
  content: "\e965";
  margin-left: -0.978515625em;
  color: rgb(255, 255, 255);
}
.icon-Group-3589 .path1:before {
  content: "\e966";
  color: rgb(255, 204, 0);
}
.icon-Group-3589 .path2:before {
  content: "\e967";
  margin-left: -1em;
  color: rgb(101, 98, 99);
}
.icon-Group-3589 .path3:before {
  content: "\e968";
  margin-left: -1em;
  color: rgb(101, 98, 99);
}
.icon-Group-3589 .path4:before {
  content: "\e969";
  margin-left: -1em;
  color: rgb(101, 98, 99);
}
.icon-magnifying-glass:before {
  content: "\e96a";
  color: #2c275f;
}
.icon-Path-26801:before {
  content: "\e96b";
}
.icon-Path-2910:before {
  content: "\e96c";
}
.icon-Path-2911:before {
  content: "\e96d";
}
.icon-refresh:before {
  content: "\e96e";
  color: #fff;
}
.icon-refresh-11:before {
  content: "\e96f";
  color: #fff;
}
.icon-round-info-button:before {
  content: "\e970";
  color: #2c275f;
}
.icon-zoom-in:before {
  content: "\e971";
  color: #2c275f;
}
.icon-full-size1:before {
  content: "\e972";
  color: #2c275f;
}
.icon-Group-2195:before {
  content: "\e973";
  color: #2c275f;
}
.icon-Group-2197:before {
  content: "\e974";
  color: #2c275f;
}
.icon-Path-26802:before {
  content: "\e975";
}
.icon-refresh1:before {
  content: "\e976";
  color: #2c275f;
}
.icon-tag-black-shape:before {
  content: "\e977";
  color: #fff;
}
.icon-Group-3216 .path1:before {
  content: "\e956";
  color: rgb(119, 215, 177);
}
.icon-Group-3216 .path2:before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-3216 .path3:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-3218 .path1:before {
  content: "\e959";
  color: rgb(255, 82, 46);
}
.icon-Group-3218 .path2:before {
  content: "\e95a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-3218 .path3:before {
  content: "\e95b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-3218 .path4:before {
  content: "\e95c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-3218 .path5:before {
  content: "\e95d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-3218 .path6:before {
  content: "\e95e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-3283-1 .path1:before {
  content: "\e94f";
  color: rgb(119, 215, 177);
}
.icon-Group-3283-1 .path2:before {
  content: "\e950";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-3283-1 .path3:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-3283-1 .path4:before {
  content: "\e952";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-3284 .path1:before {
  content: "\e953";
  color: rgb(44, 39, 95);
}
.icon-Group-3284 .path2:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-paper-clip:before {
  content: "\e955";
  color: #fff;
}
.icon-calendar:before {
  content: "\e926";
  color: #2c275f;
}
.icon-check-mark-1:before {
  content: "\e927";
  color: #74d1ac;
}
.icon-drag:before {
  content: "\e928";
  color: #fff;
}
.icon-Group-25071 .path1:before {
  content: "\e929";
  color: rgb(94, 159, 253);
}
.icon-Group-25071 .path2:before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-25071 .path3:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-2508 .path1:before {
  content: "\e92c";
  color: rgb(251, 167, 81);
}
.icon-Group-2508 .path2:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-2508 .path3:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-2508 .path4:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-2508 .path5:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-2509 .path1:before {
  content: "\e931";
  color: rgb(255, 82, 46);
}
.icon-Group-2509 .path2:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-2509 .path3:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-2509 .path4:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-2509 .path5:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-2509 .path6:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-2509 .path7:before {
  content: "\e937";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-2509 .path8:before {
  content: "\e938";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-2509 .path9:before {
  content: "\e939";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-2510 .path1:before {
  content: "\e93a";
  color: rgb(119, 215, 177);
}
.icon-Group-2510 .path2:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-2510 .path3:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-3180 .path1:before {
  content: "\e93d";
  color: rgb(119, 215, 177);
}
.icon-Group-3180 .path2:before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-3180 .path3:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-3180 .path4:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-3180 .path5:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-3181 .path1:before {
  content: "\e942";
  color: rgb(119, 215, 177);
}
.icon-Group-3181 .path2:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-3181 .path3:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-3181 .path4:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-3181 .path5:before {
  content: "\e946";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-3181 .path6:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-3182 .path1:before {
  content: "\e948";
  color: rgb(119, 215, 177);
}
.icon-Group-3182 .path2:before {
  content: "\e949";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-3182 .path3:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-3182 .path4:before {
  content: "\e94b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Path-2680:before {
  content: "\e94c";
}
.icon-refresh-1:before {
  content: "\e94d";
  color: #fff;
}
.icon-Union-3:before {
  content: "\e94e";
  color: #fff;
}
.icon-arrow-down-sign-to-navigate:before {
  content: "\e91d";
  color: #656263;
}
.icon-edit:before {
  content: "\e91e";
  color: #2c275f;
}
.icon-funnel:before {
  content: "\e91f";
  color: #2c275f;
}
.icon-Group-2328:before {
  content: "\e920";
  color: #2c275f;
}
.icon-Group-3015:before {
  content: "\e921";
  color: #2c275f;
}
.icon-invoice:before {
  content: "\e922";
  color: #2c275f;
}
.icon-Path-2107:before {
  content: "\e923";
}
.icon-plus1:before {
  content: "\e924";
  color: #2c275f;
  color: #fff;
}
.icon-receipt:before {
  content: "\e925";
  color: #2c275f;
}
.icon-chat:before {
  content: "\e902";
  color: #2c275f;
}
.icon-dashboard-1:before {
  content: "\e903";
  color: #fff;
}
.icon-gift:before {
  content: "\e904";
  color: #656263;
}
.icon-Group-303:before {
  content: "\e905";
}
.icon-Group-2504 .path1:before {
  content: "\e906";
  color: rgb(119, 215, 177);
}
.icon-Group-2504 .path2:before {
  content: "\e907";
  margin-left: -3.955078125em;
  color: rgb(101, 98, 99);
}
.icon-Group-2504 .path3:before {
  content: "\e908";
  margin-left: -3.955078125em;
  color: rgb(119, 215, 177);
}
.icon-Group-2504 .path4:before {
  content: "\e909";
  margin-left: -3.955078125em;
  color: rgb(101, 98, 99);
}
.icon-Group-2504 .path5:before {
  content: "\e90a";
  margin-left: -3.955078125em;
  color: rgb(101, 98, 99);
}
.icon-Group-2504 .path6:before {
  content: "\e90b";
  margin-left: -3.955078125em;
  color: rgb(101, 98, 99);
}
.icon-Group-2504 .path7:before {
  content: "\e90c";
  margin-left: -3.955078125em;
  color: rgb(101, 98, 99);
}
.icon-Group-2504 .path8:before {
  content: "\e90d";
  margin-left: -3.955078125em;
  color: rgb(101, 98, 99);
}
.icon-Group-2504 .path9:before {
  content: "\e90e";
  margin-left: -3.955078125em;
  color: rgb(101, 98, 99);
}
.icon-Group-2504 .path10:before {
  content: "\e90f";
  margin-left: -3.955078125em;
  color: rgb(101, 98, 99);
}
.icon-Group-2504 .path11:before {
  content: "\e910";
  margin-left: -3.955078125em;
  color: rgb(101, 98, 99);
}
.icon-Group-2507 .path1:before {
  content: "\e911";
  color: rgb(255, 255, 255);
}
.icon-Group-2507 .path2:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Group-2516:before {
  content: "\e913";
  color: #2c275f;
}
.icon-Group-2518:before {
  content: "\e914";
  color: #fff;
}
.icon-Group-2519:before {
  content: "\e915";
  color: #fff;
}
.icon-Group-2520:before {
  content: "\e916";
  color: #fff;
}
.icon-Group-2546:before {
  content: "\e917";
  color: #2c275f;
}
.icon-loupe:before {
  content: "\e918";
  color: #656263;
}
.icon-menu:before {
  content: "\e919";
  color: #77d7b1;
}
.icon-Path-2755:before {
  content: "\e91a";
  color: #2c275f;
}
.icon-plus:before {
  content: "\e91b";
  color: #77d7b1;
}
.icon-XMLID_223:before {
  content: "\e91c";
  color: #fff;
}
.icon-Group-2534:before {
  content: "\e900";
  color: #fff;
}
.icon-Mask-Group-1:before {
  content: "\e901";
  color: #fff;
}
.icon-Group-4545 .path1:before {
  content: "\e9d4";
  color: rgb(255, 255, 255);
}
.icon-Group-4545 .path2:before {
  content: "\e9f0";
  margin-left: -1em;
  color: rgb(44, 39, 95);
}
.icon-Group-4545 .path3:before {
  content: "\e9f1";
  margin-left: -1em;
  color: rgb(44, 39, 95);
}
.icon-Group-4545 .path4:before {
  content: "\e9f2";
  margin-left: -1em;
  color: rgb(44, 39, 95);
}
.icon-Group-4545 .path5:before {
  content: "\e9f3";
  margin-left: -1em;
  color: rgb(44, 39, 95);
}
.icon-add:before {
  content: "\e9f4";
}
.icon-Group-45061 .path1:before {
  content: "\e9f5";
  color: rgb(119, 215, 177);
}
.icon-Group-45061 .path2:before {
  content: "\e9f6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-45061 .path3:before {
  content: "\e9f7";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-45061 .path4:before {
  content: "\e9f8";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-45061 .path5:before {
  content: "\e9f9";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4507 .path1:before {
  content: "\e9fa";
  color: rgb(119, 215, 177);
}
.icon-Group-4507 .path2:before {
  content: "\e9fb";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4507 .path3:before {
  content: "\e9fc";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4507 .path4:before {
  content: "\e9fd";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4507 .path5:before {
  content: "\e9fe";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4507 .path6:before {
  content: "\e9ff";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4507 .path7:before {
  content: "\ea00";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4507 .path8:before {
  content: "\ea01";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4507 .path9:before {
  content: "\ea02";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4507 .path10:before {
  content: "\ea03";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Group-4509:before {
  content: "\ea04";
  color: #77d7b1;
}
.icon-Group-4510 .path1:before {
  content: "\ea05";
  color: rgb(255, 255, 255);
}
.icon-Group-4510 .path2:before {
  content: "\ea06";
  margin-left: -1em;
  color: none;
}
.icon-Group-4510 .path3:before {
  content: "\ea07";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Group-4511 .path1:before {
  content: "\ea08";
  color: rgb(255, 255, 255);
}
.icon-Group-4511 .path2:before {
  content: "\ea09";
  margin-left: -1em;
  color: none;
}
.icon-Group-4511 .path3:before {
  content: "\ea0a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}


/* 
.icon-cancel:before {
  content: "\ea21";
  color: #707070;
}

.icon-plus1:before {
  content: "\e924";
  color: #2c275f;
  color: #fff;
}

.icon-Group-2520:before {
  content: "\e916";
  color: #fff;
}
*/