.quick-menu {
    @extend .size-xxii;
    background: var(--greenc);
    height: 100vh;   
    // padding: 2.7rem 0.4rem;
    // width: 68px;
    padding: 2.7rem 0.3rem;
    width: 64px;
    // width: 270px;
    position: fixed;    
    
    left: 0;  
    z-index: 1050;
    border-top-right-radius: 25px;
    transition: all .3s ease-in-out;

    top: auto;
    @include for-desktop-big { 
        // top: 66px;
    }

    ul {
        list-style: none;
        li {
            margin-bottom: 0.7rem; 
            @include for-desktop-big { 
                margin-bottom: 1.3rem; 
            }
        }               
        .quick-link-text {
            display: none;
            // display: inline-block !important;            
            font-family: s-sbold !important;
            white-space: nowrap;
            overflow: hidden;            
            margin-left: 15px;
        } 
        & a {
            color: var(--white) !important;
            padding: 0.4rem 1rem 0.3rem 0.5rem;
            min-height: 50px;
            .quick-icons {
                font-size: 1.563rem; 
                @include for-desktop-big {
                    font-size: 1.438rem;
                }

                .snap-side-icon {
                    font-size: 2rem; 
                    @include for-desktop-big {
                        font-size: 1.8rem;
                    }
                }

                min-width: 40px;
                // @extend .size-xxiii;

                &:before, span:before {
                    color: var(--white);
                }
                // span:before {
                //     color: var(--white);
                // }

                // &:hover::before {
                //     color: var(--greenc) !important;
                // }
            }
            &:hover {
                color: var(--greenc) !important;
                background: var(--white);
                border-radius: 9px;

                .quick-icons {
                    &:before, span:before {
                        color: var(--greenc) !important;
                    }
                }
            }  
        }  
    }
    &:hover {
        width: 270px;
        transition: all .3s ease-in-out;
        .quick-link-text {
            // display: inline-block !important;
            display: inline-flex !important;
            align-items: center !important;
        }
    }
}