.home-profile {
    @extend .d-flex, .align-items-center, .gap-15;

    .home-profile-img {
        width: 65px;
        height: 65px;
        font-size: 1.8rem;

        @include for-desktop-big {
            width: 73px;
            height: 73px;
            font-size: 2.3rem;
        }

        border-radius: 100%;
        box-shadow: var(--border-shadow);

        @extend .s-sbold, .green-bg, .white-text;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .home-profile-desc {
        .home-ph {
            @extend .mb-1;
        }

        .home-pp {
            @extend .p-big, .blue-text, .mb-0;
        }
    }
}


.home-boxs {
    @extend .p-3;
    box-shadow: var(--gray-light-shadow);
    // box-shadow: 0 0 6px rgba(0,0,0,.1607843137254902);
    border-radius: 15px;
    min-height: 300px;
    height: 100%;

    margin-bottom: 20px;

    @include for-desktop-min {
        margin-bottom: 0px;
    }
}

.message-cards {
    // @extend .mb-3;
    background: var(--grayc-bg);
    padding: 0.7rem;
    border-radius: 15px;
    min-height: 75px;

    &:not(:last-child) {
        margin-bottom: 12px;
    }

    .msg-card-heading {
        @extend .d-flex, .align-items-center, .justify-content-between, .gap-10;

        p {
            @extend .size-xvii, .s-sbold, .ellipsisc, .mb-0;
            color: var(--gray-text);
        }
    }

    .msg-card-desc {
        @extend .size-xvi;
        color: var(--gray-text);
        line-height: normal;
        margin: 0.5rem 0;
    }

    .msg-card-date {
        @extend .size-xiv, .text-right, .mb-0;
        color: var(--gray-text);
    }
}


// Task 
.home-task-counts {
    @extend .d-flex, .align-items-center, .justify-content-between, .gap-15;

    .hm-task-count-boxs {
        @extend .w-100;
        border-radius: 15px;
        padding: 8px 5px;

        p {
            @extend .mb-0, .s-sbold, .white-text, .text-center;
            line-height: normal;
        }

        .hm-count-title {
            // @extend .size-xx;    
            font-size: 1rem !important;

            @include for-desktop-big {
                font-size: 1.2rem !important;
            }
        }

        .hm-count-char {
            // @extend .size-xxxv;
            font-size: 1.5rem !important;

            @include for-desktop-big {
                font-size: 2rem !important;
            }
        }
    }
}

.home-task-list {
    @extend .py-2;
    border-bottom: 0.5px solid var(--border-gray);

    .hm-head-title {
        @extend .size-xviii, .s-sbold, .blue-text, .cpointer, .mb-0;
    }

    .hm-task-date {
        @extend .d-flex, .justify-content-between, .gap-10; 

        p {
            @extend .size-xvi, .gray-text, .text-right, .mb-0;
        }
    }
}

// End Task 