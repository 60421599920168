// Basic popup settings 
.modal {
    // z-index: 100000000;
}

// .popup-model .table tbody tr:hover {
//     background: var(--table-bg);
// }

.modal-open .modal {
    // overflow-x: hidden;
    // overflow-y: auto;
    background: var(--overlay-black);
}

.popup-model {
    .modal-title {
        @extend .size-xxvii, .d-flex, .align-items-center, .blue-text;

        .mp-icon {
            @extend .mr-2;
        }
    }

    .modal-content {
        // @extend .p-0;
        border-radius: 15px;
        padding: 7px 18px;
    }

    .modal-body {
        // @extend .p-0;
        padding: 15px 0px;
        @include for-desktop-big-125 {
            padding: 10px 0px;
        }
    }

    .modal-header {
        // @extend .m-0;
        // padding: 0 0 10px;
        padding: 0 0 10px;
        margin: 3px 0px;
        // @extend .p-0, .m-0;
        // padding: 10px 0;
        // margin: 0 20px;
        border-bottom: 1px solid var(--shi-border);
    }

    .modal-header .close {
        // padding: 3px 8px;
        box-shadow: none;
        outline: none;
        background: var(--closebtn);
        color: var(--white);
        border-radius: 22px;
        // margin: -26px -34px;
        margin: -1.6rem -2rem;
        opacity: 1;
        z-index: 1000;

        width: 15px;
        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1px;

        &:hover {
            opacity: 1;
        }
    }

    .pmsg-text {
        @extend .size-xviii, .gray-text;
    }

    .pmsg-gray-center {
        @extend .gray-text, .text-center;
    }

    .pmsg-blue-center {
        @extend .size-xxv, .s-sbold, .blue-text, .text-center, .mb-2;
    }

}
// End Basic popup settings 

// Error
// .error-popup {
//     .pmsg-text {
//         @extend .size-xviii, .gray-text;
//     }

//     .pmsg-gray-center {
//         @extend .gray-text, .text-center;
//     }

//     .pmsg-blue-center {
//         @extend .size-xxv, .s-sbold, .blue-text, .text-center, .mb-2;
//     }
// }
// End Error 

// PM master-user-model 
.pm-master-user-model .modal-dialog {
    max-width: 550px;
    @include for-desktop-big {
        max-width: 660px;
    }
}
// End PM master-user-model 


// Changelog
.change-log-model {
    .modal-dialog {
        max-width: 80%;

        @include for-tablet-big {
            max-width: 70%;
        }
    }

    .modal-body {
        @extend .pt-0, .pb-1;
        overflow-y: auto;
        max-height: 74vh;
        // margin: 7px;
    }

    .table {
        tr:hover {
            // background: var(--table-bg);
            background: unset !important;
        }

        thead th {
            @extend .size-xx, .s-sbold, .blue-text;
            // vertical-align: top !important;
            border: none;
            font-weight: normal;
        }

        th,
        td {
            @extend .size-xvii, .blue-text, .s-reg;
            vertical-align: top !important;
            // padding: 0.30rem;
        }
    }

    .datech-width {
        min-width: 200px;
    }

    .idch-width {
        min-width: 100px;
    }

    .namech-width {
        min-width: 230px;
    }
}

// End Changelog

// Bulk Upload
.bulk-popup-new {
    .modal-dialog {
        @include for-tablet {
            max-width: 82%;
        }
    }

    .modal-body {
        @extend .pt-1, .pb-2;
    }

    .bulk-popup-brd {
        @extend .mt-3, .mb-3;
        border: 1px solid var(--grayc-light-icons);
        border-radius: 11px;
        padding: 0.6rem;

        .bulk-popup-pluse {
            @extend .d-flex, .align-items-center, .flex-column, .justify-content-center, .text-center;
            // @extend .d-flex, .align-items-center, .flex-column, .justify-content-center;
            border: 2px dashed var(--grayc-light-icons);
            // text-align: center;
            border-radius: 11px;
        }

        .bulk-popup-table {
            min-height: 44vh;
            max-height: 45vh;
            overflow-y: auto;
            background: var(--grayc);
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;

            // tr:hover {
            //     background: none !important;
            // }

            // p {
            //     @extend .size-xviii;
            // }
            // .icon-plus {
            //     @extend .size-xxxv;
            // }

            // table {
            //     background-color: var(--white);
                
            //     tr:hover {
            //         background: none !important;
            //     }

            //     td {
            //         @extend .size-xviii;
            //         vertical-align: top !important;
            //         padding: 0.4rem 0.5rem;
            //         border: 1px solid var(--border-gray) !important;

            //         &.va-middle {
            //             vertical-align: middle !important;
            //         }
            //     }

            //     thead {
            //         td {
            //             @extend .blue-text, .s-sbold;
            //             // border-bottom-width: 1px !important;
            //         }
            //     }

            //     tbody {
            //         td {
            //             @extend .gray-text, .s-reg;
            //             // border: 1px solid #d9d9d9!important;
            //             // color: #656263;
            //         }
            //     }
            // }
        }
    }

    p {
        @extend .size-xviii;
    }

    .icon-plus {
        @extend .size-xxxv;
    }

    .bulk-upstatus {
        @extend .mb-0, .mr-auto, .d-flex, .align-items-center;
        .spanupst {
            margin: 0 4px 0px;
            display: inline-block;
        }
    }
    .bulkb-save-btn {
        @extend .mt-0, .mt-lg-0, .mt-md-3, .text-right, .d-flex, .align-items-center, .justify-content-end;
    }
}
// End Bulk Upload

// Bill-wise Details
.bill-wise-model {
    .modal-title {
        @extend .justify-content-between, .w-100;

        .bill-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 65%;
        }
    }

    .table tbody tr:hover {
        background: transparent !important;
    }

    .modal-body {
        @extend .pr-1;
        overflow-y: auto;
        max-height: 75vh;
        // padding-right: 7px;
    }

    .min-invoice-height {
        @extend .mt-0, .pr-1;
        min-height: 300px;
        // overflow-y: auto;
        // max-height: 65vh;
    }

    .modal-dialog {
        @include for-tablet {
            max-width: 900px;
        }
    }

    // .modal-body {
    //     @extend .pt-1, .pb-2;
    // }

    .bill-wisep-agst-ref {
        button {
            border: 0;
            @extend .w-100, .p-0;

            &.dropdown-toggle::after {
                display: none;
            }
        }

        .dropdown-menu.show {
            // @extend .p-1;
            // padding: 0 0.25rem 0;
            padding: 0.5rem 0.7rem !important;
            max-height: 160px;
            overflow-y: auto;
            box-shadow: var(--light-shadow);
            border-radius: 12px;
            width: 400px;
        }

        table {
            thead {
                tr {
                    background: transparent !important;
                }

                th {
                    @extend .s-sbold;
                }
            }

            tbody td {
                @extend .blue-text;
            }
        }
    }

    .form-group {
        margin-bottom: 0;
    }
}

// End Bill-wise Details


.list-history-new-popup {
    // @extend .d-none;
    @extend .position-fixed;
    left: 70px;
    bottom: 3px;
    height: 91.6vh;
    background: var(--white);
    box-shadow: var(--light-shadow);
    z-index: 1030;

    width: 703px;

    @include for-desktop-big-x {
        width: 870px;
    }

    .list-history-content {
        @extend .position-relative, .p-2, .h-100;

        p {
            @extend .size-xvi, .s-reg;
        }
    }

    .heading-list {
        @extend .pb-2, .mb-0;
        // @extend .pb-2, .mb-0;
        border-bottom: 1px solid var(--shi-border);
    }

    // table {
    //     width: 100%;

    //     th,
    //     td {
    //         @extend .size-xvii, .s-reg;
    //     }
    // }
}

// .add-bank-model .modal-dialog, .billwise-model .modal-dialog 
.add-new-bank-model .modal-dialog {
    max-width: 900px;
}







// Profile Menegement --------------- PM

// Multiple Entities Popup 
.multiple-entity-popup {
    @extend .position-absolute;
    top: 44px;
    @include for-desktop-big {
        top: 57px;
    }
    right: 0 !important;
    left: unset !important;
    z-index: 1050 !important;

    width: 500px;
    background-color: var(--white);
    border-radius: 15px;
    box-shadow: var(--dark-shadow);
}

.pmep-crose-icon {
    @extend .d-flex, .align-items-center, .justify-content-end;

    .gray-icon {
        @extend .cpointer, .mt-1;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }
}

// cards
.multiple-entity-cards {
    @extend .position-relative;
    border: 1px solid var(--border-gray);
    border-radius: 15px;
    padding: 0.5rem;
    margin-top: 0.8rem;

    *p {
        @extend .s-sbold;
    }

    .pmec-name-mail {
        .pmec-name {
            @extend .size-xx, .ellipsisc;
            margin-bottom: 2px !important;
        }

        .pmec-mail {
            @extend .size-xv, .mb-0;
        }
    }

    .pmec-l-edate {
        padding: 0.2rem 0.5rem;
        background: var(--grayc) !important;
        border-radius: 10px;

        .pmec-l {
            @extend .size-xvii;
            margin-bottom: 2px !important;
        }

        .pmec-edate {
            @extend .size-xv, .mb-0;

            span {
                @extend .size-xiv;
            }
        }
    }
}

// end cards
// End Multiple Entities Popup 

// Sponsor Entity Popup
.sponsor-entity-cards {
    @extend .position-relative;
    border: 1px solid var(--border-gray);
    border-radius: 12px;
    margin-top: 0.8rem;
    transition: all 0.5s;
    .pmesc-name-mail {
        padding: 0.1rem 0.5rem;
        .pmesc-name {
            @extend .size-xviii, .s-sbold, .ellipsisc, .mb-0;
            line-height: normal;
        }
        .pmesc-mail {
            @extend .size-xv, .mb-0;
        }
    }
    .pmesc-code {
        @extend .size-xxiii, .s-sbold, .white-text, .d-flex, .align-items-center, .justify-content-center, .h-100, .mb-0;
        letter-spacing: 3px;
        padding: 0.5rem 0.8rem;
        background: var(--greenc) !important;
        border-radius: 12px;
        line-height: normal
    }
    .pmesc-copy-btn {
        display: none;
        @extend .position-absolute;
        right: 0.6rem;
        bottom: -2rem;
        .pmesc-copybtn {
            @extend .border-0, .blue-text, .s-sbold;
            font-size: 0.9rem;
            padding: 5px 16px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }

    &:hover {
        @extend .mb-5;
        .pmesc-copy-btn {
            display: block;
        }
    }
}
.pmesc-copy-success {
    @extend .position-absolute, .size-xx, .s-sbold, .gray-text, .d-flex, .align-items-center, .justify-content-center, .gap-10;    
    top: 20%;
    right: 7%;
    z-index: 1000;    
    border-radius: 12px;    
    background: var(--grayc);
    box-shadow: var(--dark-shadow); 
    padding: 0.4rem 0.8rem;  

    .icon-personal-icon {
        @extend .size-xxvii;
    }
}
// End Sponsor Popup 
// End Profile Menegement --------------- PM


// Snapshot Quick Entry popup 
.snap_quick_popup .modal-dialog {
    max-width: 550px;
}
// End Snapshot Quick Entry popup ---------
















// .bulk-upstatus .spanupst {
//     margin: 0 4px 0px;
//     display: inline-block;
// }
// .font-colr {
//     color: #656263;
// }
// .ft-sz-18 {
//     font-size: 18px;
// }
// .s-reg {
//     font-family: s-reg!important;
// }

// .main-content-sec .inner-content-sec .main-file-item-sec .right-inner-content-sec .right-inner-content-sec-box .toggle-btn-sec {
//     display: table;
//     margin: 0;
//     border: none;
//     padding: 6px 13px;
//     border-radius: 25px;
// }
// .main-content-sec .inner-content-sec .main-file-item-sec .toggle-btn-sec .switch {
//     position: relative;
//     display: inline-block;
//     width: 55px;
//     height: 24px;
//     margin: 0 6px;
// }
// .main-content-sec .inner-content-sec .main-file-item-sec .toggle-btn-sec .switch input {
//     opacity: 0;
//     width: 0;
//     height: 0;
// }
// .main-content-sec .inner-content-sec .main-file-item-sec .toggle-btn-sec input:checked+.slider {
//     background-color: #77d7b1;
// }
// .main-content-sec .inner-content-sec .main-file-item-sec .toggle-btn-sec .slider.round:before {
//     border-radius: 50%;
// }
// .main-content-sec .inner-content-sec .main-file-item-sec .toggle-btn-sec .slider:before {
//     position: absolute;
//     content: "";
//     height: 20px;
//     width: 20px;
//     left: 4px;
//     bottom: 2px;
//     background-color: #fff;
//     transition: .4s;
// }
// .main-content-sec .inner-content-sec .main-file-item-sec .toggle-btn-sec .slider:before {
//     position: absolute;
//     content: "";
//     height: 20px;
//     width: 20px;
//     left: 4px;
//     bottom: 2px;
//     background-color: #fff;
//     transition: .4s;
// }
// .main-content-sec .inner-content-sec .main-file-item-sec .toggle-btn-sec .slider.round {
//     border-radius: 34px;
// }
// .main-content-sec .inner-content-sec .main-file-item-sec .toggle-btn-sec .slider {
//     position: absolute;
//     cursor: pointer;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background-color: #ccc;
//     transition: .4s;
// }
// .main-content-sec .inner-content-sec .main-file-item-sec .toggle-btn-sec input:checked+.slider:before {
//     -webkit-transform: translateX(26px);
//     transform: translateX(26px);
// }
// .main-content-sec .inner-content-sec .main-file-item-sec .toggle-btn-sec .slider.round:before {
//     border-radius: 50%;
// }


// .bulk-popup .main-content-sec .inner-content-sec {
//   padding: 0 !important;
// }
// .bulk-popup .main-file-item-sec {
//   background: #ffffff !important;
//   margin: 0 !important;
//   border-radius: 15px !important;
//   padding: 0 !important;
// }
// .bulk-popup .main-file-item-sec .toggle-btn-sec {
//   border: 1px solid #D9D9D9 !important; 
//   display: flex !important; 
//   align-items: center !important; 
// }

// .toggle-btn-second .toggle-btn-sec {
//   border: 1px solid #D9D9D9 !important; 
//   display: inline-flex !important; 
//   align-items: center !important; 
// }
// .toggle-btn-second .main-file-item-sec {
//   background: #fff !important;
//   padding: 0px !important;
//   margin: 0px !important;
// }
// .toggle-btn-second .inner-content-sec {
//   padding: 0px !important;
// }