.main-sub-header-new {

    // @extend .sec-mb;
    margin-bottom: 1rem;

    border-bottom: 1px solid var(--bluec);
    .sub-header-cicon {
        @extend .justify-content-end;
    }
}
.breadcrumb-title-new {
    @extend .d-flex, .align-items-center, .pb-2, .mb-0;
}
.breadcrumb-header-new {
    .breadcrumb {
        // @extend .p-0, .mb-1;
        @extend .p-0, .mb-0;
        background-color: transparent;
        .breadcrumb-item-new, .breadcrumb-item-new a {
            @extend .s-reg;
            color: var(--bluec);
            font-size: 0.85rem;
            @include for-tablet-big {
                font-size: 1rem;
            }
            @include for-desktop-big {
                font-size: 1.25rem;
            }
            &:hover {
                text-decoration: none;
            }

            cursor: pointer;
            // &:not(:last-child) {
            //     cursor: pointer;
            // }
        }        
        .breadcrumb-item-new+.breadcrumb-item-new {
            display: flex;
            align-items: center;
            &:before {
                content: "";
                display: inline-block;
                border: solid var(--bluec);
                border-width: 0 1px 1px 0px;
                vertical-align: middle;
                transform: rotate(314deg);
                -webkit-transform: rotate(314deg);
                margin-top: 3px;
                margin-right: 0.5rem;
                margin-left: 0.3rem;
                @include for-desktop-min {
                    margin-right: 0.7rem;
                    margin-left: 0.5rem;
                }

                padding: 3px;
                @include for-desktop-big {
                    padding: 4px;
                }

                // margin: 0 0.4rem;
            }
        }      
    }    
}