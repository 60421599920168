.tabsDesign {
    .nav-tabs {
        border-bottom: 1px solid var(--bluec);

        .nav-link {
            @extend .s-sbold, .blue-text, .pt-0;
            padding: 0.5rem 1.25rem;
            border: 0 solid transparent;

            font-size: 1.4rem;
            @include for-desktop {
                font-size: 2rem;
            }
            @include for-desktop-big-125 {
                font-size: 1.55rem;
            }

            &.active {
                // @extend .blue-text;
                // background-color: var(--white);
                border-bottom: 5px solid;
                border-color: var(--bluec) !important;
                margin: 0 0 -3px;
            }  
        }
    }

    .tab-content {
        @extend .mt-3;
    }


    // .nav-tabs .nav-link {
    //     border-top-left-radius: 0;
    //     border-top-right-radius: 0;
    //     border: 0;
    //     padding: 10px 28px;
    // }
}


// .tabsDesign {
//     .nav-tabs {
//         border-bottom: 1px solid var(--shi-border);
//     }
//     .nav-tabs .nav-link {
//         @extend .size-xxxii, .s-sbold, .blue-text;
//         border: 0 solid transparent;
//     }
//     .nav-tabs .nav-item.show .nav-link,
//     .nav-tabs .nav-link.active {
//         @extend .blue-text;
//         background-color: var(--white);
//         border-bottom: 5px solid;
//         border-color: var(--bluec) !important;
//         margin: 0 0 -3px;
//     }
//     .nav-tabs .nav-link {
//         border-top-left-radius: 0;
//         border-top-right-radius: 0;
//         border: 0;
//         padding: 10px 28px;
//     }
// }