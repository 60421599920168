/* Actions Menu */
.action-new-menu {
    .dropdown-toggle::after {
        display: none;
    }

    .action-btn-height {
        // @extend .size-xxviii, .d-flex, .align-items-center, .justify-content-center;
        @extend .d-flex, .align-items-center, .justify-content-center;
        font-size: 1.4rem;
        height: calc(1em + 0.67rem + 4px);
        width: calc(1em + 0.67rem + 4px);
        border-radius: 100%;
        border-color: var(--greenc);
        background-color: var(--greenc) !important;

        @include for-desktop-big {
            font-size: 1.813rem;
            height: calc(1.2em + 0.75rem + 0px);
            width: calc(1.2em + 0.75rem + 0px);
        }
    }

    .dropdown-menu {
        // @extend .pt-1, .pb-1, .pl-0, .pr-0;
        padding: 8px 0 6px;        
        box-shadow: var(--dropdown-shadow);
        border-color: transparent;
        border-radius: 12px;

        width: 215px !important;
        @include for-desktop {
            width: 280px !important;
        }

        button {
            // @extend .s-reg, .size-xvii, .d-flex, .text-left, .w-100;
            @extend .s-reg, .d-flex, .text-left, .w-100;
            color: var(--gray-text);
            border: 0;
            background: transparent;
            line-height: 1.4;
            padding: 4px 14px;

            font-size: 0.95rem;
            @include for-desktop-15 {
                font-size: 1rem;
            }

            &:hover {
                color: var(--greenc);
            }

            &:focus {
                outline: unset !important;
            }
        }

        // button.botom-border {
        //     padding: 9px 14px;
        //     // border-bottom: 1px solid var(--form-border) !important;
        //     border-bottom: 1px solid var(--form-border) !important;
        // }
        hr {
            @extend .d-none;
            margin-top: 2px;
            margin-bottom: 2px;
            border: 0;
            border-top: 1px solid var(--form-border) !important;
        }
    }
}

.action-new-icon-sec {
    width: 23px;
    margin-top: 2px;
    // margin-right: 7px;
    // text-align: center;

    .action-new-icon {
        // @extend .mr-2;
        width: 23px;
        display: inline-block;
        
        &:before,
        span:before {
            color: var(--greenc) !important;
        }
    }
    .action-new-icon2 {
        // @extend .mr-2;
        width: 23px;
        display: inline-block;
        
        &:before,
        span:before {
            color: var(--gray-text) !important;
        }
    }
}

.action-menu button[disabled],
.action-new-menu button[disabled] {
    background: var(--disable);
    color: var(--gray-text) !important;
}
/* End Actions Menu */





/* DocBox Three Dots Menu */
.dbox-threedot-icon {
    font-size: 4px;
    padding: 10px 5px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}
.three-dots-menu {
    div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
        @extend .border-0;
        width: 200px !important;
        box-shadow: var(--dropdown-shadow);
        line-height: 30px;
        margin-left: 7px;
        border-radius: 12px;
    }
    ul {
        padding: 5px 0;
    }
    .css-aqqxug-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper:before {
        display: none !important;
    }
    hr {
        @extend .d-none;
        margin-top: 2px;
        margin-bottom: 2px;
        border: 0;
        border-top: 1px solid var(--form-border) !important;
    }
    button {
        // @extend .s-reg, .size-xvi, .d-flex, .text-left, .w-100;
        // @extend .s-reg, .size-xvi, .d-flex, .align-items-center, .text-left, .w-100;
        @extend .s-reg, .d-flex, .align-items-center, .text-left, .w-100;
        color: var(--gray-text);
        border: 0;
        background: transparent;
        display: flex;
        line-height: 1.4;
        padding: 4px 14px;

        font-size: 0.95rem;
        @include for-desktop-15 {
            font-size: 1rem;
        }
        
        &:focus {
            // border: 0;
            // outline: 0;
            // border-color: #fff !important;
            // outline: 0 !important;
            outline: unset !important;
        }
        &:hover {
            color: var(--greenc);
        }
    }
    .action-docs-icon {
        // margin-right: 10px;
        width: 21px;
        &:before, span:before {
            color: var(--greenc);
        }
    }
}





// .action-doc-menu div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
//     width: 200px !important;
//     box-shadow: var(--dropdown-shadow);
//     line-height: 30px;
//     border: 0px !important;
// }

// .action-doc-menu ul {
//     padding-top: 0 !important;
//     padding-bottom: 0 !important;
// }

// .action-doc-menu .css-aqqxug-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper:before {
//     display: none !important;
// }

// .action-doc-menu hr {
//     border-top: 1px solid #cfcfcf;
// }

// .action-doc-menu button {
//     font-family: s-reg;
//     font-size: 16px;
//     color: #656263;
//     border: 0;
//     background: transparent;
//     display: flex;
//     line-height: 1;
//     margin: 5px 10px;
//     margin: 10px;
//     text-align: left;
// }

// .action-doc-menu .action-icon {
//     margin-right: 10px;
//     width: 17px;
// }

// .action-doc-menu .action-icon:before,
// .action-doc-menu .action-icon span:before {
//     color: #77D7B1 !important;
// }

// .action-doc-menu button:hover {
//     color: #77D7B1;
// }

// .action-doc-menu button:focus {
//     border: 0;
//     outline: 0;
// }

// .action-doc-menu button:focus {
//     border-color: #fff !important;
//     outline: 0 !important;
// }






/* -------  */

/* .action-doc-menu ul.MuiList-root.MuiList-padding.MuiMenu-list li {
    font-family: s-reg !important;
    font-size: 16px !important;
    color: #656263;
    border: 0;
    background: transparent;
    display: flex;
    line-height: 1;
    margin: 10;
    text-align: left;
  } */
/* .action-doc-menu .dropdown-toggle::after{
    display: none;
  } */
/* .action-doc-menu .icon-menu-lines {
    font-size: 2rem;
  } */
/* .action-doc-menu .tgl-btn {
    font-size: 2.8rem;
  } */
/* .action-doc-menu .tgl-btn {
    border: 1px solid #77d7b1;
    background: #77d7b1;
    / 
    padding: 0.45rem 0.45rem !important;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center !important;
    margin-bottom: 0;
  }
  .action-doc-menu .tgl-btn .iconwhite:before{
    color: #fff !important;
  } */

/* @media only screen and (max-width: 1450px)
  { */
/* .action-doc-menu .icon-menu-lines {
      font-size: 1.5rem;
    } */
/* .action-doc-menu .tgl-btn {
      padding: 0 0.3rem !important;
    } */
/* } */
/* End DocBox Three Dots Menu */