body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.date-filter {
  display: none !important;
}
.main-add-detail-image{
  position: relative;

}
.main-add-detail-image .cross span{
  display: block;
  background: #656263;
  color: #fff;
  padding: 3px;
  border-radius: 25px;
  height: 27px;
  width: 27px;
  position: absolute;
  z-index: 7;
  right: 18%;
  top: -12px;
  cursor: pointer;
}

.reverse-sort-icon{
transform: rotate( 180deg );
display: inline-block;
}