// Widget Cards
.widget-filter-new {
    text-align: center;

    .wtitle-new {
        @extend .mt-2, .mb-1;
        // @extend .size-xxii, .mb-0;
        font-size: 1.1rem !important;

        @include for-desktop-big {
            font-size: 1.3rem !important;
        }
    }

    .wcounter-new {
        // @extend .size-xliv, .s-sbold, .mb-0;
        @extend .s-sbold, .mb-0;
        font-size: 2.3rem !important;

        @include for-desktop-big {
            font-size: 3rem !important;
        }
    }

    .wtitle-new,
    .wcounter-new {
        color: var(--bluec);
        line-height: normal;
    }

    .widget-block-new {
        @extend .mb-3, .mb-xl-0, .cpointer, .position-relative;
        background: var(--grayc);
        color: var(--bluec);
        border-radius: 21px;

        // padding: 1.8rem 0.5rem 1rem;
        padding: 1.1rem 0.7rem 1rem;

        @include for-desktop-big {
            // padding: 1.5rem 0.7rem 1rem;
            padding: 1.3rem 0.7rem;
        }

        // padding: 32px 15px 16px;
        // position: relative;
        // height: 93%;
        // min-height: 165px;
        &.active-widget {
            background: var(--widget-active);
            color: var(--white);

            .wtitle-new,
            .wcounter-new {
                color: var(--white);
            }
        }
    }
}

.disable-new-widgets {
    background: var(--disable) !important;
    cursor: not-allowed !important;

    // @extend .blue-text;
    .wtitle-new,
    .wcounter-new {
        @extend .blue-text;
    }

    // &:hover {
    //     @extend .blue-text;
    // }
}

/* Widget Icon Popup Buttons  */
.count-box-new-action {
    @extend .position-absolute, .p-2;
    top: 0rem;
    right: 0px;

    .ifsize {
        // @extend .size-xxii, .white-text;
        @extend .white-text;
        font-size: 1.2rem !important;

        @include for-desktop-big {
            font-size: 1.375rem !important;
        }

        background: var(--white);
        border-radius: 100%;
        padding: 1px 1px 1px 2px;
    }

    .widget-dropdown-menu {
        @extend .position-absolute, .border-0;
        right: 0;

        @include for-tablet-big {
            right: unset;
        }

        z-index: 100;
        background: var(--white);
        box-shadow: var(--dropdown-shadow);
        border-radius: 12px;
        padding: 5px;
        // min-width: 170px;
        min-width: 160px;
        display: none;

        button {
            @extend .size-xv, .s-sbold, .blue-text, .d-flex, .align-items-center, .justify-content-between, .w-100, .border-0;
            background: transparent;
            padding: 3px 3px 3px 5px;
            border-radius: 12px;
            margin-bottom: 3px;

            &:last-child {
                @extend .mb-0;
            }

            &:focus {
                outline: 0px auto -webkit-focus-ring-color !important;
            }

            &:hover {
                @extend .white-text;
                // color: var(--white);
                background: var(--widget-active);
            }

            &.disable-new-widgets {
                &:hover {
                    @extend .blue-text;
                    // color: var(--bluec);
                }
            }

            span.widgets-count {
                @extend .blue-text, .size-xiv;
                padding: 0px 8px;
                background: var(--gray-badge);
                border-radius: 21px;
                min-width: 30px;
            }
        }
    }

    &:hover .widget-dropdown-menu {
        display: block !important;
        z-index: 1000;
    }
}

.count-box-new-widget:last-child .widget-dropdown-menu {
    right: 0;
    // -webkit-transform: translate(-80%);
    // transform: translate(-80%);
}

/* End Widget Popup Buttons */
// end Widget Cards



.mainFilter-new {

    // @extend .mt-3, .mb-3;
    // margin-top: 1rem;
    // margin-bottom: 1rem;
    .gap-flt {
        gap: 7px;
    }

    @include for-desktop-big {
        .gap-flt {
            gap: 15px;
        }
    }

    .border-radius-26 {
        border-radius: 26px;
    }

    .top-new-filter {
        @extend .d-flex, .flex-wrap, .align-items-center, .gap-10;

        // .filter-bar-new {
        //     @extend .d-flex, .gap-flt;
        // }
    }

    .filter-bar-new {
        @extend .d-flex, .gap-flt;
    }
    .filter-bar-wrap {
        flex-wrap: wrap;

        @include for-desktop-min {
            flex-wrap: unset;
        }
    }

    // Form 
    .form-group .form-control::placeholder,
    .form-control::placeholder {
        @extend .s-reg, .size-xvi;
    }

    .form-control {
        @extend .mb-0;
    }

    .field-height {
        @extend .size-xx;
        height: calc(1.5em + 0.75rem + 1px);

        @include for-desktop-big {
            height: calc(1.5em + 0.75rem + 8px);
        }
    }

    .add-btn-height {
        // @extend .size-xxiii, .d-flex, .align-items-center, .justify-content-center;
        @extend .d-flex, .align-items-center, .justify-content-center;
        font-size: 1.1rem;
        width: calc(1.2em + 0.75rem + 4px);
        height: calc(1.2em + 0.75rem + 4px);

        @include for-desktop-big {
            font-size: 1.4rem;
            width: calc(1.4em + 0.75rem + 3px);
            height: calc(1.4em + 0.75rem + 3px);
        }

        // width: calc(1.5em + 0.75rem + 3px);
        // height: calc(1.5em + 0.75rem + 3px);      
        // @include for-desktop-big {
        //     width: calc(1.5em + 0.75rem + 8px);
        //     height: calc(1.5em + 0.75rem + 8px);            
        // }
    }

    // End Form 

    .global-ddown-new {
        @extend .cmenu, .s-sbold;

        .search-wrapper {
            color: var(--bluec);
            border: 1px solid var(--bluec) !important;
            border-radius: 30px !important;
            min-width: 180px;
            // height: calc(1.5em + 0.75rem + 4px);
            height: calc(1.5em + 0.70rem + 1px);
            // padding: 0.3rem 0.75rem;
            padding: 2px 2px !important;

            @include for-desktop-big {
                // height: calc(1.5em + 0.75rem + 9px);
                height: calc(1.5em + 0.75rem + 11px);
                // padding: 0.5rem 0.75rem;
                padding: 5px 2px !important;
                min-width: 215px;
            }

            .chip {
                @extend .size-xvii, .mb-0, .pr-2;
                // padding-top: 1px;
            }

            .searchBox {
                @extend .d-none;
            }
        }

        .optionListContainer {
            border: 1px solid var(--bluec) !important;
            border-radius: 15px;
            padding: 6px 1px 7px 1px;

            // padding: 6px 2px 7px;
            // min-width: 150px;
            .optionContainer {
                @extend .border-0;

                li {
                    @extend .size-xvii, .s-sbold;
                    color: var(--bluec);
                    background: var(--white);
                    padding: 0.25rem 0.75rem;

                    &:hover {
                        background: var(--greenc);
                        color: var(--white);
                    }
                }
            }
        }
    }


    // .toggle-btn-bordered {
    //     display: inline-flex;
    //     border: 1px solid var(--switch-gray) !important;
    //     padding: 0.27rem 0.75rem;
    //     border-radius: 25px;
    // }

    .filter-tdates {
        @extend .gap-flt, .d-flex, .align-items-center;
    }

    .start-end-date-sec input {
        @extend .size-xvii, .s-sbold, .pl-2, .pr-2;
        color: var(--bluec);
        border: 1px solid var(--bluec) !important;
        border-radius: 30px !important;
        max-width: 160px;
    }

    .dash-flt {
        width: 10px;
        border-top: 3px solid var(--bluec) !important;
    }

    .filter-search-field {
        // @extend .gap-flt, .d-flex, .align-items-center, .ml-lg-auto;
        @extend .gap-flt, .d-flex, .align-items-center, .ml-auto;
    }

    form.search-new-sec {
        @extend .d-flex;
        // max-width: 200px;
        max-width: 150px;

        @include for-desktop-min {
            max-width: 180px;
        }

        @include for-desktop-big {
            // max-width: 250px;
            max-width: 16rem;
            min-width: 16rem;
        }

        input[type=text] {
            // padding: 0.4rem 1rem;
            @extend .pl-3;
            font-size: 1.2rem;
            border: 1px solid var(--bluec) !important;
            // width: 84.2%;
            background: var(--white);
            border-right: none !important;
            border-radius: 0 !important;
            border-top-left-radius: 26px !important;
            border-bottom-left-radius: 26px !important;
            outline: none;
            color: var(--bluec);

            &::placeholder {
                // @extend .size-xx;
                font-size: 1rem !important;

                @include for-desktop-big {
                    font-size: 1.2rem !important;
                }
            }

            &.activeNew-search {
                border: 1px solid var(--greenc) !important;
                border-right: 0px !important;
            }
        }

        button {
            // @extend .size-xvii, .pt-2, .pb-1, .pl-1, .pr-3;
            @extend .size-xvii;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;

            @include for-desktop-big {
                width: 50px;
            }

            // padding-bottom: 0;

            // width: 14%;
            // padding: 0.4rem 1rem 0.3rem;
            // padding-top: 0.5rem;
            background: var(--white);
            color: var(--white);
            border: 1px solid var(--bluec);
            border-left: none;
            border-radius: 0;
            border-top-right-radius: 26px;
            border-bottom-right-radius: 26px;
            outline: none;

            &.crosebtn {
                @extend .size-xiv;
            }

            &:focus {
                background: unset !important;
                border-color: var(--bluec) !important;
            }
        }

        .active-new-search-icon {
            border: 1px solid var(--greenc) !important;
            border-left: 0px !important;

            &:focus {
                border-color: var(--greenc) !important;
            }
        }
    }

    .filter-btn-new {
        // @extend .d-flex, .align-items-center, .pl-3, .pr-3, .size-xviii, .s-reg;
        @extend .d-flex, .align-items-center, .pl-3, .pr-3, .size-xviii, .s-reg;
        gap: 10px;
        color: var(--gray-text);
        border-color: var(--bluec);
        // border: 1px solid var(--bluec);
        height: calc(1.5em + 0.75rem + 1px);

        @include for-desktop-big {
            height: calc(1.5em + 0.75rem + 7px);
        }

        &:focus {
            border-color: var(--greenc) !important;
            outline: unset !important;
            // box-shadow: unset !important;
        }

        &:hover,
        &:active {
            color: var(--gray-text) !important;
            background-color: var(--white) !important;
            // border-color: var(--bluec) !important;
        }

        // &.active-filter {
        //     // border: 1px solid var(--greenc) !important;
        //     box-shadow: 0 0 10px 2px var(--greenc) !important;
        //     border-radius: 100px;
        //     border: 1px solid var(--greenc) !important; 
        //     &:focus {
        //         box-shadow: 0 0 10px 2px var(--greenc) !important;
        //     }
        // }
        // .filter-icon { }
    }

    .big-filter-btn {
        // height: calc(1.5em + 0.5rem + 1px);
        height: calc(1.5em + 0.5rem + 7px);
        // @include for-desktop-big {
        //     height: calc(1.5em + 0.5rem + 7px);
        // }
    }

    .add-new-btn {
        @extend .d-flex, .align-items-center;
        border-radius: 100%;
        border-color: var(--greenc);
        background-color: var(--greenc) !important;
    }

    // active-filter
    // form.active-filter, .active-filter {
    //     // border: 1px solid var(--greenc) !important;
    //     box-shadow: 0 0 10px 2px var(--greenc) !important;
    //     border-radius: 100px;
    //     border: 1px solid var(--greenc) !important; 
    //     &:focus {
    //         box-shadow: 0 0 10px 2px var(--greenc) !important;
    //     }

    //     input[type=text] {
    //         border: 1px solid var(--greenc) !important; 
    //         border-right: none !important;
    //     }

    //     button {
    //         border: 1px solid var(--greenc);
    //         border-left: none;

    //         &:focus {
    //             outline: unset !important;
    //             border-color: var(--greenc) !important;
    //         }
    //     }
    // }
    // End active-filter
}

form.active-filter,
.active-filter {
    // border: 1px solid var(--greenc) !important;
    box-shadow: 0 0 10px 2px var(--greenc) !important;
    border-radius: 100px;
    border: 1px solid var(--greenc) !important;

    &:focus {
        box-shadow: 0 0 10px 2px var(--greenc) !important;
    }

    input[type=text] {
        border: 1px solid var(--greenc) !important;
        border-right: none !important;

        &:focus {
            border-color: var(--greenc) !important;
        }
    }


    button {
        border: 1px solid var(--greenc);
        border-left: none;

        &:focus {
            border-color: var(--greenc) !important;
        }
    }
}

.main-evidence-sms-table-sec .toggle-search-sec .search-filter-sec form.search-sec input[type=text].active-search {
    border: 1px solid var(--greenc) !important;
    border-right: 0px !important;
}




// Global Date Dropdown
.global_new_dd_date {
    color: var(--bluec);
    border: 1px solid var(--bluec) !important;
    border-radius: 30px !important;
    // min-width: 150px;

    min-width: 155px;
    max-width: 155px;

    height: calc(1.4em + 0.80rem + 0px);

    // height: calc(1.5em + 0.75rem + 3px);
    @include for-desktop-big {
        height: calc(1.5em + 0.75rem + 10px);
        // height: calc(1.5em + 0.75rem + 8px);
        min-width: 185px;
        max-width: 185px;
    }

    @include for-desktop-big-xx {
        // height: calc(1.5em + 0.75rem + 10px);
        min-width: 185px;
        max-width: 100%;
    }

    .MuiSelect-select {
        @extend .size-xvii, .s-sbold, .cmenu;
        color: var(--bluec);
    }

    .MuiSelect-icon {
        color: var(--bluec);
    }

    .MuiOutlinedInput-notchedOutline {
        border-width: 0px !important;
    }
}

#menu-year_type.MuiModal-root,
#menu-financial_institute.MuiModal-root,
#menu-financial_institute_id.MuiModal-root {
    .MuiPaper-root {
        border-radius: 15px;
        border: 1px solid var(--bluec) !important;
        padding: 6px 0px 7px 0px;

        ul {
            @extend .p-0;

            li {
                @extend .size-xvii, .s-sbold;
                color: var(--bluec);
                background: var(--white);
                padding: 0.15rem 0.75rem;

                &:hover {
                    background: var(--greenc);
                    color: var(--white);
                }
            }
        }
    }
}

// .MuiList-root, .MuiPaper-root {
//     @extend .p-0;
//     border-radius: 15px !important;
// }
// .global_dd_date_pp {
//     border-radius: 15px;
//     border: 1px solid var(--bluec) !important;
//     padding: 6px 2px 7px;
//     li {
//         @extend .size-xvii, .s-sbold;
//         color: var(--bluec);
//         background: var(--white);
//         padding: 0.25rem 0.75rem;
//         &:hover {                
//             background: var(--greenc);
//             color: var(--white);                 
//         }
//     }    
// }
// Global Date Dropdown