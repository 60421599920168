/* Ready not ready Dropdown*/
.global_new_rn {
    // float: left;
    // margin-right: .5rem !important;
}

.global_new_rn {
    .search-wrapper {
        @extend .d-flex, .align-items-center, .cmenu;
        background: var(--white) !important;
        border: 2px solid var(--greenc) !important;
        border-radius: 25px !important;
        // height: calc(1.5em + 0.75rem + 4px);
        height: calc(1.5em + 0.75rem + 1px);
        // padding: 6px !important;
        // height: 47px !important;
        width: 160px !important;
    }

    .searchBox {
        @extend .d-none;
    }

    .chip {
        @extend .s-sbold, .size-xviii, .mb-0;
        color: var(--greenc) !important;
    }

    .icon_cancel:before {
        color: var(--greenc) !important;
    }

    .optionListContainer {
        border-radius: 15px;
    }

    .optionContainer {
        @extend .pt-1, .pb-1;
        border: 2px solid var(--greenc) !important;
        border-radius: 15px;

        li {
            @extend .s-sbold, .size-xviii;
            color: var(--greenc);
            background: var(--white) !important;
            padding: 1px 13px !important;

            &:hover {
                background: var(--greenc) !important;
            }
        }
    }
}

/* End Ready not ready Dropdown*/


/* Accounting Pending Dropdown*/
.global_new_rn_dd {
    .MuiOutlinedInput-root {
        @extend .s-sbold, .d-flex, .align-items-center, .cmenu;
        font-size: 16px !important;
        background: var(--white) !important;
        border: 2px solid var(--greenc) !important;
        border-radius: 25px !important;
        color: var(--greenc) !important;

        height: calc(1.35em + 0.75rem + 1px);
        min-width: 155px !important;

        @include for-desktop-big {
            height: calc(1.55em + 0.75rem + 1px);
        }

        // background: #fff !important;
        // border: 2px solid #77d7b1 !important;
        // border-radius: 25px !important;
        // padding: 4px !important;
        // height: 47px;
        // min-width: 150px !important;
        // font-size: 17px !important;
        // color: #77d7b1 !important;
        // font-family: s-bold !important;
        // padding: 0 10px;
        // margin-right: 0.5rem;
    }

    .MuiOutlinedInput-notchedOutline {
        @extend .border-0;
    }

    .MuiOutlinedInput-root.Mui-disabled {
        color: var(--greenc) !important;
        -webkit-text-fill-color: var(--greenc) !important;
        border: 2px solid var(--greenc) !important;
        cursor: not-allowed !important;
        opacity: 0.5 !important;
    }

    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
        color: var(--greenc) !important;
        -webkit-text-fill-color: var(--greenc) !important;
    }

    .MuiOutlinedInput-root .Mui-disabled {
        cursor: not-allowed !important;
    }

    svg.MuiSvgIcon-root {
        color: var(--greenc) !important;
    }
    .global_new_dd_selected {
        // color: var(--bluec) !important;
        color: var(--dark-greenc) !important;
        background-color: var(--greenc-light) !important;
    }
}


#menu-entry_status,
#menu-accounting_status, #menu-account_status {
    div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
        border: 2px solid var(--greenc) !important;
        border-radius: 15px;
        min-width: 155px !important;
    }

    ul.MuiList-root.MuiList-padding.MuiMenu-list li {
        @extend .s-sbold;
        padding: 1px 10px !important;
        font-size: 16px !important;
        color: var(--greenc);
        background-color: var(--white) !important;
    }

    ul.MuiList-root.MuiList-padding.MuiMenu-list li.Mui-disabled {
        color: var(--gray-dark-text) !important;
    }

    ul.MuiList-root.MuiList-padding.MuiMenu-list li:hover {
        color: var(--white);
        background-color: var(--greenc) !important;
    }

    ul li.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected {
        background-color: var(--white) !important;
    }

    ul.MuiList-root.MuiList-padding.MuiMenu-list li.Mui-disabled {
        // color: #3a3a3a !important;
    }
}

/* End Accounting Pending Dropdown*/

// Select Type Dropdown 
.typeed-dd {
    border: 0 !important;
    border-bottom: 1px solid #ced4da !important;

    .type-select {
        @extend .size-xvi, .s-sbold;
        color: var(--bluec);
        border: 0 !important;
        min-height: unset;
        box-shadow: unset !important;

        .__indicators {
            min-height: unset !important;
            max-height: unset !important;
        }

        .__dropdown-indicator {
            padding: 0px 3px;
        }

        @include for-desktop-big-125 {
            .__dropdown-indicator {
                padding: 4px;
            }
        }


        .__value-container {
            padding-left: 3px !important;
        }

        .__indicator-separator {
            width: 0px;
        }

        &.__menu {
            border: 1px solid var(--form-border) !important;
        }

        // .__menu {
        //     // max-height: 100px !important;
        //     position: absolute !important;
        // }

        // .__option {
        //     background-color: var(--white);            
        // }


    }
}

.Select-Search.typeed-dd .Select.type-select.__menu .__menu-list {
    max-height: 185px !important;
    overflow-y: auto;    
    // margin-bottom: 15px;
    // position: absolute !important;
    // z-index: 1000;
}

.error-border.typeed-dd {
    border-bottom: 1px solid var(--redc) !important;
}

.type-select.__control--is-disabled {
    background-color: var(--disable) !important;
    border-radius: 0;
}

.select-minWidth{
    min-width: 150px;
    @include for-desktop-big {
        min-width: 200px !important;
            }
}
// End Select Type Dropdown 

// .SelectLedger.__menu * {
//     font-size: 0.8rem !important;

//     @include for-desktop-big {
//         font-size: 1rem !important;
//     }
// }

.Select-Search {
    border-bottom: 1px solid var(--form-border) !important;

    .css-1pahdxg-control {
        box-shadow: unset !important;
    }

    &:focus {
        border-bottom: 1px solid var(--greenc) !important;
    }

    .SelectLedger {
        @extend .s-sbold, .blue-text, .border-0;
        font-size: 1rem;
        border-radius: 0 !important;
        min-height: auto !important;

        &.__menu {
            option {
                background: #000 !important;
                background-color: #000 !important;
            }
        }

        .__value-container {
            padding-left: 0 !important;
        }

        // &.__indicator {
        //     margin-top: 5px;
        // }
    }

    // .__indicator {
    //     @extend .mt-xxl-2;
    // }
    .__indicator-separator {
        width: 0px;
    }

    // Checkbox{        
    // }   
}

.SelectLedger.__indicator.__dropdown-indicator {
    padding: 4px;

    @include for-desktop-big-125 {
        padding: 4px;
    }

    @include for-desktop-big {
        padding: 8px;
    }
}


// @include for-desktop-big-125 {
// @include for-desktop-big-15 {
//     .SelectLedger.__indicator.__dropdown-indicator {
//         padding: 8px;
//     }
// }

// .react-select__option {
//     background: #fff !important;
//     background-color: #fff !important;
// }
// .react-select__option .current {
//     background: #000 !important;
//     background-color: #000 !important;
// }
.Select.ledger_sc__control,
.Select.ledger_cc__control {
    @extend .border-0;
    border-radius: 0;

    svg {
        color: var(--bluec);
    }
}

// .Select-Search {
//     .__single-value{
//         @extend .size-xvi;
//     } 
// }
.SelectLedger.__single-value,
.ledger_sc__single-value {
    @extend .size-xvi;
    line-height: 2;
}

.SelectLedger.__control,
.Select.ledger_sc__control,
.Select.ledger_cc__control {
    padding: 0px 0px !important;
    line-height: 1;
    min-height: auto !important;
}

.Select.ledger_sc__control {
    background: var(--orange-light);
}

.SelectLedger.__menu {
    display: block !important;

    option {
        font-size: 10px !important;
    }
}


.type-select.__menu-portal,
.SelectLedger.__menu,
.type-select.__menu {
    * {
        @extend .s-sbold;
        font-size: 0.8rem !important;
        color: var(--bluec);
        padding: 5px !important;
        line-height: 1.2 !important;
    }

    @include for-desktop-big {
        * {
            font-size: 1rem !important;
        }
    }
}