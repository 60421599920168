/* intro user guid */
@import "intro.js/introjs.css";

// @import "./../../../../node_modules/intro.js/introjs.css";
.introuser,
.introuser .helpbtn {
    display: flex;
    align-items: center;
}
.introjs-tooltip {
    max-width: 425px !important;
    min-width: 425px !important;
}
.introjs-tooltip-header {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
    position: absolute !important;
    right: -10px !important;
    top: -10px !important;
}
.introjs-button {
    /* border: 1px solid #77D7B1 !important; */
    text-shadow: 1px 1px 0 #fff !important;
    color: #212529 !important;
    /* color: #fff !important; */
    /* background-color: #77D7B1 !important; */
    border-radius: 25px !important;
    text-shadow: 0px 0px 0 #fff !important;
    font-family: s-sbold !important;
    font-size: 16px !important;
    padding: 0px 9px !important;
}
.introjs-button:hover {
    background-color: #fff !important;
}
.introjs-button:focus {
    // box-shadow: 0 0 0 0.2rem rgb(158 158 158 / 0%) !important;
    border: 1px solid #9e9e9e !important;
    box-shadow: unset !important;
}
.introjs-tooltipbuttons {
    border-top: 0px solid #e0e0e0 !important;
    padding: 12px;
}
.introjs-tooltiptext {
    padding: 20px 12px 0 !important;
    color: #fff;
}
.introjs-tooltip {
    border-radius: 6px;
    background-color: #686868 !important;
    box-shadow: 0 0 8px #0000005c;
}
.introjs-skipbutton {
    color: #ffffff !important;
    background: #000 !important;
    border-radius: 100% !important;
    // padding: 0px 10px !important;
    // width: unset !important;
    // height: unset !important;
    
    font-size: 1.3rem !important;
    width: 1.7rem !important;
    height: 1.7rem !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 0rem 0 0 !important;
    // line-height: 27px !important;
    line-height: normal !important;
    // @include for-desktop {
    //     line-height: 29px !important;
    // }
    @include for-desktop-big-125 {
        padding: 1px 0 0 !important;
    }
}

// .introjs-skipbutton {
//     color: #fff !important;
//     background: #000 !important;
//     width: 25px !important;
//     height: 25px !important;
//     line-height: normal !important;
//     border-radius: 100% !important;
//     display: flex !important;
//     align-items: center !important;
//     justify-content: center !important;
//     font-size: 20px !important;
// }


// .introjs-skipbutton {
//     color: #fff !important;
//     background-color: #000 !important;
//     width: 25px !important;
//     height: 25px !important;
//     line-height: normal !important;
//     border-radius: 100% !important;
//     display: flex !important;
//     align-items: center !important;
//     justify-content: center !important;
//     font-size: 20px !important;
// }

// .introjs-skipbutton {
//     color: #fff !important;
//     background-color: #000 !important;
//     width: 25px !important;
//     height: 25px !important;
//     line-height: normal !important;    
//     border-radius: 100% !important;
//     display: flex !important;
//     align-items: center !important;
//     justify-content: center !important;
// }



// @media screen and (min-device-width: 1500px) and (max-device-width: 1550px)  {
//     .introjs-skipbutton {
//         padding: 3px 0 0 !important;
//     }
// }
// @include for-desktop-big-125 {
//     .introjs-skipbutton {
//         padding: 3px 0 0 !important;
//     }
// }

.introjs-helperLayer {
    border-radius: 6px;
    // box-shadow: rgb(124 124 124 / 80%) 0px 0px 1px 2px, rgb(92 92 92 / 65%) 0px 0px 0px 5000px !important;
    //   box-shadow: var(--border-shadow) !important;
    box-shadow: rgba(33, 33, 33, 0.1) 0px 0px 1px 2px, rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;
}
.introjs-overlay {
    opacity: 1 !important;
    box-shadow: 0 0 10px #000 !important;
}
.introjs-helperLayer {
    background-color: white !important;
  }

.introjs-bullets {
    /* display: none !important;  */
}


.introjs-arrow.top,
.introjs-arrow.bottom {
    left: 18px !important;
}
.introjs-arrow.top-right {
    right: 35px !important;
}
.introjs-arrow.top,
.introjs-arrow.top-middle,
.introjs-arrow.top-right {
    border-bottom-color: #686868 !important;
}
.introjs-arrow.right {
    top: 35px !important;
}
.introjs-arrow.right,
.introjs-arrow.right-bottom {
    border-left-color: #686868 !important;
}
.introjs-arrow.bottom,
.introjs-arrow.bottom-middle,
.introjs-arrow.bottom-right {
    border-top-color: #686868 !important;
}
.introjs-arrow.left,
.introjs-arrow.left-bottom {
    border-right-color: #686868 !important;
}

/* .introjs-button {  
  border: 1px solid #77D7B1 !important;
  text-shadow: 1px 1px 0 #fff !important;
  color: #424242 !important;
  background-color: #77D7B1 !important;
  border-radius: 25px !important;
  color: #fff !important;
  text-shadow: 0px 0px 0 #fff !important;
  font-family: s-bold !important;
  font-size: 18px !important;
  padding: 6px 15px !important;
} */
/* .introjs-prevbutton {
  background-color: #77D7B1 !important;
  border-color: #77d7b1 !important;
  border: 2px solid !important;
  color: #77d7b1 !important;
} */
/* .introjs-nextbutton {
  border: 1px solid #77D7B1 !important;
  background-color: #77D7B1 !important;
} */
/* .introjs-helperLayer {
  border-radius: 6px;
  box-shadow: rgb(149 149 149 / 80%) 0px 0px 1px 2px, rgb(33 33 33 / 50%) 0px 0px 0px 5000px !important;
} */
// .introjs-arrow.top {
//     border-bottom-color: #686868 !important;
//   }
//   .introjs-arrow.right {
//     top: 35px !important;
//     border-left-color: #686868 !important;
//   }
//   .introjs-arrow.bottom {
//     border-top-color: #686868 !important;
//   }
//   .introjs-arrow.top-middle {
//     border-bottom-color: #686868 !important;
//   }
//   .introjs-arrow.bottom-middle {
//     border-top-color: #686868 !important;
//   }
//   .introjs-arrow.bottom-right {
//     border-top-color: #686868 !important;
//   }
//   .introjs-arrow.top-right {
//     border-bottom-color: #686868 !important;
//   }
//   .introjs-arrow.left {
//     border-right-color: #686868 !important;
//   }
//   .introjs-arrow.right-bottom {
//     border-left-color: #686868 !important;
//   }
//   .introjs-arrow.left-bottom {
//     border-right-color: #686868 !important;
//   }

/* End intro user guid */