.inventory-scrolling {
    // overflow-y: auto;
    // overflow-x: hidden;
    // max-height: 500px;
    // padding-right: 5px;
}

.inventory_popup {
    @extend .position-fixed, .w-100, .h-100;
    top: 0;
    left: 0;
    // z-index: 10000000;

    .inve_pdf {
        @extend .text-center, .d-flex, .align-items-center, .justify-content-center;
        text-align: center;
        min-height: 60vh;
        width: 100%;

        .iframe_pdf {
            text-align: center;
            width: 90%;
            height: 60vh;
        }
    }

    .inventory_form_sec {
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
        overflow-y: auto;
        // height: 50vh;        
        // min-height: 50%;
        // background: var(--white);
    }

    .resizable-tag {
        // width: 100%;
        // height: 50%;
        min-height: 40vh;
        max-height: 75vh;
        // padding: 0.5rem 0rem 1.5rem 1rem;
        // border-top: 20px solid #000;
    }

    .resizable-icon {
        width: 1.8rem;
        height: 1.8rem;

        @include for-desktop-big {
            width: 2.2rem;
            height: 2.2rem;
        }

        margin-top: -15px;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);

        background: var(--greenc);
        border-radius: 100%;

        // margin-top: -15px;
        // position: fixed;
        // left: 50%;
        // transform: translate(-50%, 0);

        // z-index: 0;
        // display: block;
    }

    .inv-mp {
        // background: var(--white);
        // padding: 0.5rem 0rem 1.5rem 1rem;
        margin-top: 20px;

    }

    .inv-white-bg {
        // padding: 0px 15px;
        background: var(--white);
    }

    .inv-scroll {
        padding: 0px 15px;
        background: var(--white);
    }

    .inv-frm-bar {
        // @extend .blue-text, .d-flex, .align-items-center, .flex-wrap, .pt-3, .mb-3;
        @extend .blue-text, .d-flex, .align-items-center, .flex-wrap, .pt-3;
        margin-bottom: 0.8rem;
        padding-bottom: 0.7rem;
        border-bottom: 1px solid var(--bluec);

        // position: sticky;
        // top: 0;
        // background: var(--white);
        // // z-index: 1;
    }

    .ledger-bar-sync-text {
        @extend .text-right,
    }

    .inv-frm-bar-icon {
        display: flex;
        @extend .align-items-center;
    }

    .inventory-filter {
        margin-bottom: 0.8rem;
        // padding: 0.7rem 1.2rem 0.7rem 0.7rem;
        padding: 0.7rem;
        border: 1px solid var(--border-gray);
        border-radius: 11px;
        min-height: 102px;

        .form-group {
            // @extend .mt-2, .mb-2;
            margin-bottom: 0.3rem;
        }
    }

    .inv_tds {
        // @extend .d-inline-flex, .gap-40;
    }

    .main-invoice-item-new .invent-table {
        // min-width: 1290px;
        // min-width: 1250px;
        margin-bottom: 1.5rem;
    }

    .react-datepicker-popper[data-placement^="top"] {
        top: 6rem !important;
    }
}

.pointer-events-none {
    pointer-events: none;
}

.fieldp-icon {
    @extend .d-flex, .cpointer;

    .fieldp-icon-bg {
        background: var(--greenc);
        width: 25px;
        height: 25px;
        margin-top: 6px;
        
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        font-size: 0.8rem;

        padding-top: 2px;

        @include for-desktop-big-125 {
            padding-top: 1px;
        }

        // @include for-desktop-big-xx {
        //     padding-top: 2px;
        // }
    }
}

// .inventory-table {
//     display: block;
//     overflow-x: unset;
//     overflow-y: auto;
// }


.ledger-table .ledger-second-table {
    // padding: 3rem 0 !important;
    // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}



// Resizable Test

// .resizable-sec {
//     /* display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     align-items: center; */
//     /* padding: 0 10px; */
//     height: 100vh;
//     /* max-width: 95%; */
// }

// .resizable-pdf {
//     width: 100%;
//     text-align: center;
// }
// .iframe_pdf {
//     text-align: center;
//     width: 90%;  
//     height: 60vh;
// }

// .resizable-div {
//     padding: 0 10px;
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     width: 100%;
// }
// .resizable-tag {  
//     border: solid 1px #ddd;
//     background: #f0f0f0;
//     min-height: 40vh;
//     max-height: 90vh;
//     max-width: 100%;
//     /* position: absolute !important;
//     bottom: 0;
//     left: 0; */
// }

// <div className="resizable-sec">
//       <div className="resizable-pdf">
//         <iframe src="https://media.geeksforgeeks.org/wp-content/cdn-uploads/20210101201653/PDF.pdf" className="iframe_pdf" />
//       </div>

//       <div className="resizable-div">
//         {/* <img src={iii} /> */}
//         <Resizable className="resizable-tag" defaultSize={{ width: '100%', height: '50vh' }}>
//           Sample with default size
//         </Resizable>
//       </div>
//     </div> 

// End Resizable Test
.inve-zoom-img-sec {
    width: 100%;

    .inve-zoom-img-div {
        text-align: center;
        height: 59vh;
        // overflow: auto;
    }

    .tools-btn {
        background: var(--white);
        border-radius: 8px;
        display: inline-block;
        margin-bottom: 3px;
        button {
            border: 0 !important;
            padding: 0.3rem 0.8rem 0.1rem;

            @include for-desktop {
                padding: 0.5rem 1rem 0.2rem;
            }

            // &:focus {
            //     outline: unset !important;
            // }
            &:focus {
                outline: unset !important;
                border: 0 !important;                
                // color: var(--greenc);
            }
        }
    }

    .react-transform-wrapper, .react-transform-component {
        width: 100%;
        height: 54vh;
        @include for-desktop {
            height: 55vh;
        }
        cursor: grab;
	    // cursor: zoom-in;
        // cursor: all-scroll;
    }
    .react-transform-component {
        // width: 100%;
        //  height: 55vh;
        @extend .justify-content-center;
    }


    // .inve-zoom-btn {
    //     position: relative;
    //     position: fixed;
    //     top: 10px;
    //     // margin-top: -4%;       
    //     width: 100%;
    //     text-align: center;
    //     // margin-top: -50px;

    //     .inve-span-btn {
    //         display: inline-block;
    //         background: var(--greenc);
    //         border-radius: 20px;
    //         // margin-bottom: 20px;
    //         margin-left: -10px;

    //         button {
    //             @extend .size-xx, .cpointer;
    //             border: 0;
    //             padding: 0.4rem 1.2rem 0.1rem;

    //             @include for-desktop-big-x {
    //                 padding: 0.5rem 1.2rem 0.3rem;
    //             }

    //             background: transparent;

    //             &:focus {
    //                 outline: unset !important;
    //             }
    //         }
    //     }
    // }


}

// .inve-zoom-img-sec {
//     width: 100%;

//     .inve-zoom-img-div {
//         text-align: center;
//         height: 58.5vh;
//         overflow: auto;
//         // margin: 0.1rem 0rem 3rem;
//         // overflow-y: auto;
//         // overflow-x: hidden;
//         // position: relative;
//         // height: 100%;
//         // margin-bottom: 10px;
//     }
//     .inve-zoom-img {
//         transform-origin: top center;
//         // max-width: 100%;
//         // height: auto;
//     }
//     .inve-zoom-btn {
//         position: relative;
//         position: fixed;
//         top: 10px;
//         // margin-top: -4%;       
//         width: 100%;
//         text-align: center;
//         // margin-top: -50px;

//         .inve-span-btn {
//             display: inline-block;
//             background: var(--greenc);
//             border-radius: 20px;
//             // margin-bottom: 20px;
//             margin-left: -10px;

//             button {
//                 @extend .size-xx, .cpointer;
//                 border: 0;
//                 padding: 0.4rem 1.2rem 0.1rem;

//                 @include for-desktop-big-x {
//                     padding: 0.5rem 1.2rem 0.3rem;
//                 }

//                 background: transparent;

//                 &:focus {
//                     outline: unset !important;
//                 }
//             }
//         }
//     }
// }