.login-wrapper {
    // @extend .h-100;  
}

.login-container {
    @extend .p-0;
    // @extend .d-flex, .w-100, .p-0;
    // height: 100vh;

    .form-group {
        margin-bottom: 0.7rem !important;
    }

    @include for-desktop-15 {
        .form-group {
            margin-bottom: 1rem !important;
        }
    }

    .login-title {
        @extend .mb-3;
    }

    .login-logo {
        @extend .d-flex, .align-items-center, .justify-content-center, .p-3;
        background: var(--greenc);
        // position: fixed;
        // width: 50%;
        height: 100%;
        min-height: 100vh;

        .onboarding-logo {
            max-height: 277px;
            padding: 25px;
        }
    }

    .three-sec-form {
        @extend .d-flex, .align-items-center, .justify-content-center, .p-3;
       
        height: 100%;
        min-height: 100vh;

        .logo_qualified {
            max-height: 130px;
            // padding: 25px;

            @include for-desktop-big-x {
                max-height: 150px;
            }
        }
    }

    .onboarding-form {
        @extend .d-flex, .align-items-center, .justify-content-center, .px-3, .py-1;
        height: 100%;
        // min-height: 100vh;

        .onboarding-form-width {
            max-width: 500px;
            width: 100%;
        }

        // width: 50%;
        // margin-left: 50%;
        // .onboarding-form-width {
        //     width: 80%;
        //     @include for-desktop {
        //         width: 55%;
        //     }
        // }
    }
}


.login-p-text {
    @extend .mt-2;

    p {
        @extend .mb-1;
    }

    a:hover {
        color: #0056b3 !important;
        // text-decoration: underline !important;
    }
}

.login-spinner-border {
    position: absolute;
    left: 0;
    top: 8px;
    margin: 0 0 0 50px;
    z-index: 1000;
}


// Business / Accounting Firm Button 
.ba-ca-btn {
    @extend .d-flex, .align-items-center, .gap-15;

    .baca_btn {
        @extend .s-reg, .size-xviii, .d-flex, .align-items-center, .p-0;

        .baca_btn_icon {
            // @extend .size-xxv, .d-inline-block;
            @extend .size-xxv, .d-flex, .align-items-center, .justify-content-center;
            background: #c4c4c4 !important;
            width: 50px;
            height: 35px;
            padding: 0.1rem 0;
            // background: var(--greenc ) !important;
            // padding-right: 3px;
        }

        .baca_btn_text {
            @extend .d-inline-block;
            padding: 0.2rem 0.75rem;
        }

        border: 1px solid;
        border-color: #c4c4c4;
        color: var(--grayc-light);
        background: var(--grayc) !important;

        &:hover,
        &:focus {
            color: var(--grayc-light) !important;
            background: var(--grayc) !important;
        }

        &.active {
            border: 1px solid;
            border-color: var(--greenc) !important;
            color: var(--greenc) !important;
            background: var(--grayc) !important;

            &:hover,
            &:focus {
                color: var(--greenc) !important;
                background: var(--grayc) !important;
            }

            .baca_btn_icon {
                background: var(--greenc) !important;
            }
        }
    }
}

// End Business / Accounting Firm Button 


// Mobile Number
.reg_mob_no {
    @extend .d-flex, .align-items-baseline;

    .flag-toggle {
        @extend .d-flex, .justify-content-center, .position-relative;
        align-self: flex-start;
        background: var(--white);
    }

    .flag-dropdown {
        @extend .cpointer;
        width: 75px;
    }

    img {
        width: 22px;
    }

    .dropdown-menu {
        max-height: 150px;
        overflow-y: auto;

        .dropdown-item {
            @extend .d-flex, .align-items-center, .gap-5;
            cursor: context-menu;
            padding: 0.25rem 0.5rem;

            &:hover {
                background-color: var(--greenc) !important;
            }
        }

        :not(:last-child) {
            @extend .mb-1;
        }
    }
}

// End Mobile Number

// OTP 
.otp-reg {
    @extend .d-flex, .justify-content-between;
    gap: 20px;

    max-width: 300px;
    width: 100%;

    .otprinput {
        @extend .text-center;
    }
}

.resend-otp-reg {
    @extend .d-flex, .justify-content-between, .gap-10, .mt-2;

    .resend-otp-text {
        @extend .s-sbold, .green-text;
        cursor: pointer;

    }

    .resend-otp-disabled {
        cursor: not-allowed !important;
    }
}

// End - OTP 

// Complet
.complet-form {
    @extend .text-center, .mt-4;

    .done-all-icon {
        @extend .size-cxx, .d-block, .mb-3;
        // font-size: 8rem;
    }
}

// End - Complet



// .or-text {
//     width: 100%;
//     text-align: center;
//     border-bottom: 1px solid #000;
//     line-height: 0.1em;
//     margin: 10px 0 20px;
// }

// .or-text span {
//     background: #fff;
//     padding: 0 10px;
// }


.or-text {
    @extend .d-flex, .align-items-center, .justify-content-center, .mb-1;

    .or-hr-line {
        width: 100%;
        border-bottom: 1px solid var(--shi-border);
    }
    .or-hr-text {
        @extend .p-2;
    } 
}