
.td-green-bg {
    @extend .green-bg, .white-text;
}

.no-item-list {
    @extend .s-sbold, .text-center, .mb-0;
    color: var(--bluec);
    padding: 2rem;
}

.checkbox-personalized {
    input[type=checkbox] {
        display: none;
    }

    input[type=checkbox]:checked+label .check {
        background: var(--greenc);
        transition: all .2s ease-in;
        border: 1px solid var(--greenc);
    }

    label {
        @extend .mt-1, .mb-0;

        .check {
            // margin-top: 0.6rem;
            // margin-left: 0.7rem; 
            margin-left: 0rem;
            min-width: 18px !important;
            min-height: 18px !important;
            border: 1px solid var(--checkbox-gray);
            position: relative;
            overflow: hidden;
            cursor: pointer;
            border-radius: 5px !important;

            @include for-desktop {
                // margin-left: 0.15rem;
                min-width: 17px !important;
                min-height: 17px !important;
            }
            @include for-desktop-big {
                min-width: 18px !important;
                min-height: 19px !important;
            }
            @include for-desktop-big-x {
                min-width: 20px !important;
                min-height: 20px !important;
            }
        }
    }

    input[type=checkbox]:indeterminate+label .check:after {
        content: "";
        display: block;
        // width: 7px;
        // height: 14px;
        // margin: 1px 6px;
        border: solid var(--white);
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);

        width: 6px;
        height: 12px;
        margin: 1px 5px;
        @include for-desktop {
            width: 5px;
            height: 11px;
            margin: 1px 5px;
        }
        @include for-desktop-big {
            width: 6px;
            height: 13px;
            margin: 1px 5px;
        }
        @include for-desktop-big-x {
            width: 7px;
            height: 14px;
            margin: 1px 5px;
        }
    }

    input[type=checkbox]:checked+label .check:after {
        content: "";
        display: block;        
        border: solid var(--white);
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);

        width: 6px;
        height: 12px;
        margin: 1px 5px;
        // @include for-desktop {
        //     width: 7px;
        //     height: 14px;
        //     margin: 1px 6px;
        // }
        @include for-desktop {
            width: 5px;
            height: 11px;
            margin: 1px 5px;
        }
        @include for-desktop-big {
            width: 6px;
            height: 13px;
            margin: 1px 5px;
        }
        @include for-desktop-big-x {
            width: 7px;
            height: 14px;
            margin: 1px 5px;
        }
    }
}


// Listing Table 
.main-table-listing {
    // @extend .mt-3;
    // margin-top: 1rem;

    .table-sec {
        // @extend .mt-3, .mb-3, .pl-3, .pr-3;
        // @extend .mb-3, .pl-3, .pr-3, .pb-2;
        @extend .pl-3, .pr-3, .pb-2, .mb-0;
        margin-bottom: 1rem;
        background: var(--table-bg);
        border-radius: 20px;

        tr {
            cursor: pointer;
        }

        &.pointer-none tr {
            cursor: unset !important;
        }

        .td-green-btn {
            @extend .d-inline-block, .s-sbold, .cpointer, .border-0;
            color: var(--white);
            background: var(--greenc);
            // padding: 4px 12px;
            padding: 0.2rem 0.8rem;
            border-radius: 25px;
            // min-width: 150px;
            // &:focus {
            //     border-color: var(--btn-focus) !important;
            //     background: var(--greenc) !important;
            // }        
            // &:hover {
            //     border-color: var(--greenc) !important;
            //     background: var(--greenc) !important;
            // }
        }

        // .table-scroll {
        //     max-width: 1155px;
        //     overflow-x: auto;
        // }

        .react-bs-table-container,
        .bs_table_container {
            .react-bs-table-bordered {
                @extend .border-0;
                border-radius: 5px;
            }

            table {
                min-width: 1130px;
                overflow-x: auto;
                margin-bottom: 1.5rem;
            }

            // Table Header 
            .react-bs-container-header table,
            .bs_table {
                border-collapse: separate;
                border-spacing: 0 0px;
                // @extend .mt-3, .mb-0;
                @extend .mb-0;
                margin-top: 1rem;

                thead {
                    tr {
                        cursor: unset !important;
                    }
                    th {
                        @extend .size-xvi, .s-reg, .border-0, .pt-1, .pb-1;
                        // font-size: 17px;
                        // font-family: s-reg;
                        font-weight: 400;
                        color: var(--bluec);
                    }
                }
            }

            // Table Body 
            .react-bs-table table td {
                overflow: hidden;
                white-space: nowrap !important;
                text-overflow: ellipsis !important;
            }
            
            .react-bs-container-body table,
            .bs_table {
                @extend .border-0;
                outline: 0 !important;
                border-collapse: separate;
                border-spacing: 0 15px;

                margin-bottom: 0;


                tbody {
                    tr {
                        background: var(--white);
                        // cursor: pointer;

                        td {
                            @extend .size-xvi, .s-reg, .border-0, .align-middle, .pt-1, .pb-1;
                            color: var(--gray-text);

                            &:first-child {
                                border-top-left-radius: 8px;
                                border-bottom-left-radius: 8px;
                            }

                            &:last-child {
                                border-top-right-radius: 8px;
                                border-bottom-right-radius: 8px;
                            }
                        }

                    }
                }
            }

            .bs_table tbody tr {
                cursor: unset;
            }

            .react-bs-table-pagination {
                @extend .mt-0;

                .row {
                    @extend .mt-0;
                }

                .page-item {
                    .page-link {
                        @extend .size-xx, .s-reg, .border-0, .p-2;
                        color: var(--gray-text);
                        background-color: transparent;

                        &:focus {
                            box-shadow: unset !important;
                        }
                    }

                    &.active .page-link {
                        @extend .s-sbold;
                        color: var(--greenc) !important;
                        text-decoration: underline !important;
                    }
                }
            }

            // .icon-dt-td {
            //     @extend .size-xxxvi;
            // }
        }
    }

    .icon-dt-td {
        @extend .size-xxxvi;
        // display: inline-flex;
        // margin-top: 2px;
    }

    &.listing-icons {
        .icon-dt-td {
            @extend .size-xxx, .mr-2;
            display: inline-flex;
            margin-top: 2px;
        }
    }
}
// End Listing Table








// .table {
//     width: 100%;
//     margin-bottom: 1rem;
//     color: #212529;
// }
// End Listing Table 

.content-wrapper-inner-new {
    .table-reference {
        tr {
            background: var(--table-bg);
        }

        td {
            @extend .size-xvi, .s-reg, .border-0, .align-middle, .pt-1, .pb-1;
            border-top: 0px !important;
            color: var(--gray-text);
            white-space: nowrap;

            &:first-child {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
            }

            &:last-child {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }

            .icon-dt-td {
                @extend .size-xxv;
            }
        }

    }
}

// Border Table
.borderTable {
    thead {
        background: var(--grayc) !important;
    }
    tbody {
        background: var(--white) !important;
    }

    th,
    td {
        // @extend .size-xvii, .s-reg, .align-middle, .pt-1, .pb-1, .pl-2, .pr-2;
        @extend .size-xvi, .s-reg;
        // .align-middle;
        vertical-align: top !important;
        padding: 0.30rem;
        color: var(--gray-text);
        border: 1px solid var(--border-gray);
    }

    th {
        // @extend .s-sbold, .blue-text;
        border-bottom: 1px solid var(--border-gray) !important;
        @extend .s-reg, .blue-text;
        font-weight: unset;
    }

    tr:hover {
        // background: unset !important;
    }

    .td-date-120 {
        min-width: 90px;
        max-width: 90px;
        width: 90px;
        @include for-desktop-15 {
            min-width: 110px;
            max-width: 110px;
            width: 110px;
        }
    }
}
// End Border Table


table {
    width: 100%;

    th,
    td {
        @extend .size-xvi, .s-reg;
    }

    th,
    td {
        &.td-width-10 {
            min-width: 10px !important;
            max-width: 10px !important;
            width: 10px !important;
        }

        &.td-width-20 {
            min-width: 20px !important;
            max-width: 20px !important;
            width: 20px !important;
        }

        &.td-width-30 {
            min-width: 30px !important;
            max-width: 30px !important;
            width: 30px !important;
        }

        &.td-width-40 {
            min-width: 40px !important;
            max-width: 40px !important;
            width: 40px !important;
        }

        &.td-width-50 {
            min-width: 50px !important;
            max-width: 50px !important;
            width: 50px !important;
        }

        &.td-width-100 {
            min-width: 100px !important;
            max-width: 100px !important;
            width: 100px !important;
        }
        &.td-width-110 {
            min-width: 110px !important;
            max-width: 110px !important;
            width: 110px !important;
        }
        &.td-width-120 {
            min-width: 120px !important;
            max-width: 120px !important;
            width: 120px !important;
        }
    }
}

.td-view-all {
    @extend .blue-text, .s-sbold;
    text-decoration: underline !important;
}



// Money Link transection Table 
.ml-tran-table-sticky {
    position: sticky;
    position: -webkit-sticky;
    z-index: 100;
    top: 105px;

    @include for-desktop-min {
        top: 105px;
    }
    // @include for-desktop {
    //     top: 17%;
    // }
    @include for-desktop-big-125 {
        top: 14.5%;
    }
    @include for-desktop-big {
        top: 17%;
    }
    @include for-desktop-big-x {
        // top: 14%;
        top: 133px;
    }
}

// @media only screen and (min-device-width : 1520px) and (max-device-width : 1550px) {
//     .ml-tran-table-sticky {
//         top: 15% !important;
//     }
// }

// @media only screen and (min-device-width : 1400px) and (max-device-width : 1500px) {
//     .ml-tran-table-sticky {
//         top: 14.5% !important;
//     }
// }

.money-link-tran-table {

    th,
    td {
        vertical-align: top;
    }

    .trans-table {
        min-width: 800px;
        @extend .w-100;

        .lable-input-trans {
            font-size: 14px !important;
            color: var(--gray-text);

            @include for-desktop-big {
                font-size: 15px !important;
            }
        }

        .form-control-trans {
            @extend .size-xvii, .s-sbold, .blue-text;
            // font-size: 1rem !important;
            color: var(--bluec);
        }

        .icon-dt-td.iconsc {
            // @extend .size-xxvii;
            font-size: 1.3rem;

            @include for-desktop-big {
                font-size: 1.5rem;
            }
        }
    }
}

// Money Link transection Table





// Table Order Summary 
.table-order {
    border-collapse: separate;
    border-spacing: 0 15px;
    @extend .mb-0;
    margin-top: -10px;
    
    // min-width: 1155px;
    // overflow-x: auto;
    // margin-bottom: 1.5rem;

    th,
    td {
        @extend .size-xvi;
        font-weight: 400;
        padding: 0.25rem 0.8rem;
        border-color: #cacaca !important;
    }

    thead {
        tr {
            background: var(--grayc);

            th {
                @extend .s-reg, .blue-text;
                // color: var(--bluec);
                
                // border-top: 1px solid;
                border-bottom: 1px solid;

                &:first-child {
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                    border-left: 1px solid;
                }

                &:last-child {
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                    border-right: 1px solid;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                // @extend .gray-text;
                color: var(--gray-text);

                // border-top: 1px solid;
                border-bottom: 1px solid;

                &:first-child {
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                    border-left: 1px solid;
                }

                &:last-child {
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                    border-right: 1px solid;
                }
            }
        }
    }

    tfoot {
        tr {
            td {
                @extend .s-sbold, .blue-text;
                color: var(--bluec);

                border-bottom: 1px solid;

                &.tfoot-first-child {
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                    border-left: 1px solid;
                }

                &:last-child {
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                    border-right: 1px solid;
                }

                &.empty-td {
                    @extend .border-0;
                    // border: 0px !important;
                }
            }
        }
    } 
    .td-green-bg {
        @extend .green-bg, .white-text;
    }  
}
// End Table Order Summary 


.va-middle,
td.va-middle {
    vertical-align: middle !important;
}


// Border Table home
.bottonBorderTable {

    // th,
    // td {
    //     vertical-align: top !important;
    //     padding: 0 0 0.5rem 0;
    // }

    th {
        @extend .size-xviii, .s-sbold, .blue-text, .border-0;
        padding: 0 0 0.2rem 0;
        vertical-align: top !important;
        border: 0px !important;
        font-weight: unset;

    }

    td {
        @extend .size-xvi, .s-reg;
        vertical-align: top !important;
        color: var(--gray-text);
        border-top: 0px;
        border-bottom: 1px solid var(--border-gray);
        padding: 0.5rem 0.5rem 0.5rem 0;

        &:last-child {
            padding: 0.5rem 0 0.5rem 0;
        }

        p {
            @extend .m-0;
        }

        .icon-dt-td {
            @extend .size-xxv, .d-block;
            margin-top: 1px;
        }

        // .tdwidth-100 {
        //     max-width: 100px;
        // }

        .tdprice {
            @extend .d-flex, .align-items-center, .justify-content-end;

            .tdprice-no {
                @extend .ellips-auto, .d-block, .mr-2;
                max-width: 100px;
            }
        }
    }
}

// End Border Table home


// table {
//     min-width: 1155px;
//     overflow-x: auto;
//     margin-bottom: 1.5rem;
// }

// thead {
//     th {
//         @extend .size-xvi, .s-reg, .border-0, .pt-1, .pb-1;
//         // font-size: 17px;
//         // font-family: s-reg;
//         font-weight: 400;
//         color: var(--bluec);
//     }
// }

// tbody {
//     tr {
//         background: var(--white);
//         // cursor: pointer;

//         td {
//             @extend .size-xvi, .s-reg, .border-0, .align-middle, .pt-1, .pb-1;
//             color: var(--gray-text);

//             &:first-child {
//                 border-top-left-radius: 8px;
//                 border-bottom-left-radius: 8px;
//             }

//             &:last-child {
//                 border-top-right-radius: 8px;
//                 border-bottom-right-radius: 8px;
//             }
//         }

//     }
// }
