.filter-bar-new .toggle-big-switch-dash {
    color: var(--bluec);
    border: 1px solid var(--bluec) !important;
    border-radius: 30px !important;

    height: calc(1.4em + 0.70rem + 2px);
    padding: 5px 12px !important;

    @include for-desktop-big {
        height: calc(1.5em + 0.75rem + 10px);
        padding: 5px 12px !important;
    }

    .toggle-btn-sec-text {
        @extend .size-xvii;
    }
}

.last-updated-dash {
    @extend .d-inline-block, .text-left;
    background: var(--gray-mid-bg);
    padding: 5px 15px;
    border-radius: 8px;

    // height: calc(1.5em + 0.70rem + 8px);

    // @include for-desktop-big {
    //     height: calc(1.5em + 0.75rem + 13px);
    // }

    .lu-lable {
        @extend .size-xiii, .s-reg, .blue-text, .mb-0;
    }

    .lu-date {
        @extend .size-xv, .s-sbold, .blue-text, .mb-0;
    }
}

// Slider
.dash-slider-carousel-new {
    @extend .no-gutters;
    gap: 1.8rem;
    position: relative;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 10px;
}

.dash-carousel-icon {
    @extend .position-absolute, .cpointer;
    top: 35% !important;
    bottom: 0 !important;
    background: var(--white) !important;
    box-shadow: var(--gray-light-shadow);
    width: 25px;
    height: 85px !important;
    opacity: 1;
    // transition: opacity .15s ease;

    &.carousel-control-prev {
        left: 2px;
        border-radius: 10px 0 0 10px;
    }

    &.carousel-control-next {
        right: 2px;
        border-radius: 0 10px 10px 0;
    }
}

// End Slider



// Bar Chart Box 
.chart-box-popup {
    .modal-dialog {
        min-width: 85%;
    }
}

.chart-div {}

.dash-card-mb {
    @extend .mt-3, .mb-1;
    // @extend .mb-3;
}

.chart-box-new {
    @extend .h-100;
    padding: 15px 15px !important;
    background: var(--white);
    border-radius: 15px;

    &.model-chart-box-new {
        padding: 0px !important;
    }

    .chart-box-heading {
        @extend .mb-3, .ml-1;
        // @extend .ml-1;
        // margin-bottom: 0.7rem !important;
    }

    .chart-content {
        // &.chart-content-big {
        //     @extend .pl-2;
        // }

        &.model-chart-padding {
            @extend .pt-xl-5, .pl-xl-3;
        }

        &.big-model-chart-padding {
            @extend .pt-xl-5, .pl-xl-2;
        }

        .chart-lable {
            @extend .size-xiv, .s-sbold, .gray-text, .mb-0;
        }

        .chart-tag-color {
            display: inline-block;
            margin: 0 1px 2px 0;
            min-width: 9px;
            height: 4px;

            @include for-desktop-big {
                min-width: 12px;
                height: 5px;
            }
        }

        .chart-cp {
            @extend .d-flex, .align-items-center, .size-xvii, .s-sbold, .blue-text;
            margin-bottom: 0.7rem;

            .chart-price {
                // @extend .mr-1;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .chart-price-icon {
                @extend .ml-1;
            }
        }

        .model-chart-cp {
            margin-bottom: 1rem !important;
        }
    }

    // chart-box Column
    .chart-left-col {
        @extend .col-xl-10, .col-lg-10, .col-md-9;
    }

    .chart-right-col {
        @extend .col-xl-2, .col-lg-2, .col-md-3, .pl-1, .pr-2;
    }

    // end chart-box Column

    // model chart-box Column
    .model-chart-left-col {
        @extend .col-xl-10, .col-lg-10, .col-md-9;
    }

    .model-chart-right-col {
        @extend .col-xl-2, .col-lg-2, .col-md-3, .pl-1, .pr-2;
    }

    // end chart-box Column


    // For Buktec Summary / Business Summary -----

    // Big -- chart-box Column
    .chart-big-left-col {
        @extend .col-xl-7, .col-lg-8, .col-md-6;
    }

    .chart-big-right-col {
        @extend .col-xl-5, .col-lg-4, .col-md-6, .pl-2, .pr-2;
    }

    .ch-bigin-rt-col-12 {
        // @extend .col-12, .pr-0;
        @extend .col-12;
    }

    .ch-bigin-rt-col {
        // @extend .col-lg-6, .col-md-6, .pr-0;
        @extend .col-lg-6, .col-md-6;
    }

    // End Big -- chart-box Column

    // model Big -- chart-box Column
    .model-chart-big-left-col {
        @extend .col-xl-9, .col-lg-8, .col-md-6;
    }

    .model-chart-big-right-col {
        @extend .col-xl-3, .col-lg-4, .col-md-6, .pl-2, .pr-2;
    }

    .ch-bigin-rt-col-12 {
        @extend .col-12;
    }

    .ch-bigin-rt-col {
        @extend .col-lg-6, .col-md-6;
    }

    // end model Big -- chart-box Column 

    // For Buktec Summary / Business Summary -----


    .chart-scroll-list {
        // @extend .mt-2;
        margin-top: 0.45rem;
        border: 1px solid var(--greenc);
        border-radius: 10px;
        padding: 8px 6px 8px 8px;

        .chart-name-list {
            &.cl-list-scroll {
                padding-right: 6px;
                max-height: 135px;
                min-height: 135px;

                @include for-ipad-pro {
                    max-height: 210px;
                    min-height: 210px;
                }

                @include for-desktop-min {
                    max-height: 135px;
                    min-height: 135px;
                }

                @include for-desktop-big {
                    max-height: 155px;
                    min-height: 155px;
                }

                @include for-desktop-big-x {
                    max-height: 185px;
                    min-height: 185px;
                }

                overflow-y: auto;
                overflow-x: hidden;
            }

            &.model-cl-list-scroll {
                padding-right: 10px;
                max-height: 130px;

                @include for-ipad-pro {
                    max-height: 200px;
                }

                @include for-desktop-min {
                    max-height: 400px;
                }

                // min-height: 400px;
                // @include for-desktop-big {
                //     max-height: 155px;
                //     min-height: 155px;
                // }
                // @include for-desktop-big-x {
                //     max-height: 185px;
                //     min-height: 185px;
                // }
                overflow-y: auto;
                overflow-x: hidden;
            }

            .cl-keytitle,
            .cl-amount {
                @extend .size-xvi, .ellips-auto, .mb-2;
            }

            .cl-keytitle {
                @extend .s-reg;
            }

            .cl-amount {
                @extend .s-sbold, .blue-text, .text-right;
            }
        }
    }


    // My Card Accordion
    .accordion-chart-scroll {
        // margin-top: 0.45rem;
        // border: 1px solid var(--greenc);
        // padding: 8px 6px 8px 8px;

        background: var(--grayc);
        // background: #000;
        padding: 12px 7px 12px 12px;
        border-radius: 10px;

        .acch-chart-list {
            padding-right: 6px;
            max-height: 141px;
            // min-height: 195px;

            // @include for-desktop-big {
            //     max-height: 155px;
            //     min-height: 155px;
            // }
            @include for-desktop-15 {
                max-height: 207px;
                // min-height: 210px;
            }

            overflow-y: auto;
            overflow-x: hidden;
        }

        .acc-chart-card {
            &:first-child {
                border-radius: 10px 10px 0 0;

                .acch-card-header {
                    border-radius: 10px 10px 0 0;
                }
            }

            &:last-child {
                border-radius: 0 0 10px 10px;

                .acch-card-header {
                    border-radius: 0 0 10px 10px;
                }
            }
        }

        .acc-chart-card {
            @extend .border-0;

            &:not(:last-child) {
                @extend .mb-2;
            }

            .acch-card-header {
                @extend .d-flex, .align-items-center, .justify-content-between, .gap-5;

                // @extend .border-0, .px-3, .py-2;
                @extend .border-0;
                padding: 7px 15px;
                background: var(--white);

                // &:first-child {
                //     border-radius: 10px 10px 0 0;
                // }

                .label-acch {
                    @extend .size-xviii, .s-sbold, .gray-text, .mb-0;
                }

                .acch-chart-count {
                    @extend .size-xxii, .s-sbold, .blue-text, .ml-auto, .mr-2;
                }

                .acch-down-arrow {
                    font-size: 0.45rem;
                }
            }

            .acch-card-body {
                @extend .pt-0;

                .acch-entity-ul {
                    // @extend .pl-3, .mb-0;
                    @extend .mb-0;
                    padding-left: 9px;

                    @include for-desktop-big {
                        padding-left: 12px;
                    }

                    list-style-type: none;
                }

                .acch-entity-li {
                    @extend .size-xvi, .s-reg, .blue-text;

                    &:not(:last-child) {
                        @extend .mb-1;
                    }
                }
            }

            // .acch-label {
            //     @extend .d-flex, .align-items-center;
            //     gap: 3px;
            // }           
            // .acch-iconbar {
            //     // @extend .d-flex, .align-items-center, .gap-5;
            //     .acch-chart-count {
            //         @extend .size-xviii, .s-sbold, .blue-text;
            //     }
            //     .acch-down-arrow {
            //         font-size: 0.5rem;
            //     } 
            // }            
        }

        // .card-body {
        //     @extend .p-2;
        //     padding: 0.7rem !important;
        // }        
    }

    // End - My Card Accordion
}

// End Bar Chart Box 


// invites / License
.invitesc {
    .invitesc-card {
        @extend .d-flex, .align-items-center, .justify-content-between, .gap-20;

        &:first-child {
            border-radius: 10px 0 0 10px;
        }

        &:last-child {
            border-radius: 0 10px 10px 0;
        }

        padding: 1rem;

        @include for-desktop-15 {
            padding: 1.4rem 1.3rem;
        }
    }

    p {
        @extend .s-sbold, .white-text, .mb-0;
        line-height: 1.15;

        &:first-child {
            font-size: 1rem !important;

            @include for-desktop-15 {
                font-size: 1.4rem !important;
            }
        }

        &:last-child {
            font-size: 1.3rem !important;

            @include for-desktop-15 {
                font-size: 1.8rem !important;
            }
        }
    }
}


// .license-new-dropdown .form-control {
//     background: var(--gray-bg);
// }

.license-dash {
    @extend .px-4, .py-3;
    border: 1px solid var(--greenc);
    border-radius: 10px;

    // padding: 15px 20px 20px 28px;
    .license-count {
        @extend .size-xvii, .s-sbold, .blue-text, .ellips-auto, .mb-0;
    }
}

// End invites / License


// Users Wise Tasks
.users-wise-tasks-dash {
    .users-task-list-dash {
        @extend .gray-bg, .h-100;
        padding: 1rem 0.8rem;
        border-radius: 10px;

        .search-filter-uwtl {
            @extend .d-flex, .justify-content-between;

            input {
                @extend .size-xvi;
                height: 30px;
                color: var(--bluec);
                background: var(--white);
                padding: 0 12px;
                border: 1px solid var(--bluec);
                border-radius: 25px;
                // font-size: 16px;
                outline: none;

                &:focus {
                    border: 1px solid var(--greenc);
                    outline: none;
                }
            }

            p {
                @extend .size-xvii, .s-sbold, .blue-text, .pr-1, .mb-0;
            }
        }
    }


    .uwtl-menu-dash {
        // @extend .mt-2;
        padding: 0.7rem 2px 0 0;

        list-style: none;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 170px;
        // padding: 0 2px 0 0;

        .uwtl-menu-li {
            @extend .d-flex, .align-items-baseline, .justify-content-between, .border-0, .cpointer, .mb-2;

            p {
                @extend .gray-text, .mb-0;
            }

            .uwtl-menu-name {
                @extend .size-xviii, .s-reg;
            }

            .uwtl-menu-count {
                @extend .size-xx, .s-sbold, .ellips-auto, .pr-1, .text-right;
                width: 70px;
            }

            border-radius: 10px !important;
            padding: 0.1rem 0.6rem 0.25rem;
            background: transparent;

            &:not(:last-child) {
                margin-bottom: 0.8rem;
            }

            &.active-uwtl {
                background: var(--widget-active);

                p {
                    @extend .white-text;
                }
            }
        }
    }
}

// End Users Wise Tasks


.my-uwtl-profile-new {
    @extend .h-100;
    // @extend .px-3, .py-3;
    border: 1px solid var(--greenc);
    border-radius: 10px;

    padding: 0.8rem 0.7rem 0.8rem 0.8rem;

    @include for-desktop-15 {
        padding: 1rem 0.8rem 1rem  1rem;
    }

    .my-uwtl-profile {
        @extend .d-flex, .align-items-center, .gap-10;

        .myuw-profile {
            min-width: 42px;
            min-height: 42px;
            font-size: 1.3rem;            

            @include for-desktop-15 {
                min-width: 60px;
                min-height: 60px;
                font-size: 1.8rem; 
            }

            border-radius: 100%;
            box-shadow: var(--border-shadow);

            @extend .s-reg, .blue-bg, .white-text, .cap;
            @extend .d-flex, .align-items-center, .justify-content-center;
        }

        .myuw-profile-list {
            @extend .w-100;
            .myuw-ph {
                @extend .mb-0;

                @include for-desktop-big {
                    font-size: 1.3rem !important; 
                }
            }
            .myuw-pp {
                @extend .size-xvi, .s-sbold, .red-text, .mb-0;
                line-height: normal;
            }
            .myuw-pp-count {
                @extend .size-xxii, .s-sbold, .red-text, .mb-0;
                padding-right: 5px;
            }
        }
    }

    .myuw-profile-list-sec {
        @extend .mt-2;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 135px;
        padding-right: 5px;

        .myuw-profile-list {
            @extend .d-flex, .align-items-baseline, .justify-content-between, .gap-10, .mb-2;
            .myuw-list-pp {
                @extend .size-xviii, .s-reg, .blue-text, .mb-0;
                line-height: normal;
            }
            .myuw-list-pp-count {
                @extend .size-xxii, .s-sbold, .blue-text, .text-right, .mb-0;
                width: 50px;
            }
        }
    }
}