// Form All
.red-star {
    @extend .size-xv;
    color: var(--redc);
    margin-left: 2px;
}

.text-muted {
    @extend .text-right;
    color: var(--redc) !important;
}

.form-error-text,
.error_right {
    // .size-xv,
    @extend .s-reg, .red-text, .text-right, .d-block;
    // margin-bottom: 0.5rem;
    font-size: 0.7rem !important;
    
    @include for-desktop-big-125 {
        font-size: 0.75rem !important;
    }
    @include for-desktop-big {
        font-size: 0.85rem !important;
    }
}

.error_red {
    @extend .s-reg, .red-text, .d-block;
    font-size: 0.5rem !important;
}

.error_left,
.error-msg {
    @extend .red-text, .s-reg, .size-xv, .text-left, .d-block;
    // color: var(--redc) !important;
}

.error-msg-center {
    @extend .red-text, .s-reg, .size-xv, .text-center;
}

.filter-error-msg,
.error-msgs {
    @extend .mb-1, .red-text, .s-reg, .size-xv;
}

.side-filter-error-msg {
    @extend .mt-2, .mb-0, .red-text, .s-reg, .size-xv;
}

.lable-input {
    @extend .s-reg;
    // @extend .s-reg, .size-xvi;
    color: var(--gray-text);
    // @extend .s-reg;
    // font-size: 15px !important;      
    // @include for-desktop-big {
    //     font-size: 1rem;         
    // }
    // font-size: 14px !important;
    font-size: 0.8rem !important;
    color: var(--gray-text);

    @include for-desktop-big {
        // font-size: 15px !important;
        font-size: 1rem !important;
    }
}

.wrong-red {
    border-left: 3px solid var(--redc) !important;
    transition: 0.5s ease;
}

.input-bg-green {
    background-color: var(--greenc-light) !important;
    .Select.type-select.__control {
        background-color: var(--greenc-light) !important;
    }
}

.input-bg-gray {
    // background: #EBEBEB;
    // background: var(--disable);
}


.form-group {
    // margin-bottom: 10px;
    // @extend .mb-3;

    // margin-bottom: 1rem;
    margin-bottom: 0.5rem;
    color: var(--bluec);

    .lable-input {
        // @extend .mb-0;
        display: block;
        line-height: 1.1;
        margin-bottom: 4px;
    }
}

.form-control {
    // @extend .mb-3;
    // &::placeholder {
    //     @extend .size-xviii;
    //     @extend .s-reg;
    //     color: var(--placeholderc);
    // }

    background-color: transparent;

    &:focus {
        box-shadow: none !important;
    }

    &:disabled,
    &[readonly] {
        color: var(--bluec) !important;
        cursor: not-allowed !important;
        background-color: var(--disable) !important;
        opacity: 1;
    }
}

.__control--is-disabled {
    border-radius: 0px !important;
    cursor: not-allowed !important;
    background-color: var(--disable) !important;
    opacity: 1;
}

.form-control,
.form-control option,
.react-datepicker-ignore-onclickoutside,
.assign-by .optionContainer li,
.assign-by .search-wrapper input {
    font-size: 0.8rem !important;

    &::placeholder {
        font-size: 0.8rem !important;
        @extend .s-reg;
        color: var(--placeholderc);
    }

    @include for-desktop-big {
        font-size: 1rem !important;

        &::placeholder {
            font-size: 1rem !important;
        }
    }

    // &::placeholder {
    //     // @extend .size-xvi;
    //     font-size: 1rem !important;   
    //     @extend .s-reg;
    //     color: var(--placeholderc);
    // }

    // @include for-desktop-big {
    //     font-size: 1rem;         
    // }
}

.form-control,
.search-wrapper {
    // @extend .s-sbold, .size-xvii;
    @extend .s-sbold;
    color: var(--bluec);
    border: 0 !important;
    // border-top: 1px solid var(--white) !important;
    // border-left: 1px solid var(--white) !important;
    // border-right: 1px solid var(--white) !important;
    border-bottom: 1px solid var(--form-border) !important;
    border-radius: 0 !important;
    padding-left: 5px !important;
    padding-right: 5px !important;

    &:focus {
        border: 0 !important;
        border-bottom: 1px solid var(--greenc) !important;
    }
}

.border-0 {
    border: 0 !important;
}


select.form-control option {
    font-family: s-sbold, sans-serif !important;
    // -moz-font-family: s-sbold, sans-serif !important;
    // font-weight: 600;
}

select.form-control {
    padding-left: 2px !important;

    option {
        color: var(--bluec);

        &:disabled {
            @extend .cnotallowed;
            color: var(--text-disable);
        }
    }

    @supports (-webkit-hyphens:none) {
        .safari_only {
            box-shadow: none;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            background: transparent;
            background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+);
            background-repeat: no-repeat;
            background-position: right;
            // background-position-x: 21px;
            // background-position-y: 0px;
        }
    }
}

// For Mac Safari 
select.form-control {
    -webkit-appearance: none;
    appearance: none;    
    /* Add paddings to accommodate arrow */
    padding-right: 25px !important;    
    /* Add arrow icon */
    background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" xml:space="preserve"><path d="M7.41,8.59L12,13.17l4.59-4.58L18,10l-6,6l-6-6L7.41,8.59z"/><path fill="none" d="M0,0h24v24H0V0z"/></svg>');
    background-position: center right;
    background-repeat: no-repeat;
    // position: relative; 
}



// End For Mac Safari 

.form-text-right {
    text-align: right;
}

textarea.form-control {
    min-height: 35px;
    // min-height: auto !important;
}

// End Form All

// Assign by/to ---- 
.assign-by {
    .search-wrapper {
        @extend .s-reg;
        // height: calc(1.5em + 0.75rem + 2px) !important;
        height: calc(1.5em + 0.6rem + 1px) !important;
        padding: 4px;      

        @include for-desktop-big-125 {
            height: calc(1.5em + 0.6rem + 0px) !important;
        }
        @include for-desktop-big {
            height: calc(1.5em + 0.6rem + 5px) !important;
        }

        // padding: 0.375rem 0.75rem;
        &:focus {
            border-bottom: 1px solid var(--greenc) !important;
        }

        input {
            @extend .mt-0;
            width: 100%;

            &::placeholder {
                color: var(--bluec);
            }
        }
    }

    &.assign-select {
        background-color: var(--assignby);
    }

    .chip {
        @extend .d-none;
    }

    .optionContainer {
        max-height: 175px;

        @include for-desktop-big {
            max-height: 165px;
        }

        li {
            @extend .d-flex, .align-items-baseline, .pt-1, .pb-1;
            word-break: break-all;
            color: var(--bluec);
            background: var(--white);

            &:hover {
                color: var(--bluec);
                background: var(--white);
            }
        }
    }
}

// End  Assign by/to ---- 

// Main Filter 
.main-filter-popup-new {
    // @extend .p-3, .mt-2;
    // @extend .mt-2;
    margin-top: 0.5rem;

    padding: 0.7rem;

    // @include for-desktop-big {
    //     padding: 1rem;
    // }
    .form-group {
        margin-bottom: 0.5rem !important;
        // @include for-desktop-big {
        //     margin-bottom: 0.5rem !important;
        // }
    }

    border: 1px solid var(--bluec);
    border-radius: 15px;

    .due-date-new {
        @extend .text-md-right, .pl-md-0, .pr-0;

        label {
            @extend .mt-2, .mb-2, .gray-text, .s-reg, .size-xvi;
        }
    }

    .reset_apply_btn_new {
        @extend .mt-1;
    }
}

.hr-seprator {
    margin: 18px auto;
    width: 15px;
    border-top: 3px solid var(--bluec);
    // display: table;
}

// End Main Filter 

// Datepicker
.react-datepicker-wrapper {
    width: 100%; 
}
.react-datepicker__triangle {
    left: 50px !important;
}

// End Datepicker 

.high-priority {
    // @extend .w-100, .s-sbold, .blue-text, .border-0, .mt-0;
    @extend .s-sbold, .blue-text, .border-0, .mt-0;
    background: var(--gray-pri);
    border-radius: 50px;

    .icon-h {
        @extend .size-xxvii;
    }

    .high-priority-text {
        color: var(--grayc-light);
    }

    &.active {
        // color: var(--bluec);
        background: var(--greenc);
        border: 1px solid var(--greenc);

        .high-priority-text {
            color: var(--white) !important
        }
    }
}

// Check Box Design Form 
.sup-cust-check {
    @extend .d-flex, .align-items-center;
}

.sup-cust-check .styled-checkbox+label {
    position: relative;
    cursor: pointer;
    padding: 0;
    margin-bottom: 0;
    align-items: center;
}

.sup-cust-check .styled-checkbox {
    position: absolute;
    opacity: 0;
}

.sup-cust-check .styled-checkbox+label:before {
    width: 19px;
    height: 18px;
    margin-right: 5px;
    margin-top: 0.7px;
    // margin-top: 2px;
    margin-top: 1px;

    @include for-desktop-big {
        width: 19px;
        height: 19px;
        margin-top: 1px;
    }
}

.sup-cust-check {
    position: relative;

    .styled-checkbox:checked+label:after {
        content: "";
        position: absolute;
        background: var(--white);
        box-shadow: var(--check-box-shadow);
        transform: rotate(45deg);
        left: 4px !important;
        top: 11px !important;
        width: 2px;
        height: 2px;

        @include for-desktop-big {
            left: 4px !important;
            top: 12px !important;
            width: 2px;
            height: 2px;
        }

        @include for-desktop-big-125 {
            top: 11px !important;
        }
    }
}

.lable-check-box {
    .styled-checkbox+label:before {
        width: 1rem !important;
        height: 1rem !important;
        margin-top: 2px !important;
    }

    .styled-checkbox:checked+label:after {
        left: 2px !important;
        top: 12px !important;
    }
}

.btn-bar-save-text {
    @extend .size-xviii, .blue-text;

    .styled-checkbox+label:before {
        width: 1.3rem !important;
        height: 1.3rem !important;
        margin-right: 5px !important;
        margin-top: 0px !important;
    }

    .styled-checkbox:checked+label:after {
        left: 0.27rem !important;
        top: 0.8rem !important;
    }

    @include for-desktop-big {
        .styled-checkbox+label:before {
            width: 1.35rem !important;
            height: 1.35rem !important;
            margin-right: 5px !important;
            margin-top: 0px !important;
        }

        .styled-checkbox:checked+label:after {
            left: 0.3rem !important;
            top: 0.9rem !important;
        }
    }

    .label-text {
        @extend .d-flex, .align-items-center, .gap-5;

        .smtext {
            margin-top: 2px;
        }
    }
}


// --------------- New Check Box Design --------------
.checkbox_design,.sup-cust-check {
// .checkbox_design{

    label:before,
    label:after {
        display: none !important;
    }

    .styled-checkbox {
        position: relative;
        opacity: 1 !important;
        appearance: none;
        -webkit-appearance: none;
        // width: 20px;// height: 20px; none
        line-height: normal;
        accent-color: var(--greenc);
        @extend .d-flex, .align-items-center, .justify-content-center;
        cursor: pointer;
        margin-right: 5px;

        &::before {
            content: "";
            @extend .d-flex, .align-items-center, .justify-content-center, .w-100;
            // width: 20px !important;
            // height: 20px !important; 
            border: 1px solid var(--grayc-dark);
            border-radius: 4px;
            // clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
            // transform: scale(0);
        }
        &:checked::before {
            // content: "✓";   
            content: "\2713";
            color: var(--white);
            border: 1px solid var(--greenc);
            background-color: var(--greenc);
            // font-size: 87%;
            // transform: scale(1);
        }        

        &:disabled:before {
            background: var(--disable);
            border: 1px solid var(--disable);
        }
        &:checked:disabled::before {
            color: var(--white) !important;
            -webkit-text-fill-color: var(--white) !important;
            border: 1px solid var(--greenc);
            background-color: var(--greenc);
            opacity: 0.6;
        }

        // &:hover {
        //     color: black;
        // }
    }
}
.sup-cust-check {
    .styled-checkbox {        
        &::before {
            // margin-top: -1px;
            width: 1.1rem !important;
            height: 1.1rem !important;
            @include for-desktop-big-125 {
                width: 1rem !important;
                height: 1rem !important;
            }
            @include for-desktop-big {
                width: 1.2rem !important;
                height: 1.2rem !important;
            }
        }
    }
    &:checked::before {
        font-size: 100% !important;
    }
}
.sup-cust-check-md {
    .styled-checkbox {
        margin-right: 8px;
        &::before {
            width: 1.2rem !important;
            height: 1.2rem !important;
            @include for-desktop-big {
                width: 1.3rem !important;
                height: 1.3rem !important;
            }
        }
    }
}
.btn-bar-save-text, .sup-cust-check-lg {
    .styled-checkbox {
        margin-right: 10px;
        &::before {
            width: 1.25rem !important;
            height: 1.25rem !important;
            @include for-desktop-big {
                width: 1.4rem !important;
                height: 1.4rem !important;
                font-size: 85% !important;
            }
            @include for-desktop-big-125 {
                font-size: 100% !important;
            }
        }
    }
}
.i-accept-check {
    @extend .size-xx, .s-sbold, .blue-text;
}

// .checkbox_design {
//     .styled-checkbox {
//         // accent-color: var(--greenc) !important;
//         position: relative !important;
//         height: 20px;
//         width: 20px;
//         filter: hue-rotate(304deg);
//         opacity: 0.6;
//     }

//     label:before,
//     label:after {
//         display: none !important;
//     }
// }

// input[type="checkbox"] {
//     width: 3em;
//     height: 3rem;
//     accent-color: var(--greenc);
// }
// input[type="checkbox"] {
//     appearance: none;
//     -webkit-appearance: none;
//     display: flex;
//     align-content: center;
//     justify-content: center;
//     font-size: 2rem;
//     padding: 0.1rem;
//     border: 1px solid green;
//     border-radius: 0.5rem;
// }
// input[type="checkbox"]::before {
//     content: "✓";
//     width: 20px;
//     height: 20px;
//     transform: scale(0);
//     // clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
//     // background-color: green;
// }
// input[type="checkbox"]:checked::before {
//     transform: scale(1);
// }
//   input[type="checkbox"]:hover {
//     color: black;
//   }



// .checkbox_design {
//     .styled-checkbox {
//         // accent-color: var(--greenc) !important;
//         position: relative !important; 
//         height: 20px;
//         width: 20px;
//         filter: hue-rotate(304deg);
//         opacity: 0.6;

//         // filter: grayscale(1);
//         // filter: hue-rotate(295deg);
//         // opacity: 0.5;
//     } 
//     label:before, label:after {
//         display: none !important;
//     }
// }


// .sup-cust-check input[type='checkbox'] {
//     min-height: 20px !important;
//     min-width: 20px !important;

//     &:checked {
//         min-height: 20px !important;
//         min-width: 20px !important;
//         filter: hue-rotate(304deg);
//         opacity: 0.6;
//     }
// }
// input[type='checkbox'] {
//     width: 20px !important;
//     height: 20px !important;
// }
// input[type='checkbox']:checked {
//     background-color: var(--greenc);
// }    
// input[type='checkbox'] {
//     text-align: center;
//     display: table-cell;
//     vertical-align: middle;
//     // width: 25px !important;
//     // height: 20px !important;
//     appearance: none;
//     border-radius: 10%;
//     border: 1px solid var(--form-border);
//     box-shadow: none;

//     font-size: 16px;        
// }
// input[type='checkbox']:checked:after {
//     content: '\2714';
//     color: var(--white);
//     transform: rotate(90deg); 
// }

// --------------- End - New Check Box Design --------------

// End Check Box Design Form 





.add-tag-new {
    .tag-list {
        @extend .d-flex, .align-items-center, .flex-wrap, .p-0, .mb-0;
        list-style: none;

        li.tags {
            @extend .d-inline-block;
            border-radius: 5px;
            background: var(--sky-blue);
            color: var(--white);
            padding: 2px 6px 2px 10px;

            margin: 0 5px 5px 0;

            @include for-desktop-big {
                margin: 0 6px 6px 0;
            }

            button {
                padding: 0;
                font-size: 8px;
                margin: -4px 0 0 10px;
                color: var(--white);
                background: var(--closebtn);
                width: 15px;
                border-radius: 21px;
                vertical-align: middle;
            }
        }
    }

    // input {
    //     @extend .pl-2, .pr-2;
    //     min-width: 100px;
    //     max-width: 200px;
    //     height: 26px;
    //     font-size: 13px;
    //     color: var(--bluec);
    //     border-radius: 6px;
    //     padding-left: 10px !important;
    // }

    // input {
    //     min-width: 130px;
    //     max-width: 130px;
    // }

    .tags {
        @extend .s-reg, .cpointer, .d-inline-block;

        font-size: 12px !important;
        margin: 0 5px 5px 0;

        @include for-desktop-big {
            font-size: 14px !important;
            margin: 0 6px 6px 0;
        }

        border-radius: 5px;
        background: var(--switch-gray);
        color: var(--gray-dark-text);
        padding: 2px 8px;


        &:hover {
            background: var(--closebtn);
            color: var(--white);
        }
    }
}

.snap_form_row_col {
    @extend .justify-content-end;
}

.right-side-form-mb {
    // margin-bottom: 2rem;
    margin-bottom: 1rem;
}

.bordered-form {
    @extend .position-relative;
    padding: 0.6rem 0.8rem;
    border: 1px solid var(--shi-border);
    border-radius: 6px;
    // margin: 0 0 15px;
}

/* Payment Status */
.payment-status-scroll {
    max-height: 330px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 12px;
    margin-right: -12px;

    .main-pay-method-new {
        @extend .position-relative;
        // border: 1px solid var(--shi-border);
        border: 1px solid var(--gray-border);
        border-radius: 6px;
        margin: 0 0 10px;

        // .cross span {
        //     display: block;
        //     background: #656263;
        //     color: #fff;
        //     padding: 3px 0 0 0;
        //     border-radius: 25px;
        //     height: 20px;
        //     width: 20px;
        //     position: absolute;
        //     z-index: 20;
        //     right: -10px;
        //     top: -10px;
        //     cursor: pointer;
        //     font-size: 10px;
        // }
    }
}

/* End Payment Status */

.form-inner-blue-new {
    position: relative;
    border: 1px solid var(--bluec);
    border-radius: 12px;
    padding: 12px !important;
    
    // margin: 0.7rem 0;

    @include for-desktop-big {
        // margin: 1rem 0;
        padding: 15px !important;
    }

    .heading-blue-border {
        margin-bottom: 0.7rem;
        padding-bottom: 12px !important;

        @include for-desktop-big {
            margin-bottom: 1rem;
            padding-bottom: 15px !important;
        }

        border-bottom: 1px solid var(--bluec);
    }
}


.form-content-sec-new {
    // @extend .mt-2, .p-3;
    @extend .p-3;
    margin-top: 0.5rem;
    background: var(--grayc);
    border-radius: 15px;

    .form-sec-inner-new {
        // @extend .p-3;
        padding: 12px 15px !important;
        background: var(--white);
        border-radius: 15px;

        // .form-main-title-new {
        //     // @extend .d-flex, .align-items-center, .gap-5, .mt-2, .mb-3, .pb-3;
        //     @extend .d-flex, .align-items-center, .gap-5, .mt-2;
        //     padding-bottom: 1rem;
        //     margin-bottom: 1rem;
        //     // .flex-wrap,
        //     border-bottom: 1px solid var(--shi-border);
        //     .form-title-heading-new {
        //         @extend .mb-0, .mr-auto;
        //     }
        //     .fth-rights {
        //         @extend .d-flex, .align-items-center, .gap-7;
        //     }
        //     .form-date-new {
        //         @extend .size-xvii, .pt-1, .pb-1, .pl-3, .pr-3, .mr-2;
        //         color: var(--bluec);                    
        //         background: var(--table-bg);
        //         border-radius: 6px;                    
        //         // padding: 3px 17px;                  
        //         // margin: 0 11px 0 0;
        //     }
        //     .form-id-new {
        //         @extend .size-xv, .s-sbold;
        //         color: var(--gray-text);
        //     }
        //     .form-changelog-new {
        //         @extend .size-xxvii, .cpointer;
        //         // color: var(--bluec);
        //     }
        // }
    }
}

.form-main-title-new {
    // @extend .d-flex, .align-items-center, .gap-5, .mt-2, .mb-3, .pb-3;
    // @extend .d-flex, .align-items-center, .gap-5, .mt-2;
    @extend .d-flex, .align-items-center, .gap-5;
    padding-bottom: 0.5rem;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    // .flex-wrap,
    // border-bottom: 1px solid var(--shi-border);
    border-bottom: 1px solid var(--form-border);

    .form-title-heading-new {
        // @extend .mb-0, .mr-auto;
        margin-bottom: 0;
        margin-right: auto;
    }

    .fth-rights {
        @extend .d-flex, .align-items-center, .gap-7;
    }

    .form-date-new {
        @extend .size-xvii, .pt-1, .pb-1, .pl-3, .pr-3, .mr-2;
        color: var(--bluec);
        background: var(--table-bg);
        border-radius: 6px;
        // padding: 3px 17px;                  
        // margin: 0 11px 0 0;
    }

    .form-id-new {
        @extend .size-xv, .s-sbold;
        color: var(--gray-text);
    }

    .form-changelog-new {
        @extend .size-xxvii, .cpointer;
        // color: var(--bluec);
    }

    &.form-title-bar-center {
        @extend .d-flex, .justify-content-center, .border-0;

        position: relative;

        .mr-unset {
            margin-right: unset !important;
        }

        .ftbc {
            @extend .d-flex, .align-items-center, .justify-content-center, .gap-30;
            padding: 0 1.5rem;
            background: var(--white);
            z-index: 1;
        }

        &:before {
            position: absolute;
            content: "";
            border: 1px solid var(--greenc);
            width: 100%;
        }

        // &:before {
        //     content: "";
        //     border: 1px solid var(--greenc);
        //     width: 25%;
        // }
        // &:after {
        //     content: "";
        //     border: 1px solid var(--greenc);
        //     width: 25%;
        // }
    }
}

.inventory-section {
    // @extend .mt-5;
    margin-top: 1rem;
    // border: 1px solid var(--shi-border);
    // padding: 1rem 0.8rem;
    // border: 1px solid var(--greenc);
    // border-radius: 10px;
    // margin: 2rem 0 3rem;
    // box-shadow: 0 0 7px 1px var(--greenc);
}

/* Subheader Badge */
.multi-new-badges {
    // box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 67%);
    box-shadow: var(--box-shadow-tooltip);
    padding: 3px;
    border-radius: 5px;
    margin-top: -5px;
    // margin-left: 10px;
    transform: rotateY(335deg) rotateX(15deg);

    .badges-new-text {
        @extend .size-xiv;
        color: var(--bluec);
        // font-size: 14px;
        border-radius: 5px;
        padding: 0px 14px;
        font-family: s-sbold !important;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .saved {
        background: var(--greenc) !important;
    }

    .modified {
        color: var(--white);
        background: var(--orange) !important;
    }
}

/* End Subheader Badge */


.otpContainer {
    @extend .d-flex, .justify-content-between;
    gap: 20px;

    .otpInput {
        @extend .text-center;
    }
}


.form-control.item-error-field {
    border-bottom: 1px solid var(--redc) !important;
    transition: 0.5s ease;
}

.form-control.item-warning-field {
    border-bottom: 1px solid var(--yellow) !important;
    transition: 0.5s ease;
}

.field-border {
    border: 1px dashed var(--form-border) !important;
    padding: 0.1rem 0.4rem 0.4rem;
    margin-bottom: 0.8rem;

    .form-group {
        @extend .mb-lg-0;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/* End Firefox */

// Datepicker
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
    color: var(--black) !important;
}

.react-datepicker__month-text--keyboard-selected,
.react-datepicker__month-text--keyboard-selected:hover {
    background-color: var(--sky-blue) !important;
    color: var(--white) !important;
}

// End Datepicker

// Input Group
.input-group-border {
    border: 1px solid var(--bluec) !important;
    border-radius: 6px !important;

    .form-control {
        padding: 1px 4px !important;
        border-radius: 6px !important;
        border-radius:  6px 0 0 6px !important;
        height: calc(1rem + 0.75rem + 2px);
    }
    .input-group-prepend {
        // @extend .d-flex, .align-items-center, .justify-content-center, .cpointer;
        .input-gp-btn {
            @extend .d-flex, .align-items-center, .justify-content-center, .cpointer, .w-100, .h-100;
        }
        width: 32px;
        background: var(--greenc);
        border-radius: 0 6px 6px 0 !important;
        margin-right: 0.2px;
    }
    
}
// End Input Group


// .form-control:disabled, 
// .form-control[readonly] {
//     cursor: not-allowed !important;
//     background-color: var(--disable) !important;
//     opacity: 1;
// }

// @extend .p-3, .mt-2, .s-sbold;

// font-size: 17px;
// color: var(--bluec);

// .assignby .search-wrapper {
//     padding-left: 8px!important;
//     padding-right: 8px!important;
// }
// select.form-control {
//     border: 0 !important;
//     border-bottom: 1px solid var(--form-border) !important;        
//     padding-left: 4px !important;
//     border-radius: 0 !important;
// }
// .form-control:focus {
//     border: 0 !important;
//     border-bottom: 1px solid var(--greenc) !important;
//     color: var(--bluec);
// }
// .bodered-form {
//     border: 0 !important;
//     border-bottom: 1px solid var(--form-border) !important;
//     border-radius: 0 !important;
// }
// .form-control {
//     @extend .s-sbold, .size-xvii, .bodered-form;
//     color: var(--bluec);
//     .search-wrapper {        
//         @extend .s-reg, .bodered-form;
//     }
// }

// cards